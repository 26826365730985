import React, { useState, useRef, useEffect } from 'react'; 
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { apiURL } from '../../Layout/Common/confiqurl';
import log2 from '../../Images/log2.jpg';
import { Toaster, toast } from 'react-hot-toast'  

function Otp() {
    const navigate = useNavigate();
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [otp, setOtp] = useState("");
    const [state, setState] = useState({
        first: "",
        second: "",
        third: "",
        forth: "",
        fifth: "",
        sixth: "",
    });
    const inputRefs = useRef([]);

    useEffect(() => {
        const project = JSON.parse(sessionStorage.getItem('response'));
        setOtp(project.OTP);
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (!isNaN(value) && value.length <= 1) {
            setState((prevState) => ({
                ...prevState,
                [name]: value
            }));

            if (value !== '' && inputRefs.current[name]) {
                const nextIndex = Object.keys(state).indexOf(name) + 1;
                if (nextIndex < Object.keys(state).length) {
                    inputRefs.current[Object.keys(state)[nextIndex]].focus();
                }
            }
        } else if (value === '') {
            const prevIndex = Object.keys(state).indexOf(name) - 1;
            if (prevIndex >= 0 && inputRefs.current[Object.keys(state)[prevIndex]]) {
                inputRefs.current[Object.keys(state)[prevIndex]].focus();
            }
        }
    };

    const handleClick = () => {
        if (!isToastOpen) {
            setIsToastOpen(true);

            const data = `${state.first}${state.second}${state.third}${state.forth}${state.fifth}${state.sixth}`;

            const userId = sessionStorage.getItem("project_id");
            const token = JSON.parse(sessionStorage.getItem('data'));

            Axios.post(`${apiURL}/verify_OTP?user_id=${userId}`, { Enter_OTP: data }, {
                headers: {
                    "accept": "application/json",
                    "Content-Type": "application/json",
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    setTimeout(() => {
                        toast.success('OTP Verified Successfully');
                        navigate("/ResetPassword");
                    }, 1000);
                } else {
                    toast.error('OTP Verification Failed, Please Check Valid OTP');
                }
            })
            .catch(() => {
                toast.error('OTP Verification Failed, Please Check Valid OTP');
            });

            setTimeout(() => {
                setIsToastOpen(false);
            }, 3000);
        }
    };

    return (
        <div>
            <Toaster toastOptions={{ position: "top-right" }} />
            <main className="main-content mt-0 ps">
                <section>
                    <div className="page-header min-vh-100">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-5 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
                                    <div className="card card-plain">
                                        <div className="card-body px-lg-5 py-lg-5 text-center">
                                            <div className="text-center text-muted mb-4">
                                                <h2>OTP Verification</h2>
                                            </div>
                                            <div className="row gx-2 gx-sm-3">
                                                {['first', 'second', 'third', 'forth', 'fifth', 'sixth'].map((field, index) => (
                                                    <div className="col" key={field}>
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-lg"
                                                                maxLength="1"
                                                                style={{ textAlign: 'center', fontSize: '1.3rem' }}
                                                                name={field}
                                                                value={state[field]}
                                                                onChange={handleChange}
                                                                ref={el => inputRefs.current[field] = el}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="text-center">
                                                <button type="button" className="btn bg-gradient-primary w-100" onClick={handleClick}>
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
                                    <div
                                        className="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
                                        style={{ backgroundImage: `url(${log2})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="ps__rail-x" style={{ left: "0px", bottom: "0px" }}>
                    <div className="ps__thumb-x" tabIndex="0" style={{ left: "0px", width: "0px" }}></div>
                </div>
                <div className="ps__rail-y" style={{ top: "0px", right: "0px" }}>
                    <div className="ps__thumb-y" tabIndex="0" style={{ top: "0px", height: "0px" }}></div>
                </div>
            </main>
        </div>
    );
}

export default Otp;
