import React, { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Axios from 'axios';
import { apiURL } from '../../Layout/Common/confiqurl';

import { maxLength, numberMethod, validPasswordRegex, validEmailRegex, validPhoneNumberRegex } from '../../Layout/Common/common';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icons } from '../../Icons/IconIndex';
import "./Signup.css"
import Images from '../../Images';
// import log1 from '../../Images/log1'
import log3 from '../../Images/log3.jpg';


function Signup() {
    const navigate = useNavigate()
    const [icons, setIcons] = useState(true)
    const [icons1, setIcons1] = useState(true)
    const [showPopup, setShowPopup] = useState(false);
    const [isToastOpen, setIsToastOpen] = useState(false);

    const [state, setState] = useState({

        password: '',
        email: '',
        confirmPassword: '',

    })
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
        const value = event.target.value;
        if (/^\d{0,10}$/.test(value)) { // Limit input to 10 numbers
            setInputValue(value);
        }
    };

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
    };
    const [usernamevalue, setUsernamevalue] = useState('');

    const handleInputNameChange = (event) => {
        const capitalizedValue = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        setUsernamevalue(capitalizedValue);
    }
    const handleSignup = (e) => {



        const options = {
            user_name: usernamevalue,
            contact_number: inputValue,
            email_address: state.email,
            address: "",
            password: state.password,
            confirm_password: state.confirmPassword,
        }


        if (usernamevalue.trim()) {
            if (validEmailRegex.test(state.email)) {
                if (validPhoneNumberRegex.test(inputValue)) {
                    if (state.password.length>=8 && validPasswordRegex.test(state.password)) {
                        if (state.confirmPassword) {
                            if (state.confirmPassword === state.password) {
                            if (!isToastOpen) {
                                setIsToastOpen(true);
                            
                            Axios.post(`${apiURL}/signup`, options, {
                                headers: {
                                    "accept": "application/json",
                                    "Content-Type": " application/json"
                                }
                            },
                            ).then((response) => {
                                console.log('loginsuccess', response.data);

                                navigate("/")
                                setTimeout(() => {
                                    toast.success('Registered Successfully')
                                }, 1000)

                            })
                                .catch((err) => {
                                    toast.error(err.response.data.detail)
                                })

                                setTimeout(() => {
                                    setIsToastOpen(false);
                                  }, 3000); // set the timeout based on how long the toast message will be displayed
                                }
                            } else {
                                if (!isToastOpen) {
                                    setIsToastOpen(true);
                                    toast.error(' Password and Confirm Password Does Not Match ');
                                    setTimeout(() => {
                                      setIsToastOpen(false);
                                    }, 3000); // set the timeout based on how long the toast message will be displayed
                                  }
                                
                            }
                        } else {
                            if (!isToastOpen) {
                                setIsToastOpen(true);
                                toast.error('Please Enter Confirm Password');
                                setTimeout(() => {
                                  setIsToastOpen(false);
                                }, 3000); // set the timeout based on how long the toast message will be displayed
                              }
                            
                        }
                    } else {
                        if (!isToastOpen) {
                            setIsToastOpen(true);
                            toast.error('Please Enter Valid Password');
                            setTimeout(() => {
                              setIsToastOpen(false);
                            }, 3000); // set the timeout based on how long the toast message will be displayed
                          }
                    }
                } else {
                    if (!isToastOpen) {
                        setIsToastOpen(true);
                        toast.error('Please Enter  Mobile Number');
                        setTimeout(() => {
                          setIsToastOpen(false);
                        }, 3000); 
                      }
                }
            } else {
                if (!isToastOpen) {
                    setIsToastOpen(true);
                    toast.error('Please Enter Valid Email Address');
                    setTimeout(() => {
                      setIsToastOpen(false);
                    }, 3000); 
                  }
            }
        } else {
            if (!isToastOpen) {
                setIsToastOpen(true);
                toast.error('Please Enter Username');
                setTimeout(() => {
                  setIsToastOpen(false);
                }, 3000); 
              }
        }
       
    }

    const handleInputFocus = () => {
        setShowPopup(true);
      };
    return (
        <div>
            <Toaster toastOptions={{ position: "top-left" }} />
            <div class="page-header min-vh-100">
                <div class="container">
                    <div class="row">
                        <div class="col-6 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
                            <div class="card card-plain">
                              
                                <a href="http://uat.bookmevenue.com:3001/">
                                        <img src={Images.logobook} alt="Logo" style={{width: '75%'}}className="img-fluid logo" />
                                        </a>
                                   
                                
                                <div class="card-body">
                                    <form role="form">
                                 
                                        <label style={{marginLeft: "0"}}>Username<span style={{color:'red'}}>*</span></label>
                                        <div class="">
                                            <input type="text" class="form-control form-control-lg" placeholder="Username" aria-label="username" name='username' value={usernamevalue} onChange={handleInputNameChange} />
                                        </div>
                                        <label style={{marginLeft: "0"}}>Email<span style={{color:'red'}}>*</span></label>
                                        <div class="">
                                            <input type="email" class="form-control" placeholder="Email" aria-label="Email" name='email' value={state.email} onChange={handleChange} />
                                        </div>
                                        <label style={{marginLeft: "0"}}>Mobile Number<span style={{color:'red'}}>*</span></label>
                                        <div class="">
                                            <input
                                                class="form-control"
                                                placeholder='  Mobile Number'

                                                type="text"
                                                id="input"
                                                maxLength={10} 
                                                pattern="[0-9]*" 
                                                value={inputValue}
                                                onChange={handleInputChange}
                                            />  </div>
                                        <label style={{marginLeft: "0"}}>Password<span style={{color:'red'}}>*</span></label>
                                        <div class="">
                                            <input type={icons ? "password" : "text"} class="form-control"   placeholder="Password" onFocus={handleInputFocus} aria-label="Password" name='password' value={state.password} onChange={handleChange} />
          
                                            <span class="input-group-text">
                                                <i onClick={() => setIcons(!icons)} className="text-black"         
                                                     style={{ fontSize: "24px",cursor: "pointer" }}>
                                                    {
                                                        icons ? (<FontAwesomeIcon icon={Icons.faEye} />
                                                        ) : (<FontAwesomeIcon icon={Icons.faEyeSlash} />
                                                        )
                                                    }
                                                </i>
                                            </span>
                                            {showPopup && (
         <div className="popup">
         <p style={{fontSize:"13px"}}>
           
           {/* A minimum 8 characters password contains a first letter should uppercase ,lowercase special characters and number are required</p> */}
     
<ul style ={{color:'black',    paddingLeft: '20px'}}>
   <li>Password should be minimum 8 characters</li>
   <li>First letter should be uppercase</li>
   <li>Password should contain lowercase letters, numbers and  at least one special character</li>
  
  
</ul>

      </p>
       </div>
      )}
                                        </div>
                                        {/* <label>Role</label>
                                        <div class="">
                                            <input type="text" class="form-control" placeholder="Password" aria-label="Password" name='role' value={state.role} onChange={handleChange} />
                                        </div> */}
                                        <label style={{marginLeft: "0"}}>Confirm Password<span style={{color:'red'}}>*</span></label>
                                        <div class="">
                                            <input type={icons1 ? "password" : "text"} class="form-control" placeholder=" Confirm Password" aria-label="Password" name='confirmPassword' value={state.confirmPassword} onChange={handleChange} />
                                            <span class="input-group-text">
                                                <i onClick={() => setIcons1(!icons1)} className="text-black" style={{ fontSize: "24px",cursor: "pointer"  }}>
                                                    {
                                                        icons1 ? (<FontAwesomeIcon icon={Icons.faEye} />
                                                        ) : (<FontAwesomeIcon icon={Icons.faEyeSlash} />
                                                        )
                                                    }
                                                </i>
                                            </span>
                                        </div>

                                        <div class="text-center">
                                            <button type="button" class="btn bg-gradient-primary w-100 mt-4 mb-0" onClick={handleSignup}>Sign up</button>
                                        </div>
                                    </form>
                                </div>
                                <div class="card-footer text-center pt-0 px-sm-4 px-1">
                                    <p class="mx-auto">
                                        Already have an account?
                                        <Link to='/' class="text-primary text-gradient font-weight-bold">  Sign in</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
                        <div className="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center" style={{ backgroundImage: `url(${log3})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        </div>
                            {/* <div class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center">
                                
                                <h4 class="mt-5 text-white font-weight-bolder">Your journey starts here</h4>
                                <p class="text-white">Just as it takes a company to sustain a product, it takes a community to sustain a protocol.</p>
                            </div> */}
                             {/* <div class='col-md-6 col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column'>
                                    <div className="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center" style={{ backgroundImage: `url(${log2})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    </div>
                                </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signup