import React, { useState, useEffect } from 'react';
import lock from "../../Images/lock.jpg"
import toast, { Toaster } from 'react-hot-toast';
import { apiURL } from '../../Layout/Common/confiqurl';
import {  Link, useNavigate } from "react-router-dom";
import Axios from 'axios';
import { maxLength, numberMethod, validPasswordRegex, validEmailRegex, validPhoneNumberRegex } from '../../Layout/Common/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icons } from '../../Icons/IconIndex';
import Images from '../../Images';
import log2 from '../../Images/log2.jpg';
function ResetPassword() {
    const navigate=useNavigate()
    const [showPopup, setShowPopup] = useState(false);
    const [isToastOpen, setIsToastOpen] = useState(false);

    const [state,setState] = useState({
       
        Password:'',
     
        confirmPassword: '',
       
    })
    const [icons, setIcons] = useState(true)
    const [icons1, setIcons1] = useState(true)
    
const userId=sessionStorage.getItem("project_id")
console.log(userId);
    
    const handleChange = (event) => {
        setState({
          ...state,
          [event.target.name]: event.target.value
        });
      };

console.log(state);


const handleInputFocus = () => {
    setShowPopup(true);
  };
    const handleSignup = (e) => {

        // console.log(token.access_token,"jhhhh")

        const options = {
          
            new_password: state.Password,
            confirm_password: state.confirmPassword,
        }


        
                        if ( state.Password.length>=8 && validPasswordRegex.test(state.Password)) {
                            if (state.confirmPassword === state.Password) {

                                if (!isToastOpen) {
                                    setIsToastOpen(true);
                                

                                Axios.put(`${apiURL}/resetpassword?id=${userId}`, options, {
                                    headers: {
                                        "accept": "application/json",
                                        "Content-Type": " application/json",

                                    }
                                },
                                ).then((response) => {
                                    console.log('loginsuccess', response);
                                    navigate("/")
                                    setTimeout(() => {
                                        toast.success('Password Updated Successfully')
                                    
                                    }, 1000)
                                    
                                })
                                    .catch((err) => {
                                        {
                                            toast.error(err.response.data.detail)
                                            // setIsLoading(false);
                                        }
                                    })

                                    setTimeout(() => {
                                      setIsToastOpen(false);
                                    }, 3000);
                                  }
                            } else {if (!isToastOpen) {
                                setIsToastOpen(true);
                                toast.error('Please Enter Same New Password');
                                setTimeout(() => {
                                  setIsToastOpen(false);
                                }, 3000); // set the timeout based on how long the toast message will be displayed
                              }
                
                            }
                        } else {
                            if (!isToastOpen) {
                                setIsToastOpen(true);
                                toast.error('Please Enter Valid New Password');

                                setTimeout(() => {
                                  setIsToastOpen(false);
                                }, 3000); // set the timeout based on how long the toast message will be displayed
                              }

                        }
                  
    }
    
    return (
        <div>
            <main class="main-content mt-0 ps">
            <Toaster toastOptions={{ position: "top-left" }} />

                <section>
                    <div class="page-header min-vh-100">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
                                    <div class="card card-plain">
                                    <a href="http://uat.bookmevenue.com:3001/">
                                                <img src={Images.logobook} alt="Logo" className="img-fluid logo"  />
                                            </a>
                                        <div class=" pb-0 text-left ms-4">
                                            <h4 class="font-weight-bolder">Reset Password</h4>
                                            {/* <p class="mb-0">You will receive an e-mail in maximum 60 seconds</p> */}
                                        </div>
                                        <div class="card-body">
                                            <form role="form">
                                                {/* <div class="mb-3">
                                                    <input type="password" class="form-control form-control-lg" placeholder="New Password"  name='password' value={state.password} onChange={handleChange}   />
                                                </div> */}
                                                                                        <label>New Password<span style={{color:'red'}}>*</span></label>

                                                        <div class="mb-4">
                                            <input type={icons ? "password" : "text"} class="form-control" placeholder=" New Password" aria-label="Password" name='Password' onFocus={handleInputFocus} value={state.Password} onChange={handleChange} />
                                            <span class="input-group-text">
                                                <i onClick={() => setIcons(!icons)} className="text-black" style={{ fontSize: "24px",cursor: "pointer" }}>
                                                    {
                                                        icons ? (<FontAwesomeIcon icon={Icons.faEye} />
                                                        ) : (<FontAwesomeIcon icon={Icons.faEyeSlash} />
                                                        )
                                                    }
                                                </i>
                                            </span>
                                            {showPopup && (
        <div className="popup">
          <p style={{fontSize:"13px"}}>
            
            {/* A minimum 8 characters password contains a first letter should uppercase ,lowercase special characters and number are required</p> */}
      
<ul style ={{color:'black',    paddingLeft: '20px'}}>
    <li>Password should be minimum 8 characters</li>
    <li>First letter should be uppercase</li>
    <li>Password should contain lowercase letters, numbers and  at least one special character</li>
   
   
</ul>

       </p>
        </div>
      )}
                                        </div>
                                                {/* <div class="mb-3">
                                                    <input type="password" class="form-control form-control-lg" placeholder="conform Password"  name='conformPassword' value={state.conformPassword} onChange={handleChange}   />
                                                </div> */}
                                                                                        <label>Confirm New Password<span style={{color:'red'}}>*</span></label>

                                                   <div class=" mb-3">
                                            <input type={icons1 ? "password" : "text"} class="form-control" placeholder="Confirm New Password" aria-label="Password" name='confirmPassword' value={state.confirmPassword} onChange={handleChange} />
                                            <span class="input-group-text">
                                                <i onClick={() => setIcons1(!icons1)} className="text-black" style={{ fontSize: "24px",cursor: "pointer"  }}>
                                                    {
                                                        icons1 ? (<FontAwesomeIcon icon={Icons.faEye} />
                                                        ) : (<FontAwesomeIcon icon={Icons.faEyeSlash} />
                                                        )
                                                    }
                                                </i>
                                            </span>
                                        </div>
                                                <div class="text-center">
                                                    <button type="button" class="btn bg-gradient-primary btn-lg w-100 my-4" onClick={handleSignup}>Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column"> */}
                                    {/* <div class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"> */}
                                        {/* <img src="../../../assets/img/shapes/pattern-lines.svg" alt="pattern-lines" class="position-absolute opacity-4 start-0" /> */}
                                            {/* <div class="position-relative">
                                                <img class="max-width-500 w-100 position-relative z-index-2" src={lock} alt="lock-svg" />
                                            </div> */}
                                             <div class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
                        <div className="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center" style={{ backgroundImage: `url(${log2})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        </div>
                        </div>
                                            <h4 class="mt-5 text-white font-weight-bolder">Soft UI Design</h4>
                                            <p class="text-white">Just as it takes a company to sustain a product, it takes a community to sustain a protocol.</p>
                                    </div>
                                </div>
                            {/* </div> */}
                        {/* </div> */}
                    </div>
                </section>
                <div class="ps__rail-x" style={{left: '0px', bottom: '0px'}}><div class="ps__thumb-x" tabindex="0" style={{left: '0px', width: '0px'}}></div></div><div class="ps__rail-y" style={{top: '0px', right: '0px'}}><div class="ps__thumb-y" tabindex="0" style={{top: '0px' ,height: '0px'}}></div></div></main>

        </div>
    )
}


export default ResetPassword
