import Aside from '../../../Layout/Aside'
import Navbar from '../../../Layout/Navbar'
import Footer from '../../../Layout/Footer'
import "./Style.css";
import React, { useContext, useState } from "react";
import { Stepper, Step } from "react-form-stepper";


import Image from './Components/Image/Image';

import { MainContext } from '../../../Store/Context';
import Axios from 'axios';
import { apiURL } from '../../../Layout/Common/confiqurl';
import { Toaster, toast } from 'react-hot-toast';
import Viedo from './Components/Viedo';





const AddNew = (props) => {
  const {getEditData,data,uploadClosePopUp,closeModal}=props
  console.log(data,"klkkkk");
  const { user } = useContext(MainContext)

  const [activeStep, setActiveStep] = useState(1);

  

 


  const [addTextBox, setAddTextBox] = useState([{ FirstName: "", image: "" }]);
  const [video, setVideo] = useState([{ videoType: "", video: "" }]);

  const [image, setImage] = useState([])
  const [imageType, setImageType] = useState([])

 

 


  






 

  const handleNext = () => {

    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const VideoRemove = (e, index) => {

    const List = [...video];
    List.splice(index, 1);
    setVideo(List)

  }

  const ViedoAdd = () => {
    let tempAddTextBox = video;
    let addTextBoxRow = { videoType: "", video: "" };
    tempAddTextBox.push(addTextBoxRow);
    setVideo([...tempAddTextBox]);



  }


  const onclickRemove = (e, index) => {
    console.log(index, "index",);

    const List = [...addTextBox];
    List.splice(index, 1);
    setAddTextBox(List)

  }

  const addboxt = () => {
    let tempAddTextBox = addTextBox;
    let addTextBoxRow = { FirstName: "", image: "" };
    tempAddTextBox.push(addTextBoxRow);
    setAddTextBox([...tempAddTextBox]);

    console.log(addTextBox, "addTextBox", tempAddTextBox, "tempAddTextBox");


  }
  const onchangename = (e, key) => {
    const { name, value } = e.target;
    let data = addTextBox;
    data[key][name] = e.target.value
    setAddTextBox([...data]);



  }






  const uploadImage = (e, index) => {
    let file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        let binaryString = e.target.result;
        setAddTextBox((prevState) => {
          let newState = [...prevState];
          newState[index].image = btoa(binaryString);
          return newState;
        });
      };
      reader.readAsBinaryString(file);
    }

  };
  const onchangeVideotype = (e, key) => {
    const { name, value } = e.target;
    let data = video;
    data[key][name] = e.target.value
    setVideo([...data]);
  }

  const handleFileChange = (event, index) => {
    const fileViedo = event.target.files[0];

    if (fileViedo) {

      const reader = new FileReader();
      reader.readAsDataURL(fileViedo);
      reader.onload = () => {
        setVideo((prevState) => {
          let newState = [...prevState];
          newState[index].video = reader.result;
          return newState;
        })
      };
    }
  };






  






  const stepStyleConfig = {
    activeBgColor: "white",
    activeTextColor: "white",
    completedBgColor: "#db23bd",
    inactiveBgColor: "white",
    size: "1.5rem"

  };


  const handleImage_VideoClick = () => {

console.log("kjjjjjjjjjjj");
    const imageurl = []
    const imageUrlType = []

    addTextBox.map((value) => {
      setImage([...image, value.image])
      setImageType([...imageType, value.FirstName])

      imageurl.push(value.image)
      imageUrlType.push(value.FirstName)
    })

    const videoArray = []
    const VideoTypeArray = []
    video.map((value) => {


      videoArray.push(value.video)
      VideoTypeArray.push(value.videoType)
    })
    const project = JSON.parse(sessionStorage.getItem('data'));
    const mandapam_id = sessionStorage.getItem('mandapam_id')







    const payload = {

      images: imageurl,
      image_types: imageUrlType,
      videos: videoArray,
      video_types: VideoTypeArray


    }
    Axios.post(`${apiURL}/add_images_videos?user_id=${project.user_id}&mandapam_id=${data.id}`, payload, {
      headers: {
        'Authorization': `Bearer ${project.access_token}`,

        "accept": "application/json",
        "Content-Type": " application/json"
      }
    },
    ).then((response) => {

      toast.success('Mandapam Updated Successfully')
      uploadClosePopUp()
      closeModal()
      getEditData()

    })
      .catch((err) => {
        {
          toast.error("Invalid User")
        }
      });


  }


  return (
  

        <div className="container-fluid py-4" >
          <div className="row d-flex justify-content-center">
            <div className="col-12 ">

              <Stepper styleConfig={stepStyleConfig} activeStep={activeStep}>
            
                <Step label="Images" />
                <Step label="Videos" />


              </Stepper>
            
              {activeStep === 1 ? <Image 
                //  handleSubmit={handleSubmit}
                next={handleNext}
                addTextBox={addTextBox}
                setAddTextBox={setAddTextBox}
                onclickRemove={onclickRemove}
                onchangename={onchangename}
                addboxt={addboxt}
                uploadImage={uploadImage}

                back={handleBack} />
                : ""}
              {activeStep === 2 ? <Viedo 
                video={video}
                setVideo={setVideo}
                VideoRemove={VideoRemove}
                onchangeVideotype={onchangeVideotype}
                ViedoAdd={ViedoAdd}
                handleFileChange={handleFileChange}
                handleImage_VideoClick={handleImage_VideoClick}
                back={handleBack} />
                : ""}

            </div>

          </div>



        </div>
    
  )
}

export default AddNew
