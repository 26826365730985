import React, { useState } from 'react'
import { toast, Toaster } from 'react-hot-toast';

const RoomHall = (props) => {
  const [isToastOpen, setIsToastOpen] = useState(false);
  const { data, handleChange, next, back, fullhall_ac, setFullhall_ac } = props;
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const nextData = () => {
    if (data.roomsac.trim()) {
      if (data.roomsnonac.trim()) {
        if (data.hallseatingcapacity.trim()) {
          if (data.hallfloatingcapacity.trim()) {
            next()
          } else {
            if (!isToastOpen) {
              setIsToastOpen(true);
              toast.error('Please Enter Hall Floating Nos');
              setTimeout(() => {
                setIsToastOpen(false);
              }, 3000); // set the timeout based on how long the toast message will be displayed
            }
          }
        } else {
          if (!isToastOpen) {
            setIsToastOpen(true);
            toast.error('Please Enter Hall Seating Nos');
            setTimeout(() => {
              setIsToastOpen(false);
            }, 3000); // set the timeout based on how long the toast message will be displayed
          }
        }
      }
      else {
        if (!isToastOpen) {
          setIsToastOpen(true);
          toast.error('Please Enter No Of Non AC Rooms')
          setTimeout(() => {
            setIsToastOpen(false);
          }, 3000); // set the timeout based on how long the toast message will be displayed
        }
      }
    } else {
      if (!isToastOpen) {
        setIsToastOpen(true);
        toast.error('Please Enter No Of Air Conditioned Rooms')
        setTimeout(() => {
          setIsToastOpen(false);
        }, 3000); // set the timeout based on how long the toast message will be displayed
      }
    }
  }


  return (
    <div class="row">
      {/* <Toaster toastOptions={{ position: "top-right" }} /> */}
      <div class="col-12 col-lg-8 mx-auto  w-100">
        <div class="card w-100">
          <div class="card-body">
            <h5 class="font-weight-bolder">Room & Hall</h5>
            <div className='row'>
              <div className='col-6' >
                <label>No Of Air Conditioned Rooms<span style={{ color: 'red' }}>*</span></label>
                <input class="form-control" type="Number" onKeyDown={(e) =>
                  exceptThisSymbols.includes(e.key) && e.preventDefault()
                } value={data.roomsac} name='roomsac' onChange={handleChange} />
              </div>
              <div className='col-6' >
                <label>No Of Non AC Rooms<span style={{ color: 'red' }}>*</span></label>
                <input class="form-control" type="Number" onKeyDown={(e) =>
                  exceptThisSymbols.includes(e.key) && e.preventDefault()
                } value={data.roomsnonac} name='roomsnonac' onChange={handleChange} />
              </div>
            </div>
            <div className='row'>
              <div className='col-6' >
                <label>Hall Seating Nos<span style={{ color: 'red' }}>*</span></label>
                <input class="form-control" type="number" onKeyDown={(e) =>
                  exceptThisSymbols.includes(e.key) && e.preventDefault()
                } value={data.hallseatingcapacity} name='hallseatingcapacity' onChange={handleChange} />
              </div>
              <div className='col-6' >
                <label>Hall Floating Nos <span style={{ color: 'red' }}>*</span></label>
                <input class="form-control" type="number" onKeyDown={(e) =>
                  exceptThisSymbols.includes(e.key) && e.preventDefault()
                } value={data.hallfloatingcapacity} name='hallfloatingcapacity' onChange={handleChange} />
              </div>
            </div>
            <div className='row mt-2'>
              <div className='col-6 d-flex justify-content-start ' >
                <div className=''>
                  <label for="rememberMe">Full hall AC</label>
                </div>
                <div class="form-check form-switch pt-1 ms-3">
                  <input class="form-check-input" type="checkbox" checked={fullhall_ac} onClick={() => setFullhall_ac(!fullhall_ac)} />
                </div>
              </div>
              <div className='col-6' >
              </div>
            </div>
            <div className='row mt-5'>
              <div className="col-6 ">
                <button
                  onClick={back}
                  className="btn bg-gradient-secondary mb-0 js-btn-prev"
                >
                  back
                </button>
              </div>
              <div className="col-6 ">
                <button
                  onClick={nextData}
                  className="btn bg-gradient-dark ms-auto mb-0 js-btn-next  d-flex justify-content-end"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RoomHall
