import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react'
import { Icons } from '../../../Icons/IconIndex';
import { MainContext } from '../../../Store/Context';
import { Link, useNavigate } from 'react-router-dom';
import { Nav, NavDropdown } from 'react-bootstrap';
import "./style.css"
const Navbar = (props) => {
  const { setUser, user } = useContext(MainContext)
  const [state, setState] = useState("")
  const [userrole, setuserrole] = useState("")

  const [active, setActive] = useState(false)
  const navigate=useNavigate()
  const handleLogout=()=>{
    sessionStorage.clear()
    navigate("/")
  }

  // const handleBackButton = (event) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  // };
  useEffect(() => {
    
    const project = JSON.parse(sessionStorage.getItem('data'));

    setState(project.user_name);
    setuserrole(project.user_role)

    

  }, []);

console.log(active);
  return (
    <nav className="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky card" onClick={() => setActive(!active)} id="navbarBlur" data-scroll="true">
      <div className="container-fluid py-1 px-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm">
              <a className="opacity-3 text-dark" href="javascript:;">


              </a>
            </li>
            {/* <li className="breadcrumb-item text-sm"><a className=" text-dark" >Home</a></li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">{props.Name}</li> */}
          </ol>
          <h5 className="font-weight-bolder mb-0">{props.Name}</h5>
        </nav>
        <div className="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
          <a href="javascript:;" className="nav-link text-body p-0">
            <div className="sidenav-toggler-inner">

            

            </div>
          </a>
        </div>
        <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
          <div className="ms-md-auto pe-md-3 d-flex align-items-center">
       
          </div>
       

          <Nav className="">
       
          <NavDropdown title={state} id="basic-nav-dropdown" className='' style={{ minWidth: '100px' }}>
            {/* Set the minWidth property to adjust the width of the dropdown menu */}
            <NavDropdown.Item href="#action/1.1" className='me-5' >
                    <div className="d-flex py-1">
                      <div className="my-auto"> 
                        <img src="../../assets/img/team-2.jpg" className="avatar avatar-sm  me-3 " alt="user image" />
                      </div>
                      <div className="d-flex flex-column justify-content-center">
                        <h6 className="text-sm font-weight-normal mb-1">
                          <span className="font-weight-bold">{state}</span>
                        </h6>
                        <p className="text-xs text-secondary mb-0">
                          <i className="fa fa-clock me-1"></i>
                          {userrole}
                        </p>
                      </div>
                    </div>
                         </NavDropdown.Item>
            <NavDropdown.Item href="" >
                    <div className="d-flex py-1" onClick={()=>navigate("/Setting")}>
                    <div className="icon icon-shape icon-sm shadow border-radius-md  text-center d-flex align-items-center justify-content-center  me-2"  >
                      <FontAwesomeIcon icon={Icons.faGear} />

                    </div>
                      <div className="d-flex flex-column justify-content-center" >
                        <h6 className="text-sm font-weight-normal mb-1" >
                          Settings
                        </h6>

                      </div>
                    </div>
                         </NavDropdown.Item>
            <NavDropdown.Item href="#action/1.3" >
                    <div className="d-flex py-1">
                    <div className="icon icon-shape icon-sm shadow border-radius-md  text-center d-flex align-items-center justify-content-center  me-2"  >
                      <FontAwesomeIcon icon={Icons.faRightFromBracket} />

                    </div>
                      <div className="d-flex flex-column justify-content-center">
                        <h6 className="text-sm font-weight-normal mb-1" onClick={handleLogout}>
                          Logout
                        </h6>

                      </div>
                    </div>
                         </NavDropdown.Item>
          </NavDropdown>
        </Nav>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
