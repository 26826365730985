import axios from 'axios';
import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { apiURL } from '../../../Layout/Common/confiqurl';

const Basic = (props) => {
  const { data, handleChange, next, error, setError } = props;
  const [inavlid, setInvalid] = useState(false)
  const [state, setState] = useState([]); 
  const [isToastOpen, setIsToastOpen] = useState(false);

  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  useEffect(() => {
    const storedData = JSON.parse(sessionStorage.getItem('data_value')); 
    getData()
  }, []);


  const storedData = JSON.parse(sessionStorage.getItem('data_value')); 

  const getData = () => {
    const storedData = JSON.parse(sessionStorage.getItem('data_value')); 
    const token = JSON.parse(sessionStorage.getItem('data'));

    axios.get(`${apiURL}/get_allvenue_types`, {

      headers: {
        'Authorization': `Bearer ${token.access_token}`,
        "accept": "application/json",
        "Content-Type": " application/json",
      }
    },)
      .then((response) => { 

        setState(response.data.place_types || []);
      })
  }

  const nextData = () => {
    if (data.mandapam_name.trim()) {
      if (data.place_type.trim()) {
        if (data.startingpriceperday.trim()) {
          if (data.about) {
            next()
          } else {
            if (!isToastOpen) {
              setIsToastOpen(true);
              toast.error('Please Enter Description');
              setTimeout(() => {
                setIsToastOpen(false);
              }, 3000);
            }
          }
        } else {
          if (!isToastOpen) {
            setIsToastOpen(true);
            toast.error('Please Enter Fee Per Day');
            setTimeout(() => {
              setIsToastOpen(false);
            }, 3000);
          }
        }
      }

      else {
        if (!isToastOpen) {
          setIsToastOpen(true);
          toast.error('Select Venue Type')
          setTimeout(() => {
            setIsToastOpen(false);
          }, 3000);
        }
      }
    }

    else {
      if (!isToastOpen) {
        setIsToastOpen(true);
        toast.error('Please Enter Venue Name')
        setTimeout(() => {
          setIsToastOpen(false);
        }, 3000);
      }
    }
  }
  return (

    <div class="row"> 
      <div class="col-12 col-lg-8 mx-auto  w-100">
        <div class="card w-100">
          <div class="card-body">
            <h5 class="font-weight-bolder">Add New Venue - Basic</h5>
            <div className='row'>
              <div className='col-6' >
                <label>Venue Name <span style={{ color: 'red' }}>*</span></label>
                <input class="form-control" type="text" value={data.mandapam_name} name='mandapam_name' placeholder='Venue Name ' onChange={handleChange} />
              </div>
              <div className='col-6' >
                <label>Venue Type <span style={{ color: 'red' }}>*</span></label>
                <select id="inputState" class="form-select " value={data.place_type || ""} name='place_type' placeholder='' onChange={handleChange} style={{ cursor: "pointer" }} >
                  <option value="" disabled selected hidden>
                    Select an option
                  </option>
                  {
                    Array.isArray(state) ? state.map((item, index) => (
                      <option key={index} value={item.data_value || item}>
                        {item.data_value || item}
                      </option>
                    )) : null
                  }
                </select>
              </div>
            </div>
            <div className='row'>
              <div className='col-6' >
                <label for="number-input">Fee per day <span style={{ color: 'red' }}>*</span></label>
                <input class="form-control" type="number" id="number-input" onKeyDown={(e) =>
                  exceptThisSymbols.includes(e.key) && e.preventDefault()
                } value={data.startingpriceperday} name='startingpriceperday' placeholder=' Fee Per Day' onChange={handleChange} step="any" />
              </div>
              <div className='col-6' >
                <label>Description <span style={{ color: 'red' }}>*</span></label>
                <textarea class="form-control" type="text" value={data.about} name='about' placeholder='Description' onChange={handleChange} style={{
                  height: "120px"
                }} />
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col-6' >
              </div>
              <div className='col-6' >
                <button
                  onClick={nextData}
                  className="btn bg-gradient-dark ms-auto mb-0 js-btn-next  d-flex justify-content-end"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Basic
