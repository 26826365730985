import React from 'react';
import '../../../src/App.css';

const Footer = () => {
  return (
    <footer className="footer">
      {/* <div className="container"> */}
        {/* <div className="row align-items-center flex-row-reverse"> */}
          <div className="col-md-12 col-sm-12 mt-3 mt-lg-0 text-center">
            Developed by DATAMOO.AI | Copyright © 2024 <a href="javascript:void(0);" className="text-primary">BookMeVenue.com</a>. All rights reserved.
          </div>
        {/* </div> */}
      {/* </div> */}
    </footer>
  );
}

export default Footer;
