import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import { apiURL } from '../../Layout/Common/confiqurl';

const FileUploader = ({ closeModal }) => {
    const [file, setFile] = useState(null);

    const handleFileUpload = (event) => {
        setFile(event.target.files[0]);
    };

    const uploadFile = () => {
        if (!file) {
            toast.error('Please Select a File to Upload');
            return;
        }

        const token = JSON.parse(sessionStorage.getItem('data')).access_token;
        const formData = new FormData();
        formData.append('file', file); 

        Axios.post(`${apiURL}/uploadfile`, formData, {
            headers: {
                "accept": "application/json",
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            }
        }).then(resp => {
            closeModal();
            toast.success('File Uploaded Successfully');
        }).catch(err => {
            console.error(err);
            toast.error('Error uploading file');
        });
    };

    return (
        <div>
            <Toaster toastOptions={{ position: "top-right" }} />
            <div>
                <input type="file" className="form-control mb-3" onChange={handleFileUpload} accept=".xlsx" />
            </div>
            <div className="d-flex justify-content-center">
                <Button variant="secondary" onClick={closeModal}>
                    Cancel
                </Button>
                <Button type="button" variant="primary" className="ms-3" onClick={uploadFile}>
                    Upload
                </Button>
            </div>
        </div>
    );
};

export default FileUploader;
