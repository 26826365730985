import React, { useState } from 'react'
import { toast, Toaster } from 'react-hot-toast';

const Parking = (props) => {
  const [isToastOpen, setIsToastOpen] = useState(false);

  const { data, handleChange, valetparking, setvaletparking, back, handleSubmit, setoutsideentertainment, outsideentertainment, setoutsidedecorationallowed, outsidedecorationallowed, setbikeparking, bikeparking, setcarparking, carparking } = props;
  const nextData = () => {

    if (data.carparking_count.trim()) {
      if (data.bikeparking_count.trim()) {



        handleSubmit()

      } else {
        if (!isToastOpen) {
          setIsToastOpen(true);
          toast.error('Please Enter No Of Bike')

          setTimeout(() => {
            setIsToastOpen(false);
          }, 3000); // set the timeout based on how long the toast message will be displayed
        }
      }
    }
    else {
      if (!isToastOpen) {
        setIsToastOpen(true);
        toast.error('Please Enter No Of Car');

        setTimeout(() => {
          setIsToastOpen(false);
        }, 3000); // set the timeout based on how long the toast message will be displayed
      }
    }

  }

  return (
    <div class="row"> 
      <div class="col-12 col-lg-8 mx-auto  w-100"> 
        <div class="card w-100">
          <div class="card-body">
            <h5 class="font-weight-bolder">Parking</h5> 
            <div className='row'> 
              <div className='col-6' > 
                <div className=' d-flex justify-content-start mt-4 pt-2  ' >
                  <div className='ms-3 w-50' >
                    <label>Car Parking </label> 
                  </div>
                  <div className='ms-4'> 
                    <div class="form-check form-switch pt-1 ms-3"> 
                      <input class="form-check-input" type="checkbox" id="rememberMe" checked={carparking} name='carparking' placeholder='enter description' onClick={() => setcarparking(!carparking)} />
                    </div>
                  </div> 
                </div> 
                <div className=' d-flex justify-content-start mt-4 pt-2  ' >
                  <div className='ms-3 w-50'>
                    <label>Bike Parking </label> 
                  </div>
                  <div className='ms-4'> 
                    <div class="form-check form-switch pt-1 ms-3"> 
                      <input class="form-check-input" type="checkbox" checked={bikeparking} name='bikeparking' placeholder='enter description' onClick={() => setbikeparking(!bikeparking)} id="rememberMe" />
                    </div>
                  </div> 
                </div> 
                <div className=' d-flex justify-content-start mt-4 pt-2  ' >
                  <div className='ms-3 w-50'>
                    <label>Valet Parking 	 </label> 
                  </div>
                  <div className='ms-4'> 
                    <div class="form-check form-switch pt-1 ms-3"> 
                      <input class="form-check-input" type="checkbox" checked={valetparking} name='valetparking' placeholder='enter description' onClick={() => setvaletparking(!valetparking)} id="rememberMe" />
                    </div>
                  </div> 
                </div> 
                <div className=' d-flex justify-content-start mt-4 pt-2  ' >
                  <div className='ms-3 w-50'>
                    <label>Outside Vendor Allowed </label> 
                  </div>
                  <div className='ms-4'> 
                    <div class="form-check form-switch pt-1 ms-3"> 
                      <input class="form-check-input" type="checkbox" checked={outsidedecorationallowed} name='outsidedecorationallowed' placeholder='enter description' onClick={() => setoutsidedecorationallowed(!outsidedecorationallowed)} />
                    </div>
                  </div> 
                </div> 
                <div className=' d-flex justify-content-start mt-4 pt-2  ' >
                  <div className='ms-3 w-50'>
                    <label>Outside Entertainment Allowed	 </label> 
                  </div>
                  <div className='ms-4'> 
                    <div class="form-check form-switch pt-1 ms-3"> 
                      <input class="form-check-input" type="checkbox" checked={outsideentertainment} name='outsideentertainment' placeholder='enter description' onClick={() => setoutsideentertainment(!outsideentertainment)} id="rememberMe" />
                    </div>
                  </div> 
                </div> 
              </div>
              <div className='col-6' >
                <div className='mt-1' >
                  <label>No .of cars<span style={{ color: 'red' }}>*</span></label>
                  <input class="form-control" type="text" disabled={!carparking} value={data.carparking_count} name='carparking_count' onChange={handleChange} />

                </div>
                <div className=' mt-4' >
                  <label>No .of Bike <span style={{ color: 'red' }}>*</span></label>
                  <input class="form-control" type="text" disabled={!bikeparking} value={data.bikeparking_count} name='bikeparking_count' onChange={handleChange} />
                </div>
              </div>
            </div>
            <div className='row mt-5'>
              <div className="col-6 ">
                <button
                  onClick={back}
                  className="btn bg-gradient-secondary mb-0 js-btn-prev"
                >
                  back
                </button>
              </div>
              <div className="col-6 ">
                <button
                  className="btn bg-gradient-dark ms-auto mb-0 js-btn-next  d-flex justify-content-end"
                  onClick={nextData}
                >
                  save & next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Parking
