import React, { useContext, useEffect, useState } from 'react'
import Select from "react-select";
import Aside from '../../Layout/Aside'
import Navbar from '../../Layout/Navbar'
import Footer from '../../Layout/Footer'
import { MainContext } from '../../Store/Context'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import events from "./Calendar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Icons } from '../../Icons/IconIndex'
import './style.css';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { apiURL } from '../../Layout/Common/confiqurl';
import { toast, Toaster } from 'react-hot-toast';
import { validEmailRegex, validPhoneNumberRegex } from '../../Layout/Common/common';
import Form from 'react-bootstrap/Form';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import Admintable from './Admintable';
import partnertable from './Partnertable';
import axios from 'axios';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Partnertable from './Partnertable';
import Booking_Partnertable from './Booking_PartnerTable';

const ManageBooking = () => {
  const [project, setProject] = useState(null);
  const today = new Date().toISOString().substr(0, 10);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [eventTitle, setEventTitle] = useState("");
  const [eventfulldata, setEventFulldata] = useState("");
  const [eventbname, setEventBname] = useState("");
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [start_time, setStart_Time] = useState("");
  const [endtime, setEndTime] = useState("");
  const [userrole, setuserrole] = useState("")
  const [userid, setuserid] = useState("")
  const { user } = useContext(MainContext)
  const navigate = useNavigate()
  const [value, setValue] = useState('');
  const [events, setevents] = useState([])
  const [Mandapam, setMadapam] = useState([])
  const [inputValue, setInputValue] = useState('');
  const [state1, setState1] = useState([])
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const [currentposts, setCurrentPosts] = useState('');
  const [cancelledbooking, setcancelledbooking] = useState([]);
  const [mergedData, setMergedData] = useState([]);
  const [cancelled_enquiry, setcancelled_enquiry] = useState('')
  const [cancelled_bookings, setcancelled_bookings] = useState('')

  const [message, setMessage] = useState('');
  const handleNavigate = () => {
    navigate('/Partnertable');
  };
  useEffect(() => {
    const storedData = sessionStorage.getItem('data');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setProject(parsedData);
    }
    getcancelledbooking();
    window.scrollTo(0, 0);
  }, []);
  const [selectedOption, setSelectedOption] = useState(null);
  const [state, setState] = useState({
    place_name: "",
    event_type: "",
    contact_number: "",
    select_from_date: "",
    select_to_date: "",
    select_start_time: "",
    select_end_time: "",
    email_id: "",
  })
  const [usernamevalue, setUsernamevalue] = useState('');
  const [start_date, setstart_date] = useState('');
  const [end_date, setend_date] = useState('');
  const [start, setStart] = useState({ select_from_date: "", select_to_date: "" })
  const [end, setEnd] = useState("")
  const handleDateChange = (event) => {
    setStart({
      ...start,
      [event.target.name]: event.target.value
    })
  }
  const [event, setEvent] = useState([])
  const handleInputChange = (event) => {
    const value = event.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setInputValue(value);
    }
  };
  function addOneDay(dateString) {
    const selectedDate = new Date(dateString);
    selectedDate.setDate(selectedDate.getDate() + 1);
    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
    const day = String(selectedDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const dataHandleClose = () => setDataShow(false);
  const openEventModal = (eventInfo) => {
    setEventTitle(eventInfo.event.title);
    setEventFulldata(eventInfo.event.extendedProps.place_name);
    setEventBname(eventInfo.event.extendedProps.name);
    setStartDate(eventInfo.event.extendedProps.start_date);
    setEndDate(eventInfo.event.extendedProps.end_date);
    setStart_Time(eventInfo.event.extendedProps.start_time);
    setEndTime(eventInfo.event.extendedProps.end_time);
    setstart_date(eventInfo.event.extendedProps.start_date);
    setend_date(eventInfo.event.extendedProps.end_date);
    setDataShow(true);
  }

  function renderEventContent(eventInfo) {
    return (
      <> <span onClick={() => openEventModal(eventInfo)}> <i>{eventInfo.event.title}</i> </span> <div> </div> </>)
  }
  const handleDateClick = (event) => {
    let selectedEvent = events.filter((item, index) => { return item.start === event.dateStr });
    if (selectedEvent.length > 0) {
    }
  }
  const handleInputNameChange = (event) => {
    const capitalizedValue = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
    setUsernamevalue(capitalizedValue);
  }
  const EventData = () => {
    const token = JSON.parse(sessionStorage.getItem('data'));
    Axios.get(`${apiURL}/get_eventType`, {
      headers: {
        'Authorization': `Bearer ${token.access_token}`,
        "accept": "application/json",
        "Content-Type": " application/json",
      }
    },)
      .then((response) => {
        setEvent(response.data)
      })
  }
  useEffect(() => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    setuserrole(project.user_role)
    setuserid(project.user_id)
    getData()
    getDatas()
    EventData()
    fetchmandapamNames();
  }, []);
  const [show, setShow] = useState(false);
  const [dataShow, setDataShow] = useState(false);
  const [mandapamList, setmandapamList] = useState('')
  const handleClose = () => {
    setState({})
    setUsernamevalue("")
    setInputValue("")
    setStart({})
    setShow(false);
  }
  const handleShow = () => setShow(true);
  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };
  const fetchmandapamNames = () => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    const url = project.user_role === "admin"
        ? `${apiURL}/Mandapam_List_for_partner`
        : `${apiURL}/Mandapam_List_for_partner?partner_id=${project.user_id}`;
  
    Axios.get(url,
      {
        headers: {
          'Authorization': `Bearer ${project.access_token}`,
          "accept": "application/json",
          "Content-Type": " application/json",
        }
      },)
      .then((response) => {
        const sortedData = response.data
          .filter(item => item.mandapam_name)
          .sort((a, b) => a.mandapam_name.localeCompare(b.mandapam_name));
        const formattedData = sortedData.map(item => ({ value: item.id, label: item.mandapam_name }));
        setmandapamList(formattedData);
      });
  }
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setState(prevState => ({
      ...prevState,
      place_name: selectedOption ? selectedOption.value : ""
    }));
  };
  const handleclick = (e) => {
    e.preventDefault();
    const project = JSON.parse(sessionStorage.getItem('data'));
    const payload = {
      place_name: selectedOption.label,
      event_type: state.event_type,
      name: usernamevalue,
      contact_number: inputValue,
      start_date: start.select_from_date,
      end_date: start.select_to_date,
      start_time: state.select_start_time,
      end_time: state.select_end_time,
      email_id: state.email_id,
      mandapam_id: selectedOption.value,
      active_status: true
    }
    if (!isToastOpen) {
      setIsToastOpen(true);
      if (usernamevalue.trim()) {
        if (validPhoneNumberRegex.test(inputValue)) {
          if (validEmailRegex.test(state.email_id)) {
            if (state.event_type.trim()) {
              if (start.select_from_date.trim()) {
                if (start.select_to_date.trim()) {
                  if (state.select_start_time.trim()) {
                    if (state.select_end_time.trim()) {
                      Axios.post(`${apiURL}/Booking_event?user_id=${project.user_id}`, payload,
                        {
                          headers: {
                            'Authorization': `Bearer ${project.access_token}`,
                            "accept": "application/json",
                            "Content-Type": " application/json",
                          }
                        },
                      ).then((response) => {
                        if (response.data.detail) {
                          toast.success(response.data.detail)
                          getData()
                          handleClose()
                          // window.location.reload()
                        }
                        else {
                          toast.error(response.data)
                        }
                      })
                        .catch((err) => {
                          {
                            toast.error("Invalid User")
                          }
                        });
                    } else {
                      toast.error('Please Enter End Time');
                    }
                  } else {
                    toast.error('Please Enter Start Time');
                  }
                } else {
                  toast.error('Please Enter End Date');
                }

              } else {
                toast.error('Please Enter Start Date');
              }
            } else {
              toast.error('Select Event Type');
            }
          } else {
            toast.error('Please Enter Valid Email');
          }
        } else {
          toast.error('Please Enter  Mobile Number');
        }
      } else {
        toast.error('Please Enter Organizer Name');
      }
      setTimeout(() => {
        setIsToastOpen(false);
      }, 3000);
    }
  }
  const handleEvents = (data) => {
    const newEvents = data.map((item) => ({
      title: item.event_type,
      start: item.start_date,
      end: addOneDay(item.end_date),
      place_name: item.place_name,
      name: item.name,
      start_date: item.start_date,
      end_date: item.end_date,
      start_time: item.start_time,
      end_time: item.end_time,
      className: item.event_type == "Wedding" ? "danger" : item.event_type === "Birthday" ? "primary" : item.event_type == "Reception" ? "warning" : item.event_type == "Corporate Meeting" ? "info" : item.event_type == "Anniversary" ? "success" : item.event_type == "Retirement Function" ? "info" : "",
    }));
    setevents((prevState) => [...prevState, ...newEvents]);
  };
  const getData = () => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    Axios.get(`${apiURL}/Booking_event?user_id=${project.user_id}`,
      {
        headers: {
          'Authorization': `Bearer ${project.access_token}`,
          "accept": "application/json",
          "Content-Type": " application/json",
        }
      }).then((response) => {
        setValue(response.data.Booking.concat(response.data.Enquiry))
        handleEvents(response.data.Booking.concat(response.data.Enquiry))
      })
      .catch((err) => {
        {
          console.log(err)
        }
      });
  }
  const getDatas = () => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    if (project.user_role == "Partner") {
      Axios.get(`${apiURL}/mandapam_details?user_id=${project.user_id}`, {
        headers: {
          'Authorization': `Bearer ${project.access_token}`,
          "accept": "application/json",
          "Content-Type": " application/json",
        }
      },)
        .then((response) => { 
          setState1(response.data)
          storeMandapam(response.data)
        })
    } else if (project.user_role == "admin") {
      Axios.get(`${apiURL}/All_Booking_event`, {
        headers: {
          'Authorization': `Bearer ${project.access_token}`,
          "accept": "application/json",
          "Content-Type": " application/json",
        }
      },)
        .then((response) => { 
          setState1(response.data)
          storeMandapam(response.data)
        })
    } else {
      console.log("data");
    }
  }
  const storeMandapam = (data) => {
    if (!Array.isArray(data)) {
      return;
    }
    const newEvents = data.map((item) => ({
      label: item.mandapam_name,
      value: item.id,
    }));
    setMadapam((prevState) => [...prevState, ...newEvents]);
  }
  const getcancelledbooking = () => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    const token = JSON.parse(sessionStorage.getItem('data'));
    Axios.get(`${apiURL}/get_cancelled_booking_for_partner?partner_id=${project.user_id}`, {
      headers: {
        'Authorization': `Bearer ${token.access_token}`,
        "accept": "application/json",
        "Content-Type": " application/json",
      }
    },)
      .then((response) => {
        const { cancelled_enquiry, cancelled_bookings } = response.data;
        setcancelledbooking([...cancelled_enquiry, ...cancelled_bookings]);
      })
  }

  const [activeTab, setActiveTab] = useState("booking");
  const [booking_show, setBooking_show] = useState(true);
  const [view_table_show, setView_table_show] = useState(false);

  const ChangeTab = (tabname) => {
    setevents([])
    getData()
    setActiveTab(tabname);
    if (tabname === "booking") {
      setBooking_show(true);
      setView_table_show(false);
    } else if (tabname === "view_table") {
      setBooking_show(false);
      setView_table_show(true);
    }
  };



  const [state12, setState12] = useState([])

  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);


  const [openpopupView1, setopenpopupView1] = useState(false);


  const [editedData, setEditedData] = useState({});
  const [viewValue, setviewValue] = useState()
  const [editeValue, setEditValue] = useState()
  const [selectedBooking, setSelectedBooking] = useState({})

  const [openpopupDelete, setopenpopupDelete] = useState(false);
  const [deleteValue, setDeleteValue] = useState()

  const [itemsPerPage, setItemsPerPage] = useState(5);
  useEffect(() => {
    getData1()
  }, []);

  const [data, setData] = useState()
  const [datas, setDatas] = useState()


  const [sortConfig, setSortConfig] = useState(
    { key: 'place_name', direction: 'descending' },
    { key: 'event_type', direction: 'descending' },
    { key: 'start_date', direction: 'descending' },
    { key: 'end_date', direction: 'descending' },
    { key: 'start_time', direction: 'descending' },
    { key: 'end_time', direction: 'descending' },
    { key: 'contact_number', direction: 'descending' },
    { key: 'name', direction: 'descending' },
    { key: 'active_status', direction: 'descending' }
  ); 
  const getData1 = () => {
    const project = JSON.parse(sessionStorage.getItem('data')); 
    Axios.get(`${apiURL}/Booking_event?user_id=${project.user_id}`, {
      headers: {
        'Authorization': `Bearer ${project.access_token}`, 
        "accept": "application/json",
        "Content-Type": " application/json",
      } 
    }).then((response) => { 
      const { Booking, Enquiry } = response.data; 
      const typedBookings = Booking.map(item => ({ ...item, type: 'booking' }));
      const typedEnquiries = Enquiry.map(item => ({ ...item, type: 'enquiry' })); 
      setState12([...typedBookings, ...typedEnquiries]); 
    })
      .catch((err) => {
        { 
          console.log(err)
        }
      });
  } 
  const handleToggleStatus = (id, itemMode) => { 
    if (itemMode === 'enquiry') {
      toast.error("Cannot Edit Toggle Status For Enquiries.");
      return;
    } 
    const updatedData = state1.map((row) => {
      if (row.id === id) {
        setData(!row.active_status);
        handleEdit(!row.active_status, id); 
        return { ...row, active_status: !row.active_status };
      }
      return row;
    });
    setState12(updatedData);
  }; 
  const filteredData = Array.isArray(state12)
    ? state12.filter((item) => {
      const itemValues = Object.values(item).join('').toLowerCase();
      return itemValues.includes(searchQuery.toLowerCase());
    })
    : []; 
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };  
  const sortedData = filteredData.sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  }); 
  const handleClick = (page) => {
    setCurrentPage(page); 
  }; 
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);  
  }; 
  const pages = Math.ceil(filteredData.length / itemsPerPage);
  const startPage = Math.max(1, currentPage - 2);
  const endPage = Math.min(pages, startPage + 4); 
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, filteredData.length - 1); 
  const displayedItems = sortedData.slice(startIndex, endIndex + 1); 
  const paginationButtons = [];
  for (let i = startPage; i <= endPage; i++) {
    paginationButtons.push(
      <li key={i} className={i === currentPage ? "active" : ""}>
        <a key={i} className='active' onClick={() => handleClick(i)} style={{ cursor: "pointer" }} > {i} </a>
      </li>
    );
  }
  const handleEdit = (value, id) => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    const payload = {
      active_status: value,
    }
    if (!isToastOpen) {
      setIsToastOpen(true);
      Axios.put(`${apiURL}/cancelled_status_in_booking_enquiry?flag=booking&id=${id}`, payload, {
        headers: {
          'Authorization': `Bearer ${project.access_token}`,
          "accept": "application/json",
          "Content-Type": " application/json"
        }
      }).then((response) => {
        toast.success('Successfully Updated');
        getData();
      }).catch((err) => {
        toast.error("Invalid User");
      });
      setTimeout(() => {
        setIsToastOpen(false);
      }, 3000);
    }
  }
  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setSelectedBooking((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleView = (id) => {
    const token = JSON.parse(sessionStorage.getItem('data'));
    const project = JSON.parse(sessionStorage.getItem('data'));
    const queryString = Object.keys(selectedBooking).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(selectedBooking[key])}`).join('&');
    axios.put(`${apiURL}/Booking_Event_Update/${id}?${queryString}`, null, {
      headers: {
        'Authorization': `Bearer ${project.access_token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        setSelectedBooking(res.data);
        getData();
      })
      .catch((error) => {
        console.error("Error fetching booking details:", error);
      });
  }
  const handleView1 = (id) => {
    const token = JSON.parse(sessionStorage.getItem('data'));
    const project = JSON.parse(sessionStorage.getItem('data'));
    const queryString = Object.keys(selectedBooking).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(selectedBooking[key])}`).join('&');
    axios.put(`${apiURL}/Booking_Event_Update/${id}?${queryString}`, null, {
      headers: {
        'Authorization': `Bearer ${project.access_token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        toast.success("Updated Data Successfully");
        setSelectedBooking(res.data);
        getData();
        closeModalView1(id);
      })
      .catch((error) => {
        console.error("Error fetching booking details:", error);
      });
  }
  const openModalView1 = (id) => {
    handleView(id);
    setEditedData({ ...data });
    setopenpopupView1(true);
    setviewValue(id)
    setEditValue(id)
  }
  const closeModalView1 = (id) => {
    setopenpopupView1(false);
  };
  const openModalDelete = (id) => {
    setopenpopupDelete(true);
    setDeleteValue(id)
  }
  const getDatadelete = () => {
    const id = deleteValue;
    const project = JSON.parse(sessionStorage.getItem('data'));
    Axios.delete(`${apiURL}/Booking_Event_Delete?id=${id}`, {
      headers: {
        'Authorization': `Bearer ${project.access_token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
      .then(response => {
        if (response.data.detail === "Booking Event Deleted Successfully") {
          toast.success("Booking Event Deleted Successfully");
          getData();
          setopenpopupDelete(false);
        }
      })
      .catch(error => {
        toast.error("Error deleting booking detail.");
      });
  };
  const closeModalDelete = () => {
    setopenpopupDelete(false);
  }; 
  return (
    <div className={user ? "g-sidenav-show bg-gray-100 g-sidenav-hidden " : "g-sidenav-show  bg-gray-100"} >
      <Toaster toastOptions={{ position: "top-right" }} />
      <Aside />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Navbar Name='Manage Booking' />
        <div className="container-fluid py-4" style={{ minHeight: "560px" }}>
          <div className='card p-4 mt-4'>
            <div className='row'>
              <div class="nav-wrapper position-relative end-0">
                <div className='row'>
                {project && project.user_role == "Partner" &&(

                  <div className='col-md-6' style={{marginTop: '30px'}}>
                    <ul className="nav nav-pills nav-fill p-1" role="tablist">
                      <li className="nav-item curs_pointer" onClick={() => ChangeTab("booking")}>
                        <a
                          className={`nav-link mb-0 px-0 py-1 ${activeTab === "booking" ? "active" : ""}`}
                          data-bs-toggle="tab"
                          role="tab"
                          aria-controls="Booking"
                          aria-selected={activeTab === "booking"}
                        >
                          Calendar View
                        </a>
                      </li>
                      <li className="nav-item curs_pointer" onClick={() => ChangeTab("view_table")}>
                        <a
                          className={`nav-link mb-0 px-0 py-1 ${activeTab === "view_table" ? "active" : ""}`}
                          data-bs-toggle="tab"
                          role="tab"
                          aria-controls="view_table"
                          aria-selected={activeTab === "view_table"}
                        >
                          Table View
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
                  <div className='col-md-12'>
                    <div class="row">
                    <div className='col-md-4'>
                      <div className=''>
                        <label>Choose Venues </label>
                        <Select value={selectedOption} onChange={handleSelectChange} options={mandapamList} isSearchable={true} className="w-100 select-change" />
                      </div>
                      </div>
                      <div className='col-md-6 mt-4'>  
                        <button type="button" class="btn bg-gradient-primary  ms-2  py-2 my-2 " disabled={selectedOption === null} target="_blank" onClick={handleShow}>+&nbsp; Book</button>
                        <label style={{color: 'red'}}>Please Choose Venue before Booking</label>
                       </div>
                    </div>
                  </div>
                </div>
              </div>

              {booking_show &&
                <>

                  <Modal size="xl" show={show} onHide={handleClose} animation={false}>
                    <div className='d-flex justify-content-between mt-3' >
                      <Modal.Title className='ms-4 '>Booking  Event</Modal.Title>
                      <button className='me-3 btn btn-outline-secondary  ' onClick={handleClose}> x </button>
                    </div>
                    
                    <div className='m-5'>
                      <div className='row '>
                        <div className='col-12' >
                          <label>Place Name  </label>
                          <input class="form-control" type="text"  placeholder='Place Name ' name='place_name' value={selectedOption && selectedOption.label} disabled />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-6' >
                          <label> Event Organizer Name<span style={{color:'red'}}>*</span>  </label>
                          <input class="form-control" type="text" placeholder='Event Organizer Name ' name='enter_event_organizer' value={usernamevalue} onChange={handleInputNameChange} />
                        </div>
                        <div className='col-6' >
                          <label>Mobile Number <span style={{color:'red'}}>*</span></label>
                          <input class="form-control" type="number" placeholder=' Mobile Number' name='contact_number' onKeyDown={(e) =>
                            exceptThisSymbols.includes(e.key) && e.preventDefault()
                          } value={inputValue} onChange={handleInputChange} />
                        </div>
                      </div>
                      <div className='row '>
                        <div className='col-6' >
                          <label>Email <span style={{color:'red'}}>*</span> </label>
                          <input type='text' class="form-control " placeholder=' Email ' name='email_id' value={state.email_id} onChange={handleChange} />
                        </div>
                        <div className='col-6' >
                          <label>Event Type	<span style={{color:'red'}}>*</span> </label>
                          <select id="inputState" class="form-select " name='event_type' value={state.event_type} onChange={handleChange} style={{ cursor: "pointer" }}>
                            <option value="" disabled selected hidden>
                              Select an option
                            </option>
                            {
                              event.map((data) => {
                                return (
                                  <option style={{ cursor: "pointer" }}> {data.data_value}</option>

                                )
                              })
                            }
                          </select>
                        </div>

                      </div>

                      <div className='row'>
                        <div className='col-6'>
                          <label>From Date<span style={{color:'red'}}>*</span> </label>
                          <input
                            className="form-control"
                            type="date"
                            placeholder='Enter From Date'
                            name='select_from_date'
                            min={today}
                            onChange={handleDateChange}
                            onFocus={(e) => e.target.showPicker()}
                          />
                        </div>
                        <div className='col-6'>
                          <label>To Date<span style={{color:'red'}}>*</span> </label>
                          <input className="form-control" type="date" placeholder='Enter To Date' name='select_to_date' min={today} onChange={handleDateChange} onFocus={(e) => e.target.showPicker()} />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-6' >
                          <label> Start Time<span style={{color:'red'}}>*</span> </label>
                          <input class="form-control" type="time" onFocus={(e) => e.target.showPicker()} placeholder='Enter Start Time' name='select_start_time' value={state.select_start_time} onChange={handleChange} />
                        </div>
                        <div className='col-6' >
                          <label> End Time<span style={{color:'red'}}>*</span> </label>
                          <input class="form-control" type="time" placeholder='Enter End Time' name='select_end_time' value={state.select_end_time} onChange={handleChange} onFocus={(e) => e.target.showPicker()}/>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-center me-5'>
                      <Button variant="secondary" onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button variant="primary" className='ms-3 me-2' onClick={handleclick}>
                        Submit
                      </Button>
                    </div>
                  </Modal>
                  {project && project.user_role == "Partner" && (
                    <div className='row'>
                      <div class="col-xl-12 mt-5">
                        <div class="card card-calendar">
                          <div class="card-body p-3">
                            <FullCalendar
                              defaultView="dayGridMonth"
                              header={{
                                left: "prev,next",
                                cEnter: "title",
                                right: "dayGridMonth,timeGridWeek,timeGridDay"
                              }}
                              themeSystem="Simplex"
                              plugins={[dayGridPlugin, interactionPlugin]}
                              selectable
                              editable
                              events={events}
                              eventContent={renderEventContent}
                              height="720px"
                              dateClick={handleDateClick}
                              eventClick={renderEventContent}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {project && project.user_role === 'admin' && (
                    <Admintable />
                  )}
                </>}
              {view_table_show &&
                
                <Booking_Partnertable />
              }
            </div>
          </div>
        </div>
        <Footer />
      </main>
      <div>
        <Modal show={dataShow} onHide={dataHandleClose} className='txtfulllastdata'>
          <Modal.Header closeButton>
            <Modal.Title><h4>Booking Information</h4></Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <div className='d-flex justify-content-start'>
              <p className='txtmanname'>Venue Name:  </p>
              <p>{eventfulldata}</p>
            </div>
            <div className='d-flex justify-content-start'>
              <p className='txtmanname'>Booking UserName:  </p>
              <p>{eventbname}</p>
            </div>
            <div className='d-flex justify-content-start'>
              <p className='txtmanname'>Date:  </p>
              <p>{start_date}  to  {end_date}</p>
            </div>
            <div className='d-flex justify-content-start'>
              <p className='txtmanname'>Time:  </p>
              <p>{start_time}  to  {endtime}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={dataHandleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}
export default ManageBooking

