import React, { useEffect, useState } from 'react'
import "../Style.css"
import axios from 'axios';
import { apiURL } from '../../../Layout/Common/confiqurl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
const Banner = () => {
    const navigate=useNavigate()

    const [state, setState] = useState([])
    const [Value, setValue] = useState({
        capacity:"",
        placeType:"",
        Location:"",
    })

    const handleChange = (event) => {
        setValue({
            ...Value,
            [event.target.name]: event.target.value,
        });
        handleClick()
    };

console.log(Value,"lklk");
    useEffect(() => {


        getData()
    }, []);
    const getData = () => {
        const token = JSON.parse(sessionStorage.getItem('data'));
        axios.get(`${apiURL}/get_Venue_Type`, {
            headers: {
                'Authorization': `Bearer ${token.access_token}`,

                "accept": "application/json",
                "Content-Type": " application/json",
            }
        },)
            .then((response) => {
                // console.log(response.data);
                setState(response.data)
            })
    }



    const handleClick =(e)=>{
        const project = JSON.parse(sessionStorage.getItem('data'));
      let payload={}
        if (Value.capacity && Value.placeType &&Value.Location) {
            payload = {
                Area:Value.Location ,
                Place_type: Value.placeType,
                Floating_Capacity: Value.capacity
            };
          } else if (Value.capacity && Value.Location) {
            payload = {
                Area:Value.Location ,
                Floating_Capacity: Value.capacity
            };
          } 
          else if (Value.capacity && Value.placeType) {
            payload = {
                Place_type: Value.placeType,
                Area:Value.Location 
            };
          }
          else if (Value.placeType) {
            payload = {
                Place_type: Value.placeType
            };
          }
          else if (Value.capacity ) {
            payload = {
                Floating_Capacity: Value.capacity
            };
          }
          else if (Value.Location) {
            payload = {
                Area:Value.Location 

            };
          }
          
          else {
            payload = {
            
            };
          }
      
      
      
          
                                  axios.post(`${apiURL}/Homepage_details`, payload, {
                                      headers: {
                                          "accept": "application/json",
                                          "Content-Type": " application/json",
                                          'Authorization': `Bearer ${project.access_token}`,
      
                                      }
                                  },
                                  ).then((response) => {
                                      console.log('loginsuccess', Value);
                                 
                                      navigate("/Second",{state:{value1:response.data,value2:Value}})
      
                                      
                                  })
                                      .catch((err) => {
                                          {
                                              toast.error(err)
                                              // setIsLoading(false);
                                          }
                                      });
                         
                    
      
      
      
      }


    return (
        <div className='mx-1'>
            <div className='Banner_1_container pt-5'>
                <div className='row'>
                    <div className='col-lg-2'></div>
                    <div className=' col-lg-8 ms-5 select_container pb-3'>

                        <div>
                            <h6 className='Banner_1_Heading ps-4 pt-3'>Sort by</h6>
                        </div>
                        <div className='row mx-1 d-flex justify-content-evenly'>
                            <div className='col-lg-4 col-sm-6 '>
                                <select class="form-select-md p-1  w-100" name='Location' onClick={handleChange}>
                                    <option  className='m-2' disabled selected hidden>Areas</option>
                                    <option className='m-2' value="Chennai">Chennai</option>
                                    <option className='m-2' value="Madurai">Madurai</option>
                                </select>
                            </div>
                            <div className='col-lg-4 col-sm-6'>
                                <select class="form-select-md p-1 w-100" name='placeType' onClick={handleChange}>
                                    <option value="" disabled selected hidden>
                                        Venue Type
                                    </option>
                                    {
                                        state.map((data) => {
                                            return (
                                                <option > {data.data_value}</option>

                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-lg-4 col-sm-6'>
                                <select class="form-select-md p-1 w-100" name='capacity' onClick={handleChange}>
                                    <option className='px-5'  disabled selected hidden>Capacity</option>
                                    <option className='px-5'  value="0-250" >0-250</option>
                                    <option className='px-5'  value="250-500">250-500</option>
                                    <option className='px-5'  value="500-750">500-750</option>
                                    <option className='px-5'  value="750-1000">750-1000</option>

                                </select>
                            </div>
                            {/* <div className='col-lg-3 col-sm-6'>
                       
                                <input class="form-select-md p-1 w-100 " type='Date' />
                            </div> */}
                        </div>

                    </div>
                    <div className='col-lg-2'></div>

                </div>

                <div className=' row  pt-5 mt-5 me-5 '>
                    <div className='col-lg-1'></div>
                    <div className=' col-lg-4 mx-5 mt-3   bg-white ' style={{ borderRadius: "15px" }}>
                        <div>
                            <h2 className='Banner_1_Heading_2  pt-3 mx-4'>Search for the city and book venue</h2>
                        </div>
                        <div>
                            <p className='  pt-3 mx-4' style={{ color: "#6E5240" }}>Now you can select venue in which ever city you like</p>
                        </div>
                        <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                            <div className="input-group mb-4">
                                <span className="input-group-text text-body"><i className="fas fa-search" aria-hidden="true" style={{ marginTop: "40px", marginRight: "-2px", zIndex: 1, color: "#949494" }}></i></span>
                                <input className="w-100 mb-2 mx-4 p-1" placeholder="Search..." type="text" style={{ border: "1px solid #F3F3F3", borderRadius: "6px" }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-7'></div>
                </div>
            </div>
        </div>
    )
}

export default Banner
