import React from 'react'
import Header from '../Main/Header'
import Footer from '../../../Layout/Footer'
import ImageGallery from './ImageGallery'
import About from './About'
import Details from './Details'
import Reviews from './Reviews'
import Banner6 from '../Main/Banner6'
import { useLocation } from 'react-router-dom'

const FullDetails = () => {
  const location=useLocation()
  const allData=location.state
  return (
    <div>
        <Header />
      <ImageGallery allData={allData} />
      <About allData={allData} />
      <Details allData={allData} />
      <Reviews />
      <Banner6 />
      <Footer />
    </div>
  )
}

export default FullDetails
