import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const Session = ({ timeout = 300000}) => {
  const navigate = useNavigate();
  const timer = useRef(null);

  const resetTimer = () => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      handleLogout();
    }, timeout);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.clear()
    navigate('/');
  };

  useEffect(() => {
    const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

    const resetTimerOnEvent = () => {
      resetTimer();
    };

    events.forEach((event) => {
      window.addEventListener(event, resetTimerOnEvent);
    });

    resetTimer();

    return () => {
      clearTimeout(timer.current);
      events.forEach((event) => {
        window.removeEventListener(event, resetTimerOnEvent);
      });
    };
  }, [navigate, timeout]);

  return null;
};

export default Session;
