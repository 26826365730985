import Images from "../../../Images";


export const cardData = [
    {
      id: 1,
      imageurl:Images.cardimage1 ,
      name: "Chennai",
    
    },
    {
      id: 2,
      imageurl:Images.cardimage2,
      name: "Madurai",
     
    },
    {
      id: 3,
      imageurl:Images.cardimage3,
      name: "Bengaluru",
    
    },
    {
      id: 4,
      imageurl:Images.cardimage4,
      name: "Mysore",
    
    },
    {
      id: 5,
      imageurl:Images.cardimage5,
      name: "Visakapatnam",
   
    },
    {
      id: 6,
      imageurl:Images.cardimage6,
            name: "Thiruvananthapuram",
  
    },
    {
      id: 7,
      imageurl:Images.cardimage7,
            name: "Nagpur",
     
    },
    {
      id: 8,
      imageurl:Images.cardimage8,
            name: "Jaipur",
    
    },
    {
        id: 9,
        imageurl:Images.cardimage9,
              name: "Dehli",
      
      },
      {
        id: 10,
        imageurl:Images.cardimage10,
              name: "Goa",
      
      },
      {
        id: 11,
        imageurl:Images.cardimage11,
              name: "Coimbatore",
      
      },
      {
        id: 12,
        imageurl:Images.cardimage12,
              name: "Indore",
      
      },
  ];