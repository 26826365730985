import React from 'react'
import "../Style.css"
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { apiURL } from '../../../Layout/Common/confiqurl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import Images from '../../../Images'
import { useNavigate } from 'react-router-dom'
const Part1 = (props) => {
    const {allData}=props
    const [click,setClick]=useState("")
    const [state, setState] = useState([])
    const [Details, setDetails] = useState([])
const navigate= useNavigate()
    useEffect(() => {

        getData()
    }, []);
    const getData = () => {
        const token = JSON.parse(sessionStorage.getItem('data'));
        axios.get(`${apiURL}/get_Venue_Type`, {
            headers: {
                'Authorization': `Bearer ${token.access_token}`,

                "accept": "application/json",
                "Content-Type": " application/json",
            }
        },)
            .then((response) => {
                // console.log(response.data);
                setState(response.data)
            })
    }


const GetcardDetails=(e)=>{
    setClick(e.target.value)

    const token = JSON.parse(sessionStorage.getItem('data'));
   axios.get(`${apiURL}/get_place_type?venue_type=${click}`, {
    headers: {
        'Authorization': `Bearer ${token.access_token}`,

        "accept": "application/json",
        "Content-Type": " application/json",
    }
},)
    .then((response) => {
        // console.log(response.data);
        setDetails(response.data)
    })

}

console.log(Details,"klm");
  return (
    <div className='main px-3  mt-5 mx-5'>
           <div className='row'  >
           {
                                        state.map((data) => {
                                            return (
                                                <div className='col-lg-2 col-sm-4' >
                                           <button type='button'class={click===data.data_value?" px-5 mb-4 py-1 button-group  clicked":"px-3  button-group py-1 mb-4  "} value={data.data_value} onClick={(e)=>GetcardDetails(e)}  > {data.data_value}</button>

                                                <div> 
                                              {/* <button  type="button"  class={click===data.data_value?"btn px-5 mt-4  mx-2 button-group clicked":"btn px-5 mx-2 mt-4 button-group "} value={data.data_value} onClick={(e)=>handleClick(e)} style={{width:"250px"}}>{data.data_value}</button> */}
                                          </div>
                                                </div>
                                            )
                                        })
                                    }
     
    
      </div> 

      <div className="Banner2 mx-5 px-3" >
      {/* <h3 className='my-4 text-center  Carousel-name '>Explore Venues in all the major Indian Cities</h3> */}
    <div className=' row pt-5'>
        {
          click?  Details.map((item) => {
            return(
                <div className='col-lg-4 col-sm-6  my-2'>
                <div className="card px-4 pt-4">
                  <img className="product--image" src={Images.cardimage15} height={200}  alt="product image" />
                  <h5 className='Carousel-name pt-3'>{item.mandapam_name}</h5>
                  <h6 className='Carousel-name pt-1'><FontAwesomeIcon className='  mx-1' icon={faLocationDot} />{item.address}</h6>

                  <p className="price" style={{fontSize:"18px"}}>You can experience luxury and best Ambeince with us</p>
                  <p>
                    <div className='col-12'>                                                                          
                    <button  type="button"  class="btn px-5 mt-4 text-white w-100"  style={{backgroundColor:" #836D5D"}} onClick={()=>{navigate("/FullDetails",{state:item})}} >View Details</button>

                    </div>
                  </p>
                </div>
                </div>
            )})
          
          
          :allData.slice(0,25).map((item) => {
                return(
                    <div className='col-lg-4 col-sm-6  my-2'>
                    <div className="card px-4 pt-4" style={{height:"570px"}}>
                      <img className="product--image" src={Images.cardimage15} height={200}  alt="product image" />
                      <h5 className='Carousel-name pt-3'>{item.mandapam_name}</h5>
                      <h6 className='Carousel-name pt-1'><FontAwesomeIcon className='  mx-1' icon={faLocationDot} />{item.address}</h6>
                      <div style={{height:"130px"}}>
                      <p className="price" style={{fontSize:"18px"}}>You can experience luxury and best Ambeince with us</p>
                      </div>
                      <p>
                        <div className='col-12'>                                                                          
                        <button  type="button"  class="btn px-5 mt-4 text-white w-100"  style={{backgroundColor:" #836D5D"}} onClick={()=>{navigate("/FullDetails",{state:item})}} >View Details</button>

                        </div>
                      </p>
                    </div>
                    </div>
                )})
        }
      
    </div>
    
    </div>

    </div>
  )
}

export default Part1
