
import { faAsterisk, faBoxOpen, faFileCirclePlus, faFilePen, faGear, faGlobe, faHouseLaptop, faIdBadge, faLocation, faPlusCircle ,faPhone} from '@fortawesome/free-solid-svg-icons';



export const sideData=[
    {
        type:"all",
        icon:faHouseLaptop,
        name:"Dashboard",
        path:"/dashboard",
    
    },
    {
        icon:faFileCirclePlus,

        name:"Add New",
        path:"/AddNew",
        childrens:[
        ]
    },
    {
        icon:faGlobe,

        name:"Venues",
        path:"/AllPlaces",
        childrens:[
        ]
    },
    {
        icon:faBoxOpen,
        color:'text-warning',

        name:"Enquiries",
        path:"/Enquiry",
        childrens:[
        ]
    },
  
 
    {
        icon:faPlusCircle,

        name:"Bookings",
        path:"/ManageBooking",
        childrens:[
        ]
    },

    // {
    //     icon:faIdBadge,

    //     name:"Premium Partner",
    //     path:"/PremiumPartner",
    //     childrens:[
    //     ]
    // },
    // {
    //     icon:faLocation,

    //     name:"Request Location/Area",
    //     path:"/RequestLocation",
    //     childrens:[
    //     ]
    // },
    // {
    //     icon:faGear,

    //     name:"Settings",
    //     path:"/Settings",
    //     childrens:[
    //     ]
    // },

    // {
    //     icon:faAsterisk,

    //     name:"Policy & Condition",
    //     path:"/PolicyCondition",
    //     childrens:[
    //     ]
    // },
    // {
    //     icon:faFilePen,
    //     name:"Create Admin",
    //     path:"/CreateAdmin",
    //     childrens:[
    //     ]
    // },
    
]

export const sideData1=[
    {
        type:"all",
        icon:faHouseLaptop,
        name:"Dashboard",
        path:"/dashboard",
    
    },
   
    {
        icon:faFileCirclePlus,

        name:"Partners",
        path:"/Partners",
        childrens:[
        ]
    },
    {
        icon:faGear,

        name:"Assign a Partner",
        path:"/AssignPartner",
        childrens:[
        ]
    },
  

    {
        icon:faFileCirclePlus,

        name:"Add Venue",
        path:"/AddNew",
        childrens:[
        ]
    },
    {
        icon:faGlobe,

        name:"Venues",
        path:"/AllPlaces",
        childrens:[
        ]
    },
    {
        icon:faBoxOpen,

        name:"Enquiries",
        path:"/Enquirys",
        childrens:[
        ]
    },
  
 
    {
        icon:faPlusCircle,

        name:"Bookings",
        path:"/ManageBooking",
        childrens:[
        ]
    },

    // {
    //     icon:faIdBadge,

    //     name:"Manage Premium Partner",
    //     path:"/PremiumPartner",
    //     childrens:[
    //     ]
    // },
    {
        icon:faLocation,

        name:"Master Data",
        path:"/ManageMasterData",
        childrens:[
        ]
    },
    {
        icon:faFilePen,

        name:"Bulk Upload",
        path:"/BulkUpload",
        childrens:[
        ]
    },
    {
        icon:faPhone,

        name:"Contact Details",
        path:"/Contactdetail",
        childrens:[
        ]
    },
    // {
    //     icon:faAsterisk,

    //     name:"Complaints & Queries",
    //     path:"/PolicyCondition",
    //     childrens:[
    //     ]
    // },
   

    // {
    //     icon:faGear,

    //     name:"Settings",
    //     path:"/Settings",
    //     childrens:[
    //     ]
    // },
    // {
    //     icon:faGear,

    //     name:"Assign a Partner",
    //     path:"/AssignPartner",
    //     childrens:[
    //     ]
    // },
    // {
    //     icon:faFilePen,
    //     name:"Create Admin",
    //     path:"/CreateAdmin",
    //     childrens:[
    //     ]
    // },

    
    
]