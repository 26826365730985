import { Avatar } from '@material-ui/core'
import React from 'react'
import Images from '../../../Images'
import { Review } from './data'
import { Container, Row, Col } from 'react-bootstrap';

const data = [
  {id: 1, title: 'Card 1'},
  {id: 2, title: 'Card 2'},
  {id: 3, title: 'Card 3'},
  {id: 4, title: 'Card 4'},
  {id: 5, title: 'Card 5'},
  {id: 6, title: 'Card 6'},
  {id: 7, title: 'Card 7'},
  {id: 8, title: 'Card 8'},
];
const Reviews = () => {
  return (
    <div className='px-3   mt-5 mx-5  mb-5'>
             <h5 className='text-black mx-3 mb-3 '>Reviews</h5>
     <div className='card p-5'>
     <h2 className='text-black mx-3 mb-3 ps-3'>5.0.6 Reviews</h2>
     <Row>
        <Col md={12}>
          <Row>
            {Review.map(item => (
              <Col md={6} key={item.id}>
               <div className='row'>
     <div className="col-7">
         <div className='d-flex justify-content-start'>
         <div> <Avatar alt="Remy Sharp" className='mt-2 ' src={item.avatar} /></div>
         <div> <div>
             <label className='ms-3 mb-0 pb-0' style={{fontSize:"15px"}}>{item.name}</label>
            <div className='mb-0 pb-0'><img src={Images.Vector} alt='' className='ms-3' /><img src={Images.Vector} alt=''className='ms-2' /><img src={Images.Vector} alt='' className='ms-2' /><img src={Images.Vector} alt='' className='ms-2'/><img src={Images.Vector} alt=''className='ms-2' /></div> 
             </div></div>

         </div>
     </div>
     <div className='col-5'><label>12 Apr</label></div>
     </div>
<div className='ms-1 mt-3'>
 <p>The rooms were clean, very comfortable, and the staff was amazing. They went over and beyond to help make our stay enjoyable. I highly recommend this hotel for anyone visiting downtown</p>
 </div>   
              </Col>
            ))}
          </Row>
        </Col>
       
      </Row>
 
        </div>

    </div>
  )
}

export default Reviews
