import React from 'react'
import { Icons } from '../../../Icons/IconIndex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Banner6 = () => {
  return (
    <div className="Banner2 px-3 mt-5 mx-5">
    <div  >
        <div className='row pt-5' >
    <div className='col-sm-4 '>
        <div className='d-flex justify-content-start ms-5'>
    <h6 className='pt-1 Carousel-name'>EVENTS PORTAL </h6>
            <div className='mt-1 pt-1 ms-2'>
              <span className="squarecolor dot"></span>
              <span className="dot squarecolor_1"></span>
              <span className="dot squarecolor_2"></span>
            </div>
            </div>
            <div className='w-50 mt-4 ms-5'><p>Lorem ipsum dolor sit amet,
consec tetur adipiscing elit,
sed do eiusmod.</p></div>
    </div>
    <div className='col-sm-4'>

    <div className=' ms-5'>
    <h6 className='pt-1 '>Working Hours </h6>
       
            </div>
            <div className='w-50 mt-4 ms-5'>
                <p>Mon - Wed : 9 PM - 4 AM</p>
                <p>Thu - Fri : 9 PM - 4 AM</p>
                </div>
    </div>
    <div className='col-sm-4'>
    <div className='ms-5 ps-3'>
    <h6 className='pt-1 '>Contact Us </h6>

    <div className='d-flex justify-content-start mt-4'>
    <FontAwesomeIcon icon={Icons.faLocationDot} className='mt-1' style={{color:"#836D5D" }}/>
        <p className='ms-2'>#2 Kormangala BTM 2nd stage</p></div>
        <div className='d-flex justify-content-start mt-2'>
    <FontAwesomeIcon icon={Icons.faPhone} className='mt-1' style={{color:"#836D5D" }}/>
        <p className='ms-2'>(+1) 9239999999</p></div>
        <div className='d-flex justify-content-start mt-2'>
    <FontAwesomeIcon icon={Icons.faMessage} className='mt-1' style={{color:"#836D5D" }}/>
        <p className='ms-2'>contact@eventss.com</p></div>
        
            </div>
      
    </div>

        </div>
        </div>
      </div>
  )
}

export default Banner6
