import Images from "../../../Images";
export const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 6,
      slidesToSlide: 2,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  
  export const productData = [
    {
      id: 1,
      imageurl:Images.carousel1 ,
      name: "Colorful sneakers",
    
    },
    {
      id: 2,
      imageurl:Images.carousel2,
      name: "Sport sneakers",
     
    },
    {
      id: 3,
      imageurl:Images.carousel3,
      name: "iWatch",
    
    },
    {
      id: 4,
      imageurl:Images.carousel4,
      name: "Water Bottle",
    
    },
    {
      id: 5,
      imageurl:Images.carousel5,
      name: "Vans sneakers",
   
    },
    {
      id: 6,
      imageurl:
      Images.carousel6,
            name: "Coco Noir",
  
    },
    {
      id: 7,
      imageurl:
        "https://images.unsplash.com/photo-1589782182703-2aaa69037b5b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OTJ8fHByb2R1Y3RzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      name: "Sunglasses",
     
    },
    {
      id: 8,
      imageurl:
        "https://images.unsplash.com/photo-1625772452859-1c03d5bf1137?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fHByb2R1Y3RzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      name: "Dove cream",
    
    },
  ];
  