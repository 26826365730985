import React, { useContext, useEffect, useState } from 'react'
import Aside from '../../Layout/Aside'
import Navbar from '../../Layout/Navbar'
import Footer from '../../Layout/Footer'
import { MainContext } from '../../Store/Context'
import Axios from 'axios'
import { apiURL } from '../../Layout/Common/confiqurl'
import { Toaster, toast } from 'react-hot-toast'
import "./style.css"
import { faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const Enquiry = () => {
  const { user } = useContext(MainContext)
  const [isToastOpen, setIsToastOpen] = useState(false);

  const [state, setState] = useState([])
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  console.log('dd', currentPage);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  useEffect(() => {
    getData()
}, []);

const [data,setData]=useState()
const [datas,setDatas]=useState()
// console.log(datas,"nhnjmjj");

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}


const getData = () => {

  const project=JSON.parse(sessionStorage.getItem('data'));


      Axios.get(`${apiURL}/GetEnquiyList?user_id=${project.user_id}`,   {
        headers: {
            'Authorization': `Bearer ${project.access_token}`,

            "accept": "application/json",
            "Content-Type": " application/json",
        }
    
    }).then((response) => {
      console.log(response.data, "jjjjjjjjjjj");

      // Format the created_at date for each item in the response data
      const formattedData = response.data.map(item => ({
        ...item,
        created_at: formatDate(item.created_at)
      }));
      setState(formattedData);
    })
        .catch((err) => {
            {
                // setIsLoading(false);
                console.log(err)
            }
        });
}

const handleEdit=(value,id)=>{
  const project=JSON.parse(sessionStorage.getItem('data'));
console.log(id,"kkkk");
  const payload={
    active_status:value,
  }
  
  if (!isToastOpen) {
    setIsToastOpen(true);
   
  
  Axios.put(`${apiURL}/active_status?id=${id}`,payload, {
      headers: {
        'Authorization': `Bearer ${project.access_token}`,

          "accept": "application/json",
          "Content-Type": " application/json"
      }
  },
  ).then((response) => {
      
      toast.success(' Successfully Updated') 
      
      
  })
      .catch((err) => {
          {
              toast.error("Invalid User")
              // setIsLoading(false);
          }
      });
      setTimeout(() => {
        setIsToastOpen(false);
      }, 3000); 
    }
}

const handleToggleStatus = (id) => {
  console.log(id);
  const updatedData = state.map((row) => {
    if (row.id === id) {
      setData(!row.active_status)
      handleEdit(!row.active_status,id)

      return { ...row, active_status: !row.active_status };
    }
    return row;
  });
  setState(updatedData);
 
};


const filteredData = state.filter((item) => {
  const itemValues = Object.values(item).join('').toLowerCase();
  return itemValues.includes(searchQuery.toLowerCase());
});


const handleClick = (page) => {
  setCurrentPage(page);

};



const pages = Math.ceil(filteredData.length / itemsPerPage);
const startPage = Math.max(1, currentPage - 2);
const endPage = Math.min(pages, startPage + 4);

const startIndex = (currentPage - 1) * itemsPerPage;
const endIndex = Math.min(startIndex + itemsPerPage - 1, filteredData.length - 1);

const displayedItems = filteredData.slice(startIndex, endIndex + 1);
console.log(startIndex,"m",endIndex)
const paginationButtons = [];
for (let i = startPage; i <= endPage; i++) {
  paginationButtons.push(
      <li key={i} className={i === currentPage?"active":""}>
      <a
          key={i}
          className='active'
          onClick={() => handleClick(i)}
         
          style={{cursor:"pointer"}}
      >
          {i}
      </a>
      </li>
  );
}

  return (
    <div className={user ? "g-sidenav-show bg-gray-100 g-sidenav-hidden " : "g-sidenav-show  bg-gray-100"} >
            <Toaster toastOptions={{ position: "top-right" }} />

      <Aside />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

        <Navbar Name='Enquiry' />

        <div className="container-fluid py-4" style={{ minHeight: "560px" }}>
          <div className="row mt-4">
            <div className="col-12">
              <div className="card">

                <div className="card-header">
                  <h5 className="mb-0"></h5>
                  <p className="text-sm mb-0">
                    {/* A lightweight, extendable, dependency-free javascript HTML table plugin. */}
                  </p>
                </div>
                <div className="table-responsive">
                  <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                    <div className="dataTable-top">
                      <div className="dataTable-dropdown">
                        <label style={{ width: "300px" }}>
                          <select className="dataTable-selector w-50 me-2"  onClick={(e) => setItemsPerPage(e.target.value)}style={{cursor:"pointer"}}>
                            <option value="5" style={{cursor:"pointer"}}>5</option>
                            <option value="10 " style={{cursor:"pointer"}}>10</option>
                            <option value="15" style={{cursor:"pointer"}}>15</option>
                            <option value="20" style={{cursor:"pointer"}}>20</option>
                            <option value="25" style={{cursor:"pointer"}}>25</option>
                          </select>
                          entries per page</label>
                      </div>
                      <div className="dataTable-search">
                      <input className="dataTable-input" placeholder="Search..." type="text" value={searchQuery} onChange={(event) => setSearchQuery(event.target.value)} />
                      </div>
                    </div>
                    <div className="dataTable-container table-responsive w-100" >
                      <table className="table  " id="datatable-search">
                        <thead className="thead-light">
                          <tr><th data-sortable="" >
                            <a  className="" style={{fontSize:"10px"}}>Place Name</a>
                          </th>
                            <th data-sortable="" >
                              <a  className="r" style={{fontSize:"10px"}}>event Type</a>

                            </th>
                            <th data-sortable="mx-5" >
                              <a  className="" style={{fontSize:"10px"}}>enquired date</a>
                              
                            </th>
                            <th data-sortable="" >
                              <a  className="r" style={{fontSize:"10px"}}> Start Date</a>
                            </th>
                            <th data-sortable="" >
                              <a  className="" style={{fontSize:"10px"}}>End Date </a>
                            </th>
                            <th data-sortable="" >
                              <a  className="" style={{fontSize:"10px"}}> Start Time </a>
                            </th>
                            <th data-sortable="" >
                              <a  className="" style={{fontSize:"10px"}}> End Time</a>
                            </th>
                            <th data-sortable="" >
                              <a  className="" style={{fontSize:"10px"}}> Contact Number</a>
                            </th>
                            <th data-sortable="" >
                              <a  className="" style={{fontSize:"10px"}}> Status</a>
                            </th>
                            <th data-sortable="" >
                              <a  className="2" style={{fontSize:"10px"}}> Actions</a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>

                        {displayedItems && displayedItems.map((item, index) => {

 

 

    return (<tr className=''>
    <td className="text-sm font-weight-normal " style={{fontSize:"10px"}}>{item.place_name}</td>
    <td className="text-sm font-weight-normal" style={{ whiteSpace: 'normal' }} >{item.event_type}</td>

    <td className="text-sm font-weight-normal" style={{ whiteSpace: 'normal' }} >{item.created_at}</td>
    <td className="text-sm font-weight-normal" style={{fontSize:"10px"}}>{item.start_date}</td>
    <td className="text-sm font-weight-normal" style={{fontSize:"10px"}}>{item.end_date}</td>
    <td className="text-sm font-weight-normal" style={{fontSize:"10px"}}>{item.start_time}</td>
    <td className="text-sm font-weight-normal" style={{fontSize:"10px"}}>{item.end_time}</td>
    <td className="text-sm font-weight-normal" style={{fontSize:"10px"}}>{item.contact_number}</td>
    <td className="text-sm font-weight-normal" style={{fontSize:"10px"}}>
    {item.active_status?(<span className="badge badge-success badge-sm"> Confirmed</span>):(<span className="badge badge-danger badge-sm">Not Confirmed</span>)}
  {/* {item.active_status} */}
    </td>

    <td className="text-sm font-weight-normal">    <div className="form-check form-switch pt-1 ms-3"
    
  
    >


      <input className="form-check-input" type="checkbox" id="rememberMe" checked={item.active_status}  onClick={()=>handleToggleStatus(item.id)} />
    </div></td>


  </tr>)
}
)}
                          
                      



                        </tbody>
                      </table>
                      
                      </div>
                      <div className="dataTable-bottom">
                                        <div className="dataTable-info">Showing {startIndex + 1} to  {currentPage === displayedItems.length ? filteredData.length : endIndex+1}   of {filteredData.length} entries</div>
                                        <nav className="dataTable-pagination">



                                            <ul className="dataTable-pagination-list">
                                                <li className="pager" style={{cursor:"pointer"}}>

                                                    <a
                                                        onClick={() => handleClick(currentPage - 1)}
                                                        className={currentPage === 1 ? "button-disable" : ""}


                                                    > ‹ </a>
                                                </li>

                                              {paginationButtons}


                                                <li className="pager" style={{cursor:"pointer"}}><a data-page="2"
                                                    onClick={() => handleClick(currentPage + 1)}

                                                    className={currentPage === pages ? "button-disable" : ""}
                                                >›</a></li>

                                            </ul></nav>
                                
                                    </div>
                      </div>
                </div>
              </div>
            </div>
          </div>














        </div>
        <Footer />
      </main>

    </div>
  )
}

export default Enquiry
