import {
    faSliders,
    faUser,
    faGaugeHigh,
    faListCheck,
    faAsterisk,
    faToggleOff,
    faToggleOn,
    faArrowCircleRight,
    faMagnifyingGlass,
    faGear,
    faBook,
    faPen,
    faTrash,
    faPlusCircle,
    faImage,
    faVideo,
    faOutdent,
    faLock,
    faPhone,
    faPerson,
    faMessage,
    faFile,
    faTrashAlt,
    faFileImage,
    faSquareCaretRight,
    faFilePen,
    faHouseLaptop,
    faFileCirclePlus,
    faGlobe,
    faBoxOpen,
    faIdBadge,
    faLocation,
    faBell,
    faBirthdayCake,
    faBusinessTime,
    faCakeCandles,
    faRing ,
    faStamp,
    faBox,
    faBullhorn,
    faEllipsisVertical,
    faEye,
    faEdit,
    faRemove,
   faEyeSlash ,
   faLocationDot, 
    faRightFromBracket ,
    faBars,
    faArrowLeft,
    faChevronLeft,
    faChevronRight
    

} from '@fortawesome/free-solid-svg-icons';

import {
    faAddressBook,
    faNoteSticky,
    faRectangleList,
    faLifeRing,
    
    


} from '@fortawesome/free-regular-svg-icons';
import {
    
} from "@fortawesome/fontawesome-svg-core";



export const Icons = {
    faBell,
    faSliders,
    faEdit,
    faEye,
    faBirthdayCake,
    faCakeCandles,
    faRing ,
    faBusinessTime,
    faLifeRing,
    faSquareCaretRight,
    faUser,
    faAddressBook,
    faGaugeHigh,
    faListCheck,
    faAsterisk,
    faToggleOff,
    faToggleOn,
    faArrowCircleRight,
    faMessage, faNoteSticky,
    faMagnifyingGlass,
    faRectangleList,
    faGear,
    faBook,
    faPen,
    faTrash,
    faPlusCircle,
    faImage,
    faVideo,
    faOutdent,
    faLock,
    faPerson,
    faPhone,
    faTrashAlt, faFile,
    faFileImage,
    faFilePen,
    faHouseLaptop,
    faFileCirclePlus,
    faGlobe,
    faBoxOpen,
    faIdBadge,
    faLocation,
    faCakeCandles,
    faStamp,
    faBox,
    faBullhorn,
    faEllipsisVertical,
    faRemove,
    faEyeSlash ,
    faLocationDot,   
    faRightFromBracket,
    faBars,
    faArrowLeft,
    faChevronLeft,
    faChevronRight

    

}
/* <FontAwesomeIcon icon="fa-duotone fa-sliders-simple" /> */