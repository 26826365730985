import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { apiURL } from '../../../../Layout/Common/confiqurl';

const FoodService = (props) => {
  const { data, handleChange, next, back, nonveg_cooking, setnonveg_cooking, nonveg_service_allowed, setnonveg_service_allowed, setoutdoor_catering_allowed, outdoor_catering_allowed } = props;
  const [isToastOpen, setIsToastOpen] = useState(false); 
  const [state, setState] = useState([])


  useEffect(() => {
    getData()
  }, []);
  const getData = () => {
    const token = JSON.parse(sessionStorage.getItem('data'));
    axios.get(`${apiURL}/get_foodType`, {
      headers: {
        'Authorization': `Bearer ${token.access_token}`,
        "accept": "application/json",
        "Content-Type": " application/json",
      }
    },)
      .then((response) => {
        setState(response.data)
      })
  }

  const nextData = () => {
    if (data.foodtype.trim()) {
      next();
    } else {
      if (!isToastOpen) {
        setIsToastOpen(true);
        toast.error('Select Food Type')
        setTimeout(() => {
          setIsToastOpen(false);
        }, 3000);
      }
    }
  }
  return (
    <div class="row">
      <div class="col-12 col-lg-8 mx-auto  w-100">
        <div class="card w-100">
          <div class="card-body">
            <h5 class="font-weight-bolder">Food & Service</h5>
            <div className='row'>
              <div className='col-6' >
                <label>Food Type<span style={{ color: 'red' }}>*</span></label>
                <select id="inputState" class="form-select " name='foodtype' value={data.foodtype} onChange={handleChange} style={{ cursor: "pointer" }} >
                  <option value="" disabled selected hidden>
                    Select an option
                  </option>
                  {
                    state.map((data) => {
                      return (
                        <option > {data.data_value}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className='col-6'>
                <div className=' d-flex justify-content-start mt-4 pt-2  ' >
                  <div className='ms-3'>
                    <label for="rememberMe">Outdoor Catering Allowed</label>
                  </div>
                  <div className='ms-4'>
                    <div class="form-check form-switch pt-1 ms-3">
                      <input class="form-check-input" type="checkbox" id="rememberMe" name='outdoor_catering_allowed' checked={outdoor_catering_allowed} placeholder='enter description' onClick={() => setoutdoor_catering_allowed(!outdoor_catering_allowed)} />
                    </div>
                  </div>
                </div>
                <div className=' d-flex justify-content-start mt-4 pt-2  ' >
                  <div className='ms-3'>
                    <label for="rememberMe">Non-veg Cooking Allowed</label>
                  </div>
                  <div className='ms-4'>
                    <div class="form-check form-switch pt-1 ms-3">
                      <input class="form-check-input" type="checkbox" id="rememberMe" checked={nonveg_cooking} name='nonveg_cooking' placeholder='enter description' onClick={() => setnonveg_cooking(!nonveg_cooking)} />
                    </div>
                  </div>
                </div>
                <div className=' d-flex justify-content-start mt-4 pt-2  ' >
                  <div className='ms-3'>
                    <label for="rememberMe">Non-veg Service Allowed</label>
                  </div>
                  <div className='ms-4'>
                    <div class="form-check form-switch pt-1 ms-4">
                      <input class="form-check-input" type="checkbox" id="rememberMe" checked={nonveg_service_allowed} name='nonveg_service_allowed' placeholder='enter description' onClick={() => setnonveg_service_allowed(!nonveg_service_allowed && "Yes")} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-5'>
              <div className="col-6 ">
                <button
                  onClick={back}
                  className="btn bg-gradient-secondary mb-0 js-btn-prev"
                >
                  back
                </button>
              </div>
              <div className="col-6 ">
                <button
                  onClick={nextData}
                  className="btn bg-gradient-dark ms-auto mb-0 js-btn-next  d-flex justify-content-end"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FoodService
