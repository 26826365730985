import React from 'react'
import Banner from './Main/Banner'
import Banner2 from './Main/Banner2'
import Header from './Main/Header'
import Banner3 from './Main/Banner3'
import Banner4 from './Main/Banner4'
import Banner5 from './Main/Banner5'
import Banner6 from './Main/Banner6'


const LandingPage = () => {


  
  return (
    <div>
        <Header />
        <Banner />
        <Banner2 />
        <Banner3 />
        <Banner4 />
        <Banner5 />
        <Banner6 />
   

    </div>
  )
}

export default LandingPage
