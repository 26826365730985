import React from 'react'
import Images from '../../../Images'
import { cardData } from '../BannnerData.js/cardData'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { apiURL } from '../../../Layout/Common/confiqurl'
import { toast } from 'react-hot-toast'
const Banner3 = () => {
  const navigate=useNavigate()

const handleClick =(e)=>{
  const value={
    Location:e.target.value
  }
  const project = JSON.parse(sessionStorage.getItem('data'));


    // console.log(token.access_token,"jhhhh")

    const options = {
      
      Area:value.Location
    }


    
                            axios.post(`${apiURL}/Homepage_details`, options, {
                                headers: {
                                    "accept": "application/json",
                                    "Content-Type": " application/json",
                                    'Authorization': `Bearer ${project.access_token}`,

                                }
                            },
                            ).then((response) => {
                                console.log('loginsuccess', response);
                           
                                navigate("/Second",{state:{value1:response.data,value2:value}})

                                
                            })
                                .catch((err) => {
                                    {
                                        toast.error(err.response.data.detail)
                                        // setIsLoading(false);
                                    }
                                });
                   
              



}

  return (
    <div className="Banner2 mx-5 px-3" >
      <h3 className='my-4 text-center  Carousel-name '>Explore Venues in all the major Indian Cities</h3>
    <div className=' row'>
        {
            cardData.slice(0,6).map((item) => {
                return(
                    <div className='col-lg-4 col-sm-6  my-2'>
                    <div className="card px-4 pt-4">
                      <img className="product--image" src={item.imageurl} height={200}  alt="product image" />
                      <h4 className='Carousel-name pt-3'>{item.name}</h4>
                      <p className="price" style={{fontSize:"18px"}}>Top 10 Kalyana mandapapams in Mysore Kalyana mandapapams Mini Halls Party Halls Resorts Hotels</p>
                      <p>
                      <button  type="button"  class="btn px-5 mt-4 text-white" value={item.name} style={{backgroundColor:" #836D5D"}}  onClick={(e)=>handleClick(e)} >check Options</button>
                      </p>
                    </div>
                    </div>
                )})
        }
      
    </div>
    
    </div>
  )
}

export default Banner3
