import React, { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Axios from 'axios';
import { apiURL } from '../../../Layout/Common/confiqurl';

import { maxLength, numberMethod, validPasswordRegex, validEmailRegex, validPhoneNumberRegex } from '../../../Layout/Common/common';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icons } from '../../../Icons/IconIndex';
import Images from '../../../Images';
const Enquiry = () => {
    const navigate = useNavigate()



    const [state, setState] = useState({

        password: '',
        email: '',
        conformPassword: '',

    })


    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
    };

    console.log(state);

    return (
        <div>
            <div class="container">
                <div class="row mt-5">
                    <div class="col-lg-6 ">
                        <div class="">
                            <img src={Images.galleryimage14} alt='' className='w-100 mb-5' />

                        </div>
                    </div>
                    <div class="col-lg-6 ps-4 ">
                        <div class="card card-plain " style={{ width: "85%" }}>
                            <div class=" pb-0 text-left ms-4">
                                <h4 class="font-weight-bolder">Event Date & Sessions</h4>
                            </div>
                            <div class="card-body mt-5">
                                <form role="form">
                             

                                    <label className='' >Name</label>
                                    <div class="">
                                        <input type="text" class="form-control form-control-lg" placeholder="username" aria-label="username" name='username' value={"k"} onChange={handleChange} />
                                    </div>
                                    <label className='mt-3'>Email</label>
                                    <div class="">
                                        <input type="email" class="form-control" placeholder="Email" aria-label="Email" name='email' value={state.email} onChange={handleChange} />
                                    </div>
                                    <label className='mt-3'>Mobile Number</label>
                                    <div class="">
                                        <input
                                            class="form-control"
                                            placeholder='  mobile number'

                                            type="text"
                                            id="input"
                                            maxLength={10} // Set the maximum length of input to 10
                                            pattern="[0-9]*" // Only allow numbers
                                            value={"l"}
                                            onChange={handleChange}
                                        />                                        </div>
       <div className='row mt-3'>
                                        <div className='col-6' >
                                            <label> From Date</label>
                                            <input class="form-control" type="Date" placeholder='Enter  From Date ' name='select_from_date' onChange={handleChange} onFocus={(e) => e.target.showPicker()}/>
                                        </div>
                                        <div className='col-6' >
                                            <label> To Date</label>

                                            <input class="form-control" type="Date" placeholder='Enter To Date' name='select_to_date' onChange={handleChange} onFocus={(e) => e.target.showPicker()}/>


                                        </div>

                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-6' >
                                            <label> Start Time</label>
                                            <input class="form-control" type="time" placeholder='Enter Start Time' name='select_start_time' value={state.select_start_time} onFocus={(e) => e.target.showPicker()} onChange={handleChange} />
                                        </div>
                                        <div className='col-6' >
                                            <label> End Time</label>

                                            <input class="form-control" type="time" placeholder='Enter End Time' name='select_end_time' value={state.select_end_time} onFocus={(e) => e.target.showPicker()} onChange={handleChange} />


                                        </div>

                                    </div>
                                    <div class="text-center mt-4">
                                        <button type="button" class="btn text-white  w-100 mt-4 mb-0" style={{ backgroundColor: "#836D5D" }} >Submit</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Enquiry
