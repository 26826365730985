

import React, { useContext, useEffect, useRef, useState } from 'react'
import Aside from '../../../Layout/Aside'
import Navbar from '../../../Layout/Navbar'
import Footer from '../../../Layout/Footer'
import { MainContext } from '../../../Store/Context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Icons } from '../../../Icons/IconIndex'
import Axios from 'axios'
import { apiURL } from '../../../Layout/Common/confiqurl'
import { Toaster, toast } from 'react-hot-toast'
import { maxLength, numberMethod, validPasswordRegex, validEmailRegex, validPhoneNumberRegex } from '../../../Layout/Common/common';
import Images from '../../../Images'
import { useNavigate } from 'react-router-dom'; 
import avathar from "../../../Images/avathar.jpg";

const Setting = () => {
    const { user } = useContext(MainContext)
    const [icons, setIcons] = useState(true)
    const [icons1, setIcons1] = useState(true)
    const navigate = useNavigate()

    const [state, setState] = useState({
        profile_image: "", user_name: "",
        email_address: "",
        address: "",
        contact_number: "",
        password: "",
        confirmPassword: ""
    })
    const [isToastOpen, setIsToastOpen] = useState(false);

    const [showPopup, setShowPopup] = useState(false);

    const [imagePreview, setImagePreview] = useState(state.profile_image);
    const fileInputRef = useRef(null);

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setState(reader.result);
                setState({
                    ...state,
                    profile_image: reader.result,
                });
            };
            reader.readAsDataURL(file);
        } else {
            setImagePreview(null);
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    useEffect(() => {
        getData()
    }, []);
    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });

    };
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
        const value = event.target.value;
        if (/^\d{0,10}$/.test(value)) { // Limit input to 10 numbers
            setInputValue(value);
        }
    };
    const [usernamevalue, setUsernamevalue] = useState('');

    const handleInputNameChange = (event) => {
        const capitalizedValue = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        setUsernamevalue(capitalizedValue);
    }
    const getData = () => {

        const project = JSON.parse(sessionStorage.getItem('data'));


        Axios.get(`${apiURL}/user_details?user_id=${project.user_id}`, {
            headers: {
                'Authorization': `Bearer ${project.access_token}`,

                "accept": "application/json",
                "Content-Type": " application/json",
            }

        }).then((response) => {

            setState(response.data)
            setUsernamevalue(response.data.user_name)
            setInputValue(response.data.contact_number)
        })
            .catch((err) => {
                {
                    console.log(err)
                }
            });
    }
    const handleEdit = () => {
        const date = new Date()
        const project = JSON.parse(sessionStorage.getItem('data'));
        const options = {
            new_password: state.password,
            confirm_password: state.confirmPassword,
        }
        if (!isToastOpen) {
            setIsToastOpen(true);
            if (state.password) {
                if (state.password.length >= 8 && validPasswordRegex.test(state.password)) {
                    if (state.confirmPassword === state.password) {
                        Axios.put(`${apiURL}/resetpassword?id=${project.user_id}`, options, {
                            headers: {
                                "accept": "application/json",
                                "Content-Type": " application/json",
                                'Authorization': `Bearer ${project.access_token}`,
                            }
                        },
                        ).then((response) => {
                            { toast.success(' Updated Successfully') }
                        })
                            .catch((err) => {
                                {
                                    toast.error("Invalid User")
                                }
                            });
                    } else {
                        toast.error('Please Enter Same New Password');
                    }
                } else {

                    toast.error('Please Enter Valid New Password');
                }
            } else {

                toast.error('Please Enter Valid New Password');
            }
            setTimeout(() => {
                setIsToastOpen(false);
            }, 3000);
        }
    }
    const handleInputFocus = () => {
        setShowPopup(true);
    };

    return (
        <div className={user ? "g-sidenav-show bg-gray-100 g-sidenav-hidden " : "g-sidenav-show  bg-gray-100"} >
            <Toaster toastOptions={{ position: "top-right" }} />
            <Aside />
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <Navbar Name='Settings' />
                <div className="container-fluid py-4" style={{ minHeight: "560px" }}>
                    <div className="row mb-5">
                        <div className="col-lg-2 mt-lg-0 mt-4">
                        </div>
                        <div className="col-lg-8 mt-lg-0 mt-4">
                            <>
                                <div className="card card-body" id="profile">
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-sm-auto col-4">
                                            <div className="avatar avatar-xl position-relative">
                                                <img src={avathar} alt="bruce" className="w-100 border-radius-lg shadow-sm" />
                                                {/* <img src="../../../Images/avathar.jpg" alt="bruce" className="w-100 border-radius-lg shadow-sm" /> */}
                                            </div>
                                        </div>
                                        <div className="col-sm-auto col-8 my-auto">
                                            <div className="h-100">
                                                <h5 className="mb-1 font-weight-bolder">
                                                    {usernamevalue}
                                                </h5>
                                                <p className="mb-0 font-weight-bold text-sm">
                                                    {state.user_role}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
                                        </div>
                                    </div>
                                </div>
                                <div className="card mt-4" id="basic-info">
                                    <div className="card-header">
                                        <h5>Basic Info</h5>
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className="row">
                                            <div className="col-12">
                                                <label className="form-label">Username</label>
                                                <div className="input-group">
                                                    <input id="firstName" name="user_name" className="form-control" value={usernamevalue} type="text" placeholder="Username" required="required" onChange={handleInputNameChange} disabled />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <label className="form-label ">Email</label>
                                                <div className="input-group">
                                                    <input id="email" name="email_address" className="form-control" type="email" placeholder="" value={state.email_address} onChange={handleChange} disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <label className="form-label mt-4">Mobile Number</label>
                                                <div className="input-group">
                                                    <input id="confirmation" name="contact_number" className="form-control" type="number" value={inputValue} placeholder="Mobile Number" onChange={handleInputChange} disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <label className="form-label mt-4">Address</label>
                                                <div className="input-group">
                                                    <textarea id="location" name="address" className="form-control" type="text" value={state.address} onChange={handleChange} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            <div className="card mt-4" id="password">
                                <div className="card-header">
                                    <h5>Change Password</h5>
                                </div>
                                <div className="card-body pt-0">
                                    <label className="form-label">New password</label>
                                    <div className="form-group">
                                        <input type={icons ? "password" : "text"} class="form-control" onFocus={handleInputFocus} placeholder=" New Password" aria-label="Password" name='password' value={state.password} onChange={handleChange} />
                                        <div className='d-flex justify-content-end me-3 pe-1'>
                                            <label class="input-icon">
                                                <i onClick={() => setIcons(!icons)} className="text-black" style={{ fontSize: "24px", cursor: "pointer" }}>
                                                    {
                                                        icons ? (<FontAwesomeIcon icon={Icons.faEye} />
                                                        ) : (<FontAwesomeIcon icon={Icons.faEyeSlash} />
                                                        )
                                                    }
                                                </i>
                                            </label>
                                            {showPopup && (
                                                <div className="popup">
                                                    <p style={{ fontSize: "13px" }}>A minimum 8 characters password contains a first letter should uppercase ,lowercase special characters and number are required</p>
                                                </div>
                                            )}
                                        </div>                                      </div>
                                    <label className="form-label">Confirm New Password</label>
                                    <div className="form-group">
                                        <input type={icons1 ? "password" : "text"} class="form-control" placeholder="Confirm New Password" aria-label="Password" name='confirmPassword' value={state.confirmPassword} onChange={handleChange} />
                                        <span class="input-group-text">
                                            <i onClick={() => setIcons1(!icons1)} className="text-black " style={{ fontSize: "24px", cursor: "pointer" }}>
                                                {
                                                    icons1 ? (<FontAwesomeIcon icon={Icons.faEye} />
                                                    ) : (<FontAwesomeIcon icon={Icons.faEyeSlash} />
                                                    )
                                                }
                                            </i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <button className="btn bg-gradient-white btn-sm  mt-6 mb-0" onClick={() => navigate('/CreateAdmin')}>Back</button>
                            <button className="btn bg-gradient-dark btn-sm float-end mt-6 mb-0" onClick={handleEdit}>save</button>
                        </div>
                        <div className="col-lg-2 mt-lg-0 mt-4">
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    )
}

export default Setting
