import image1 from './1.jpg';
import image2 from './2.jpg';
import image3 from './3.jpg';
import mini from './mini.jpg';
import party from './party.jpg';
import wedding from './wedding.jpg';
import  carousel2   from "./LandingImage/Rectangle 58.png"
import  carousel1   from "./LandingImage/Rectangle57.png"
import  carousel3   from "./LandingImage/Rectangle 59.png"
import  carousel4   from "./LandingImage/Rectangle 60.png"
import  carousel5   from "./LandingImage/Rectangle 61.png"
import  carousel6   from "./LandingImage/Rectangle 62.png"
import  cardimage1   from "./LandingImage/Rectangle 67.png"
import  cardimage2   from "./LandingImage/Rectangle 68.png"
import  cardimage3   from "./LandingImage/Rectangle 69.png"
import  cardimage4   from "./LandingImage/Rectangle 73.png"
import  cardimage5   from "./LandingImage/Rectangle 75.png"
import  cardimage6   from "./LandingImage/Rectangle 77.png"
import  cardimage7   from "./LandingImage/Rectangle 89.png"
import  cardimage8   from "./LandingImage/Rectangle 90.png"
import  cardimage9   from "./LandingImage/Rectangle 91.png"
import  cardimage10   from "./LandingImage/Rectangle 93.png"
import  cardimage11  from "./LandingImage/Rectangle 94.png"
import  cardimage12   from "./LandingImage/Rectangle 95.png"
import  profile   from "./LandingImage/Image Placeholder.png"
import  line16   from "./LandingImage/Line 16.png"
import  line15   from "./LandingImage/Line 15.png"
import  line17   from "./LandingImage/Line 17.png"
import  line18   from "./LandingImage/Line 18.png"
import cardimage13 from "./LandingImage/Second/Rectangle 77.png"
import cardimage14 from "./LandingImage/Second/Rectangle 109.png"
import cardimage24 from "./LandingImage/Second/Rectangle 110.png"
import cardimage15 from "./LandingImage/Second/Rectangle 111.png"
import cardimage16 from "./LandingImage/Second/Rectangle 112.png"
import cardimage17 from "./LandingImage/Second/Rectangle 113.png"
import cardimage18 from "./LandingImage/Second/Rectangle 114.png"
import cardimage19 from "./LandingImage/Second/Rectangle 115.png"
import cardimage20 from "./LandingImage/Second/Rectangle 116.png"
import cardimage21 from "./LandingImage/Second/Rectangle 117.png"
import cardimage22 from "./LandingImage/Second/Rectangle 118.png"
import cardimage23 from "./LandingImage/Second/Rectangle 119.png"
import galleryimage1 from "./LandingImage/Second/Rectangle 12887.png"
import galleryimage5 from "./LandingImage/Second/Rectangle 12888.png"
import galleryimage2 from "./LandingImage/Second/Rectangle 12890.png"
import galleryimage3 from "./LandingImage/Second/Rectangle 12891.png"
import galleryimage4 from "./LandingImage/Second/Rectangle 12892.png"
import galleryimage11 from "./LandingImage/Second/Rectangle 12886.png"
import galleryimage6 from "./LandingImage/Second/Rectangle 12885.png"
import galleryimage7 from "./LandingImage/Second/Rectangle 12884.png"
import galleryimage8 from "./LandingImage/Second/Rectangle 12895.png"
import galleryimage9 from "./LandingImage/Second/Rectangle 12896.png"
import galleryimage10 from "./LandingImage/Second/Rectangle 12894.png"
import galleryimage12 from "./LandingImage/Second/Rectangle 12897.png"
import galleryimage13 from "./LandingImage/Second/Rectangle 12893.png"
import galleryimage14 from "./LandingImage/Second/Rectangle 12899.png"

import Vector from "./LandingImage/Second/Vector.png"
import profile3 from "./LandingImage/Second/Ellipse 2.png"
import profile1 from "./LandingImage/Second/Ellipse 26.png"
import profile2 from "./LandingImage/Second/Ellipse3.png"
import madabam02 from "./LandingImage/Second/madabam02.png"
import mahall from "./LandingImage/Second/mahall.png"
import log from "../../src/Images/log.jpg"
import avathar from "../../src/Images/avathar.jpg";
import logobook from "../../src/Images/logobook.png";
 
import lognew from "./lognew.jpg"
import log1 from "./log1.jpg"

import boookmevneue_logo from "./bookmevenue_logo.png"

const Images = {
    image1,
    image2,
    image3,
    mini,
    party,
    wedding,carousel1,
    carousel2,
    carousel3,
    carousel4,
    carousel5,
    carousel6,
    cardimage1,
    cardimage2,
    cardimage3,
    cardimage4,
    cardimage5,
    cardimage6,
    cardimage7,
    cardimage8,
    cardimage9,
    cardimage10,
    cardimage11,
    cardimage12,
    cardimage13,
    cardimage14,
    cardimage15,
    cardimage16,
    cardimage17,
    cardimage18,
    cardimage19,
    cardimage20,
    cardimage21,
    cardimage22,
    cardimage23,
    cardimage24,
    galleryimage1,
    galleryimage2,
    galleryimage3,
    galleryimage4,
    galleryimage5,
    galleryimage6,
    galleryimage7,
    galleryimage8,
    galleryimage9,
    galleryimage10,
    galleryimage12,
galleryimage11,
galleryimage13,
galleryimage14,
madabam02,
    profile,
    profile1,
    profile2,
    profile3,

    line15,
    line16,
    line17,
    line18,
    Vector,
    log,
    avathar,

    boookmevneue_logo,
    logobook,
    mahall, 
    lognew,


}
export default Images;