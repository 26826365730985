import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  Axios  from 'axios';
import React, { useContext,useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { apiURL } from '../Common/confiqurl';
import { sideData, sideData1 } from './SideData';
import '../../../src/App.css'; 
import { MainContext } from '../../Store/Context';

const Aside = () => {
  const { user } = useContext(MainContext)
  const [state, setState] = useState()
  const [getvalue, setGetvalue] = useState(false)


  useEffect(() => {
    const project=JSON.parse(sessionStorage.getItem('data'));

    setState(project.user_role,"lklkkk");

    }, []);

console.log(state,"jjjjjjjjjj");



  return (
    <aside className="sidenav navbar navbar-vertical navbar-expand-xs  my-3 fixed-start ms-3  " id="sidenav-main" >
      <div className="sidenav-header">
        <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
        <a className="navbar-brand m-0"  target="_blank">
          
          <img src={user ?"../../assets/img/fav-icon.png":"../../assets/img/logo.jpg"} className="navbar-brand-img h-100 logo_img" alt="main_logo" />
          {/* <img src={user ?"../../assets/img/logo.jpg":"../../assets/img/fav-icon.png"} className="navbar-brand-img h-100 logo_img" alt="main_logo" /> */}
          {/* <span className="ms-1 font-weight-bold">Event-Portal</span> */}
        </a>
      </div>
      <hr className="horizontal dark mt-0" />
      <div className="collapse navbar-collapse  w-auto h-auto" id="sidenav-collapse-main">
        <ul className="navbar-nav">
            {
             state === 'admin' && 
              sideData1 && sideData1.map((item, key) => {
                return (
                  <li className="nav-item" >

                  <NavLink data-bs-toggle="" to={item.path}
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"}
                    aria-controls="dashboardsExamples" role="button" aria-expanded="false"   >
                    <div className="icon icon-shape icon-sm shadow border-radius-md  text-center d-flex align-items-center justify-content-center  me-2"  >
                      <FontAwesomeIcon icon={item.icon} />

                    </div>
                    <span className="nav-link-text ms-1">{item.name}</span>
                  </NavLink>
                  </li>

                )

              })
            }
             {
             
             state === 'Partner' && 
                sideData && sideData.map((item, key) => {
                  return (
                    <li className="nav-item" >

                    <NavLink data-bs-toggle="" to={item.path}
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"}
                      aria-controls="dashboardsExamples" role="button" aria-expanded="false"   >
                      <div className="icon icon-shape icon-sm shadow border-radius-md  text-center d-flex align-items-center justify-content-center  me-2"  >
                        <FontAwesomeIcon icon={item.icon} />

                      </div>
                      <span className="nav-link-text ms-1">{item.name}</span>
                    </NavLink>
                    </li>

                  )

                })
              }

        </ul>
      </div>
      {/* <div className="sidenav-footer mx-3 mt-3 pt-3">
        <div className="card card-background shadow-none card-background-mask-secondary" id="sidenavCard">
          <div className="full-background" style={{ backgroundImage: "url('../../assets/img/curved-images/white-curved.jpg')" }}></div>
          <div className="card-body text-start p-3 w-100">
            <div className="icon icon-shape icon-sm bg-white shadow text-center mb-3 d-flex align-items-center justify-content-center border-radius-md">
              <i className="ni ni-diamond text-dark text-gradient text-lg top-0" aria-hidden="true" id="sidenavCardIcon"></i>
            </div>
            <div className="docs-info">
              <h6 className="text-white up mb-0">Need help?</h6>
              <p className="text-xs font-weight-bold">Please check our docs</p>
              <a href="https://www.creative-tim.com/learning-lab/bootstrap/overview/soft-ui-dashboard" target="_blank" className="btn btn-white btn-sm w-100 mb-0">Documentation</a>
            </div>
          </div>
        </div>
      </div> */}
    </aside>
  )
}


export default Aside
