// import React, { useEffect, useState, useRef } from 'react'
// import toast, { Toaster } from 'react-hot-toast';
// import Axios from 'axios';
// import { Link, useNavigate } from "react-router-dom";
// import { apiURL } from '../../Layout/Common/confiqurl';
// import image from "../../Images/danger-chat.jpg"
// function LoginOtp() {
//     const navigate = useNavigate()
//     const inputRef = useRef(null);

//     const [userId, setuserId] = useState()

//     const [state, setState] = useState({

//         first: "",
//         second: "",
//         third: "",
//         forth: "",


//     })
//     useEffect(() => {
//         const project = JSON.parse(sessionStorage.getItem('data'));
//         setuserId(project.user_id)
//         const value = project.Enter_OTP
//         handleSplit(value)
//         inputRef.current.focus();
//     }, []);

//     const handleSplit = (data) => {
//         const [first, second, third, forth] = data.split("");
//         setState({ first, second, third, forth })

//     }
//     console.log(state, "kkkkkkkoooo");



//     const handleChange = (event) => {
//         setState({
//             ...state,
//             [event.target.name]: event.target.value,
//         });
//     };
//     const handleClick = (e) => {
//         const token = JSON.parse(sessionStorage.getItem('data'));
//         console.log(token.access_token, "jhhhh")
//         const datas = `${state.first}${state.second}${state.third}${state.forth}`

//         const options = {

//             Enter_OTP: datas,

//         }






//         Axios.post(`${apiURL}/verify_OTP?user_id=${userId}`, options, {
//             headers: {
//                 "accept": "application/json",
//                 "Content-Type": " application/json",
//                 'Authorization': `Bearer ${token.access_token}`,
//             }
//         },
//         ).then((response) => {
//             console.log('loginsuccess', token.user_role);
//             //    navigate("/ResetPassword")
//             {
//                 // toast.success('otp genarating') 
//                 { token.user_role == 'Super Admin' ? navigate("/CreateAdmin") : token.user_role = 'Partner' ? navigate("/dashboard") : token.user_role = 'admin' ? navigate("/dashboard") : "" }



//             }
//         })
//             .catch((err) => {
//                 {
//                     toast.error("Invalid User")
//                     // setIsLoading(false);
//                 }
//             });



//     }
//     const handleKeyDown = (event) => {
//         if (event.key === 'Enter') {
//             event.preventDefault();
//             handleClick();
//         }
//     }

//     return (
//         <div>
//             <main class="main-content mt-0 ps">
//                 <section>
//                     <div class="page-header min-vh-100">
//                         <div class="container">
//                             <div class="row">
//                                 <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
//                                     <div class="card card-plain">
//                                         <div class="card-body px-lg-5 py-lg-5 text-center">
//                                             <div class="text-center text-muted mb-4">
//                                                 <h2>OTP  Verification</h2>
//                                             </div>
//                                             <div class="row gx-2 gx-sm-3">
//                                                 <div class="col">
//                                                     <div class="form-group">
//                                                         <input type="text" class="form-control form-control-lg" maxlength="1" name='first' value={state.first} onChange={handleChange} />
//                                                     </div>
//                                                 </div>
//                                                 <div class="col">
//                                                     <div class="form-group">
//                                                         <input type="text" class="form-control form-control-lg" maxlength="1" name='second' value={state.second} onChange={handleChange} />
//                                                     </div>
//                                                 </div>
//                                                 <div class="col">
//                                                     <div class="form-group">
//                                                         <input type="text" class="form-control form-control-lg" maxlength="1" name='third' value={state.third} onChange={handleChange} />
//                                                     </div>
//                                                 </div>
//                                                 <div class="col">
//                                                     <div class="form-group">
//                                                         <input type="text" class="form-control form-control-lg" maxlength="1" name='forth' value={state.forth} ref={inputRef} onKeyDown={handleKeyDown} onChange={handleChange} />
//                                                     </div>
//                                                 </div>


//                                             </div>
//                                             <div class="text-center">
//                                                 <button type="button" class="btn bg-gradient-primary w-100" onClick={handleClick}>Submit</button>
//                                                 {/* <span class="text-muted text-sm">Haven't received it?<a href="javascript:;"> Resend a new code</a>.</span> */}
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
//                                     <div class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center">
//                                         {/* <img src="../../../assets/img/shapes/pattern-lines.svg" alt="pattern-lines" class="position-absolute opacity-4 start-0" /> */}
//                                         <div class="position-relative">
//                                             <img class="max-width-500 w-100 position-relative z-index-2" src={image} alt="chart-ill" />
//                                         </div>
//                                         <h4 class="mt-5 text-white font-weight-bolder">"Attention is the new currency"</h4>
//                                         <p class="text-white">The more effortless the writing looks, the more effort the writer actually put into the process.</p>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </section>
//                 <div class="ps__rail-x" style={{ left: "0px", bottom: "0px" }}><div class="ps__thumb-x" tabindex="0" style={{ left: "0px", width: "0px" }}></div></div><div class="ps__rail-y" style={{ top: "0px", right: "0px" }}><div class="ps__thumb-y" tabindex="0" style={{ top: "0px", height: "0px" }}></div></div></main>
//         </div>
//     )
// }


// export default LoginOtp