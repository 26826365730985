import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Product from "../BannnerData.js/Product";
import { productData, responsive } from "../BannnerData.js/data";
import "../Style.css"
const Banner2 = () => {
  const product = productData.map((item) => (
    <div className=" text-center mx-4" style={{height:"160px",width:"130px"}}>

    <img className="rounded-circle Carousel-image " src={item.imageurl}  height={130} alt="product image" />
    <p className="text-black mx-auto  Carousel-name">{item.name} </p>

  </div>
   
    // <Product
    //   name={item.name}
    //   url={item.imageurl}
  
    // />
  ));

  return (
    <div className="Banner2 px-3  mx-5">
      <h3 className='my-4 text-center Carousel-name  '>Events you can book for with us</h3>
      <div className='Carousel  card pt-5' style={{height:"250px"}}>
  <Carousel showDots={true} responsive={responsive}>
        {product}
      </Carousel>
      </div>
    
    </div>)
}

export default Banner2
