import React from "react";
import { Navigate, Outlet, Route } from "react-router-dom";

export default function PrivateRoute({path,element}){
    const token=sessionStorage.getItem('data');
    return(
        <>
        {token &&  token !='' ? <Outlet/>:<Navigate to={'/'}/>}
        </>
    )
}