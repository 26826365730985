import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Icons } from '../../../Icons/IconIndex';
import "../Style.css"

const data = [
  {
    place:"Chennai",
    part:"1"
  },
  {
    place:"Madurai",
    part:"1"
  },
 
 
]


const Header = () => {
  return (
    <div>
      <Navbar collapseOnSelect expand="lg" className='Header' variant="" >
        <Container>
          <Navbar.Brand href="#home" className='heading_color logo_design me-5'>     <div className='d-flex justify-content-end'>
            <div className='pt-1'>EVENTS PORTAL </div>
            <div className='mt-1 pt-1 ms-2'>
              <span className="squarecolor dot"></span>
              <span className="dot squarecolor_1"></span>
              <span className="dot squarecolor_2"></span>
            </div>

          </div></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto ">
              <i className="text-secondary mt-2 heading_color" aria-hidden="true" >
                <FontAwesomeIcon icon={Icons.faLocationDot} />
              </i>
              <NavDropdown title="Location"  id="collasible-nav-dropdown">
              <NavDropdown.Item href="" >

                <div className='row bg-white'>
                 
                  {data.map((value)=>{
                    return(
                      <div className=' my-2'>
                      <div className='d-flex justify-content-start '>
                        {/* <input type="checkbox"  className='ms-2'/> */}
                      <span className='heading_color ms-1'>{value.place}</span>
  
                      </div>
                    </div>
                    )
                  })}

                     <div className='d-flex justify-content-end me-5'>
                <button  type="button"  class="btn px-5 mt-4 text-white"  style={{backgroundColor:" #836D5D"}} >Apply</button>

                </div>

                </div>
                </NavDropdown.Item>


              </NavDropdown>
            </Nav>
            <Nav>
              <Nav.Link className='heading_color'>Home</Nav.Link>
              <Nav.Link eventKey={2} className='heading_color'>
                <i className="text-secondary mt-2 heading_color me-2" aria-hidden="true" >
                  <FontAwesomeIcon icon={Icons.faPhone} />
                </i>
                974647877
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default Header
