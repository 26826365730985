import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react'
import { Icons } from '../../Icons/IconIndex';
import { MainContext } from '../../Store/Context';
import { Link, useNavigate } from 'react-router-dom';
import { Nav, NavDropdown } from 'react-bootstrap';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import avathar from "../../Images/avathar.jpg";

import "./style.css"
const Navbar = (props) => {
  const { setUser, user } = useContext(MainContext)
  const [state, setState] = useState("")
  const [userrole, setuserrole] = useState("")

  const [active, setActive] = useState(false)
  const navigate = useNavigate()
  const handleLogout = () => {
    sessionStorage.clear()
    navigate("/")
  }

  // const handleBackButton = (event) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  // };
  useEffect(() => {

    const project = JSON.parse(sessionStorage.getItem('data'));

    setState(project.user_name);
    setuserrole(project.user_role)
    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener('popstate', handleBackButton);

    // return () => {
    //   window.removeEventListener('popstate', handleBackButton);
    // };


  }, []);

  return (
    <nav className="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky card" onClick={() => setActive(!active)} id="navbarBlur" data-scroll="true">
      <div className="container-fluid py-1 px-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 ">
            <li className="breadcrumb-item text-sm">
              <a className="opacity-3 text-dark" href="javascript:;">


              </a>
            </li>
            {/* <li className="breadcrumb-item text-sm"><a className="opacity-5 text-dark" onClick={()=>{navigate(props.path)}} >Home</a></li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">{props.Name}</li> */}
          </ol>
          {/* <h5 className="font-weight-bolder mb-0">{props.Name}</h5> */}
        </nav>
        <div className="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
          <a href="javascript:;" className="nav-link text-body p-0">
            <div className="sidenav-toggler-inner">

              <i onClick={() => setUser(!user)} className="text-black" style={{ fontSize: "24px" }}>
                {
                  user ? (<svg width="38" height="38" viewBox="0 0 19 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm.646 2.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L4.293 8 2.646 6.354a.5.5 0 0 1 0-.708zM7 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"></path>
                  </svg>
                  ) : (<svg width="38" height="38" viewBox="0 0 19 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg" transform="scale(-1, 1)" transform-origin="center">
                    <path d="M2 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm.646 2.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L4.293 8 2.646 6.354a.5.5 0 0 1 0-.708zM7 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"></path>
                  </svg>
                  )
                }
              </i>

            </div>
          </a>
        </div>
        <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
          <div className="ms-md-auto pe-md-3 d-flex align-items-center">

          </div>
          {/* <ul className="navbar-nav  justify-content-end">
            <li className="nav-item d-flex align-items-center" onClick={() => setActive(!active)}>
              <a href="javascript:;" className={active ? "nav-link text-body p-0 show" : "nav-link text-body p-0 "} id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="fa fa-user me-sm-1" ></i>
                <span className="d-sm-inline d-none">{state}</span>
              </a>
            </li>
            <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
              <a href="javascript:;" className="nav-link text-body p-0" id="iconNavbarSidenav">
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line"></i>
                  <i className="sidenav-toggler-line"></i>
                  <i className="sidenav-toggler-line"></i>
                </div>
              </a>
            </li>
      
            <li className="nav-item dropdown pe-2 px-3 d-flex align-items-center ">
              <a href="javascript:;" className={"nav-link text-body p-0"} id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              </a>
              <ul className={active ? "dropdown-menu dropdown-menu-end px-2 py-3 me-3  show" : "dropdown-menu dropdown-menu-end px-2 py-3 me-3  "} aria-labelledby="dropdownMenuButton" style={{ width: "260px" }}>
                <li className="mb-2" onClick={() => setActive(!active)}>
                  <a className="dropdown-item border-radius-md" href="javascript:;">
                    <div className="d-flex py-1">
                      <div className="my-auto"> 
                        <img src="../../assets/img/team-2.jpg" className="avatar avatar-sm  me-3 " alt="user image" />
                      </div>
                      <div className="d-flex flex-column justify-content-center">
                        <h6 className="text-sm font-weight-normal mb-1">
                          <span className="font-weight-bold">{state}</span>
                        </h6>
                        <p className="text-xs text-secondary mb-0">
                          <i className="fa fa-clock me-1"></i>
                          {userrole}
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mb-2" onClick={() => setActive(!active)}>
                  <a className="dropdown-item border-radius-md" href="javascript:;">
                    <div className="d-flex py-1">
                    <div className="icon icon-shape icon-sm shadow border-radius-md  text-center d-flex align-items-center justify-content-center  me-2"  >
                      <FontAwesomeIcon icon={Icons.faGear} />

                    </div>
                      <div className="d-flex flex-column justify-content-center">
                        <h6 className="text-sm font-weight-normal mb-1" onClick={()=>navigate("/Settings")}>
                          Settings
                        </h6>

                      </div>
                    </div>
                  </a>
                </li>
                <li onClick={() => setActive(!active)}>
                  <a className="dropdown-item border-radius-md" href="javascript:;">
                    <div className="d-flex py-1">
                    <div className="icon icon-shape icon-sm shadow border-radius-md  text-center d-flex align-items-center justify-content-center  me-2"  >
                      <FontAwesomeIcon icon={Icons.faGear} />

                    </div>
                      <div className="d-flex flex-column justify-content-center">
                        <h6 className="text-sm font-weight-normal mb-1" onClick={handleLogout}>
                          Logout
                        </h6>

                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </li>
          </ul> */}

          <Nav className="">

            <NavDropdown title={state} id="basic-nav-dropdown" className='' style={{ minWidth: '100px' }}>
              {/* Set the minWidth property to adjust the width of the dropdown menu */}
              <NavDropdown.Item href="" className='me-5' >
                <div className="d-flex py-1">
                  <div className="my-auto">
                    {/* <img src="../../assets/img/team-2.jpg" className="avatar avatar-sm  me-3 " alt="user image" /> */}
                    <img className="rounded-circle me-3" style={{ width: '50px', height: '50px' }} src={avathar} alt="Default" />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h6 className="text-sm font-weight-normal mb-1">
                      <span className="font-weight-bold">{state}</span>
                    </h6>
                    <p className="text-xs text-secondary mb-0">
                      <i className="fa fa-clock me-1"></i>
                      {userrole}
                    </p>
                  </div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item href="" >
                <div className="d-flex py-1" onClick={() => navigate("/Settings")}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md  text-center d-flex align-items-center justify-content-center  me-2"  >
                    <FontAwesomeIcon icon={Icons.faGear} />

                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h6 className="text-sm font-weight-normal mb-1" >
                      Settings
                    </h6>

                  </div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item href="" >
                <div className="d-flex py-1" onClick={handleLogout}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md  text-center d-flex align-items-center justify-content-center  me-2"  >
                    <FontAwesomeIcon icon={Icons.faRightFromBracket} />

                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h6 className="text-sm font-weight-normal mb-1">
                      Logout
                    </h6>

                  </div>
                </div>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>

        </div>
      </div>
    </nav>
  )
}

export default Navbar
