import React from 'react'
import Header from '../Main/Header'
import Photo from './Photo'

const Gallery = () => {
  return (
    <div>
      <Header />
      <Photo />
    </div>
  )
}

export default Gallery
