import React, { useContext } from 'react'
import Aside from '../../Layout/Aside'
import Navbar from '../../Layout/Navbar'
import Footer from '../../Layout/Footer'
import { MainContext } from '../../Store/Context'

const RequestLocation = () => {
  const { user } = useContext(MainContext)

  return (
    <div className={user ? "g-sidenav-show bg-gray-100 g-sidenav-hidden " : "g-sidenav-show  bg-gray-100"} >


    <Aside />







    <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

   <Navbar Name='RequestLocation' />

    <div className="container-fluid py-4" style={{ minHeight: "560px" }}>
      <div className="row">
      RequestLocation
      </div>



    </div>
  <Footer />
  </main>

  </div>
  )
}

export default RequestLocation
