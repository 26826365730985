import React, { useState, useEffect } from 'react';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import Axios from 'axios';  
import { apiURL } from '../../Layout/Common/confiqurl';

export default function ExcelExport() {
    const [foodType, setFoodtype] = useState([]);
    const [venueType, setVenuetype] = useState([]);

    useEffect(() => {
        getData();
        getData1();
    }, []);

    const getData = () => {
        const token = JSON.parse(sessionStorage.getItem('data'));
        Axios.get(`${apiURL}/get_foodType`, {
            headers: {
                'Authorization': `Bearer ${token.access_token}`,
                "accept": "application/json",
                "Content-Type": "application/json",
            }
        })
        .then((response) => { 
            const foodTypeOptions = response.data.map(item => item.data_value);
            setFoodtype(foodTypeOptions);
        })
        .catch((error) => {
            console.error("Error fetching food types:", error);
            setFoodtype([]);
        });
    };

    const getData1 = () => {
        const token = JSON.parse(sessionStorage.getItem('data'));
        Axios.get(`${apiURL}/get_Venue_Type`, {
            headers: {
                'Authorization': `Bearer ${token.access_token}`,
                "accept": "application/json",
                "Content-Type": "application/json",
            }
        })
        .then((response) => { 
            const venueTypeOptions = response.data.map(item => item.data_value);
            setVenuetype(venueTypeOptions);
        })
        .catch((error) => {
            console.error("Error fetching venue types:", error);
            setVenuetype([]);
        });
    };

    async function saveAsExcel() {
        const workbook = new ExcelJS.Workbook();
        const ws = workbook.addWorksheet('Worksheet');

        const headers = [
            "Mandapam Name", "Mandapam Link", "Address", "Hall Seating Capacity",
            "Hall Floating Capacity", "Food Type", "Outside Decoration Allowed",
            "Outside Entertainment Allowed", "About", "Valet Parking",
            "Outdoor Catering Allowed", "Non-Veg Cooking", "Non-Veg Service Allowed",
            "Non AC Rooms", "AC Rooms", "Full Hall AC", "Starting Price Per Day",
            "Car Parking", "Bike Parking", "Car Parking Count", "Bike Parking Count",
            "Place Type", "Mobile Number", "Contact Name", "User Id", "Created At",
            "Created By", "Updated At", "Updated By"
        ];

        ws.addRow(headers);

        ws.columns.forEach((col) => {
            col.width = 25;
        });

        if (foodType.length > 0) {
            const foodTypeOptions = foodType.join(',');
            ws.dataValidations.add('F2:F99999', {
                type: 'list',
                allowBlank: false,
                formulae: [`"${foodTypeOptions}"`],
            });
        }

        if (venueType.length > 0) {
            const venueTypeOptions = venueType.join(',');
            ws.dataValidations.add('V2:V99999', { 
                type: 'list',
                allowBlank: false,
                formulae: [`"${venueTypeOptions}"`],
            });
        }

        const dropdownOptions = '"Yes,No"';
        const dropdownFields = [
            "Outside Decoration Allowed", "Outside Entertainment Allowed", 
            "Valet Parking", "Outdoor Catering Allowed", "Non-Veg Service Allowed", 
            "Full Hall AC", "Car Parking", "Bike Parking"
        ];

        dropdownFields.forEach(field => {
            const colIndex = headers.indexOf(field) + 1;
            if (colIndex > 0) {
                ws.dataValidations.add(`${String.fromCharCode(64 + colIndex)}2:${String.fromCharCode(64 + colIndex)}99999`, {
                    type: 'list',
                    allowBlank: false,
                    formulae: [dropdownOptions],
                });
            }
        });

        // Add integer validation for specific columns
        const integerFields = ["Hall Seating Capacity", "Hall Floating Capacity"];
        integerFields.forEach(field => {
            const colIndex = headers.indexOf(field) + 1;
            if (colIndex > 0) {
                ws.dataValidations.add(`${String.fromCharCode(64 + colIndex)}2:${String.fromCharCode(64 + colIndex)}99999`, {
                    type: 'whole',
                    operator: 'between',
                    formula1: 0, // Minimum value
                    formula2: 1000000, // Maximum value, adjust as needed
                });
            }
        });

        const headerRow = ws.getRow(1);
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFADD8E6' },
            };
            cell.font = {
                name: 'Inter',
                size: 8,
                bold: true,
            };
            cell.alignment = {
                horizontal: 'center',
            };
        });

        ws.eachRow((row) => {
            row.eachCell((cell) => {
                cell.font = {
                    name: 'Inter',
                    size: 8,
                };
                cell.alignment = {
                    horizontal: 'center',
                };
            });
        });

        const excelBlob = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), 'Master Data.xlsx');
    }

    return (
        <button type="button" onClick={saveAsExcel} className="btn btn-outline-primary mx-2 btn-sm mb-0">
            Download Template
        </button>
    );
}
