import React, { useContext, useEffect, useState } from 'react'
import Aside from '../../Layout/Aside'
import Navbar from '../../Layout/Navbar'
import Footer from '../../Layout/Footer'
import { MainContext } from '../../Store/Context'
import Chart from 'react-apexcharts'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import "./Style.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Icons } from '../../Icons/IconIndex'
import events from '../ManageBooking/Calendar'
import { CircleSlider } from 'react-circle-slider';
import Axios from 'axios'
import { apiURL } from '../../Layout/Common/confiqurl';
import interactionPlugin from '@fullcalendar/interaction';
import { redirect } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const [state, setState] = useState("Kitchen")
    const [iconValue, setIconValue] = useState(false)
    const [count, setCount] = useState("")
    const [partnercount, setpartnercount] = useState('')
    const [userRole, setuserRole] = useState()
    const [cancelledbooking, setcancelledbooking] = useState([]);
    const { user } = useContext(MainContext)
    const handleChange = (value) => {
        setIconValue(value)
    }
    
    const [value, setValue] = useState(85);

    useEffect(() => {
        const project = JSON.parse(sessionStorage.getItem('data'));
        setuserRole(project.user_role)
        getdashboardcount();
        getpartnercount();
        getcancelledbookingadmin();
        getData();
        window.scrollTo(0, 0);
    }, [])

    const [eventTitle, setEventTitle] = useState("");
    const [eventfulldata, setEventFulldata] = useState("");
    const [eventbname, setEventBname] = useState("");
    const [startdate, setStartDate] = useState("");
    const [enddate, setEndDate] = useState("");
    const [start_time, setStart_Time] = useState("");
    const [endtime, setEndTime] = useState("");
    const [dataShow, setDataShow] = useState(false);
    const [events, setevents] = useState([]);
    const [eventCount, setEventCount] = useState(0);
    const getData = () => {
        const project = JSON.parse(sessionStorage.getItem('data'));
        Axios.get(`${apiURL}/Booking_event?user_id=${project.user_id}`,
            {
                headers: {
                    'Authorization': `Bearer ${project.access_token}`,
                    "accept": "application/json",
                    "Content-Type": " application/json",
                }
            }).then((response) => {
                setValue(response.data.Booking.concat(response.data.Enquiry))
                handleEvents(response.data.Booking.concat(response.data.Enquiry))
                const combinedEvents = response.data.Booking.concat(response.data.Enquiry);
                setEventCount(combinedEvents.length);
            })
            .catch((err) => {
                {
                    console.log(err)
                }
            });
    }
    const handleEvents = (data) => {
        const eventCounts = data.reduce((acc, item) => {
            const startDate = new Date(item.start_date);
            const endDate = new Date(item.end_date);
            for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
                const dateString = date.toISOString().split('T')[0];
                acc[dateString] = (acc[dateString] || 0) + 1;
            }
            return acc;
        }, {});
        const newEvents = Object.keys(eventCounts).map(date => ({
            title: `${eventCounts[date]} Events`,
            start: date,
            end: date
        }));
        setevents(newEvents);
    };

    const openEventModal = (eventInfo) => {
        setEventTitle(eventInfo.event.title);
        setEventFulldata(eventInfo.event.extendedProps.place_name);
        setEventBname(eventInfo.event.extendedProps.name);
        setStartDate(eventInfo.event.extendedProps.start_date);
        setEndDate(eventInfo.event.extendedProps.end_date);
        setStart_Time(eventInfo.event.extendedProps.start_time);
        setEndTime(eventInfo.event.extendedProps.end_time);
        setDataShow(true);
        navigate('/ManageBooking'); 
    }
    function renderEventContent(eventInfo) {
        return (
            <>
                <span onClick={() => openEventModal(eventInfo)}>
                    <i>{eventInfo.event.title}</i>
                </span>
                <div>
                </div>
            </>
        )
    }
    const handleDateClick = (event) => {
        let selectedEvent = events.filter((item, index) => { return item.start === event.dateStr });
        if (selectedEvent.length > 0) {
        }
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            weekday: 'long'
        };
        return date.toLocaleDateString('en-US', options);
    };

    const getdashboardcount = () => {
        const project = JSON.parse(sessionStorage.getItem('data'));
        Axios.get(`${apiURL}/Dashboard_Counts`, {
            headers: {
                'Authorization': `Bearer ${project.access_token}`,
                "accept": "application/json",
                "Content-Type": " application/json",
            }
        }).then((response) => {
            const modifiedDate = formatDate(response.data.todays_date);
            response.data.todays_date = modifiedDate;
            setCount(response.data)
        })
            .catch((err) => {
                {
                    console.log(err)
                }
            });
    }

    const getpartnercount = () => {
        const project = JSON.parse(sessionStorage.getItem('data'));
        Axios.get(`${apiURL}/Dashboard_Counts_for_partner?partner_id=${project.user_id}`, {
            headers: {
                'Authorization': `Bearer ${project.access_token}`,
                "accept": "application/json",
                "Content-Type": " application/json",
            }
        }).then((response) => {
            setpartnercount(response.data)
        })
            .catch((err) => {
                {
                    console.log(err)
                }
            });
    }
    const getcancelledbookingadmin = () => {
        const project = JSON.parse(sessionStorage.getItem('data'));
        const token = JSON.parse(sessionStorage.getItem('data'));
        Axios.get(`${apiURL}/get_cancelled_booking`, {
            headers: {
                'Authorization': `Bearer ${token.access_token}`,
                "accept": "application/json",
                "Content-Type": " application/json",
            }
        },)
            .then((response) => {
                const { cancelled_enquiry, cancelled_bookings } = response.data;
                setcancelledbooking([...cancelled_enquiry, ...cancelled_bookings]);
            })
    }
    const navigate = useNavigate();

    const redirect = (flag) => { 
        if (flag === "booking") {
            navigate('/ManageBooking');
        } else if (flag === "enquiry") {
            navigate('/Enquiry');
        } else if (flag === "venue") {
            navigate('/AllPlaces');
        }else if (flag === "partners") {
            navigate('/Partners');
        }
    };

    return (
        <div className={user ? "g-sidenav-show bg-gray-100 g-sidenav-hidden " : "g-sidenav-show  bg-gray-100"} >
            <Aside />
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

                <Navbar Name='Dashboard' />

                <div className="container-fluid py-4" style={{ minHeight: "560px" }}>
                    <div className="row">
                        {userRole === 'admin' && (
                            <div className="col-8">
                                <div className="card">
                                    <div className="card-header d-flex pb-0 p-3">
                                        <h6 className="my-auto">Recently Added Images</h6>
                                        <div className="nav-wrapper position-relative ms-auto w-50 ">
                                            <ul className="nav nav-pills nav-fill p-1" role="tablist">
                                                <li className="nav-item">
                                                    <button className={state === "Kitchen" ? "nav-link mb-0 px-0 py-1 button-active" : "nav-link mb-0 px-0 py-1"} data-bs-toggle="tab"
                                                        role="tab" aria-controls="cam1" aria-selected="true" onClick={() => { setState("Kitchen") }} >
                                                        MKB Wedding Hall
                                                    </button>
                                                </li>
                                                <li className="nav-item">
                                                    <button className={state === "Living" ? "nav-link mb-0 px-0 py-1 button-active" : "nav-link mb-0 px-0 py-1"} data-bs-toggle="tab"
                                                        onClick={() => { setState("Living") }}  >
                                                        VST Hall
                                                    </button>
                                                </li>
                                                <li className="nav-item">
                                                    <button className={state === "Attic" ? "nav-link mb-0 px-0 py-1 button-active" : "nav-link mb-0 px-0 py-1"} data-bs-toggle="tab" role="tab"
                                                        aria-controls="cam3" aria-selected="false" onClick={() => { setState("Attic") }}>
                                                        MKB Hall
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card-body p-3 mt-2">

                                        {
                                            iconValue == true ? (
                                                <div className=' d-flex justify-content-start  dropdown-menu '>
                                                    <ul className=""
                                                        style={{ listStyleType: "none" }}>
                                                        <li className='list-data' onClick={() => handleChange(!iconValue)} >Pause</li>
                                                        <li className='list-data' onClick={() => handleChange(!iconValue)}>Stop</li>
                                                        <li className='list-data' onClick={() => handleChange(!iconValue)}>Schedule</li>

                                                    </ul>
                                                </div>
                                            ) : ""

                                        }

                                        <div className="tab-content" id="v-pills-tabContent mt-5" >
                                            {state === "Kitchen" ? (
                                                <div className="tab-pane fade show position-relative active height-400 border-radius-lg image-1"
                                                    id="cam1" role="tabpanel" aria-labelledby="cam1"
                                                >
                                                </div>) : ""

                                            }

                                            {state === "Living" ? (
                                                <div className="tab-pane fade show position-relative active height-400 border-radius-lg image-2"
                                                    id="cam1" role="tabpanel" aria-labelledby="cam1"
                                                >
                                                </div>) : ""

                                            }
                                            {state === "Attic" ? (
                                                <div className="tab-pane fade show position-relative active height-400 border-radius-lg image-3"
                                                    id="cam1" role="tabpanel" aria-labelledby="cam1"
                                                >
                                                </div>) : ""

                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {userRole === 'Partner' && (
                            <div className="col-8">
                                <div className="card">
                                    <div className="card-header d-flex pb-0 p-3">

                                        <div class="col-xl-12 mt-5">
                                            <div class="card card-calendar">
                                                <div class="card-body p-3">
                                                    <FullCalendar
                                                        defaultView="dayGridMonth"
                                                        header={{
                                                            left: "prev,next",
                                                            center: "title",
                                                            right: "dayGridMonth,timeGridWeek,timeGridDay"
                                                        }}
                                                        themeSystem="Simplex"
                                                        plugins={[dayGridPlugin, interactionPlugin]}
                                                        selectable
                                                        editable
                                                        events={events}
                                                        eventContent={renderEventContent}
                                                        height="720px"
                                                        dateClick={handleDateClick}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body p-3 mt-2">
                                        {
                                            iconValue == true ? (
                                                <div className=' d-flex justify-content-start  dropdown-menu '>
                                                    <ul className=""
                                                        style={{ listStyleType: "none" }}>
                                                        <li className='list-data' onClick={() => handleChange(!iconValue)} >Pause</li>
                                                        <li className='list-data' onClick={() => handleChange(!iconValue)}>Stop</li>
                                                        <li className='list-data' onClick={() => handleChange(!iconValue)}>Schedule</li>

                                                    </ul>
                                                </div>
                                            ) : ""
                                        }
                                    </div>
                                </div>
                            </div>
                        )}

                        {userRole === 'admin' && (
                            <div className="col-xl-4 ms-auto mt-xl-0 mt-4">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card bg-gradient-primary">
                                            <div className="card-body p-3">
                                                <div className="row">
                                                    <div className="col-8 my-auto">
                                                        <div className="numbers">
                                                            <p
                                                                className="text-white text-sm mb-0 text-capitalize font-weight-bold opacity-7">
                                                                {count.todays_date}</p>
                                                            <h5 className="text-white font-weight-bolder mb-0">
                                                                Today's Total Enquiries
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 text-end">
                                                        <FontAwesomeIcon className="mb-0 text-white text-end me-1" icon={Icons.faAsterisk} style={{ fontSize: "25px", cursor: "pointer" }} />

                                                        <h5 className="mb-0 text-white text-end me-1">{count.todays_enquiry_count}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-6 cur_pointer" onClick={() => redirect("booking")}>
                                        <div className="card pb-4">
                                            <div className="card-body text-center">
                                                <h1 className="text-gradient text-primary"><span id="status1" countto="21">{count.this_month_booking_count}</span> </h1>
                                                <h6 className="mb-0 font-weight-bolder"> This Month Booking</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-md-0 mt-4 cur_pointer" onClick={() => redirect("enquiry")}>
                                        <div className="card ">
                                            <div className="card-body text-center py-4">
                                                <h1 className="text-gradient text-primary"> <span id="status2" countto="44">{count.this_month_enquiry_count}</span> </h1>
                                                <h6 className="mb-0 font-weight-bolder">This Month Enquiry</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-6 cur_pointer" onClick={() => redirect("venue")}>
                                        <div className="card pb-4">
                                            <div className="card-body text-center">
                                                <h1 className="text-gradient text-primary"><span id="status1" countto="21">{count.total_venue_count}</span> </h1>
                                                <h6 className="mb-0 font-weight-bolder"> Total venue count</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-md-0 mt-4 cur_pointer" onClick={() => redirect("enquiry")}>
                                        <div className="card ">
                                            <div className="card-body text-center py-4">
                                                <h1 className="text-gradient text-primary"> <span id="status2" countto="44">{count.total_enquiry_count}</span> </h1>
                                                <h6 className="mb-0 font-weight-bolder">Total enquiry count</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-md-12 cur_pointer" onClick={() => redirect("booking")}>
                                        <div className="card pb-4">
                                            <div className="card-body text-center">
                                                <h1 className="text-gradient text-primary"><span id="status1" countto="21">{count.
                                                    total_booking_count
                                                }</span> </h1>
                                                <h6 className="mb-0 font-weight-bolder"> Total booking count</h6>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row mt-4">
                                    <div className="col-md-6 cur_pointer" onClick={() => redirect("booking")}>
                                        <div className="card pb-4">
                                            <div className="card-body text-center">
                                                <h1 className="text-gradient text-primary"><span id="status1" countto="21">{count.
                                                    total_cancelled_count
                                                }</span> </h1>
                                                <h6 className="mb-0 font-weight-bolder"> Cancelled event count</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 cur_pointer" onClick={() => redirect("partners")}>
                                        <div className="card pb-4">
                                            <div className="card-body text-center">
                                                <h1 className="text-gradient text-primary"><span id="status3" countto="87">{count.total_partner_count}</span> </h1>
                                                <h6 className="mb-0 font-weight-bolder">Total partners count</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )}

                        {userRole === 'Partner' && (
                            <div className="col-xl-4 ms-auto mt-xl-0 mt-4">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card bg-gradient-primary">
                                            <div className="card-body p-3">
                                                <div className="row">
                                                    <div className="col-8 my-auto">
                                                        <div className="numbers">
                                                            <p
                                                                className="text-white text-sm mb-0 text-capitalize font-weight-bold opacity-7">
                                                                {count.todays_date}</p>
                                                            <h5 className="text-white font-weight-bolder mb-0">
                                                                Today's Total Enquiries
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 text-end">
                                                        <FontAwesomeIcon className="mb-0 text-white text-end me-1" icon={Icons.faAsterisk} style={{ fontSize: "25px", cursor: "pointer" }} />

                                                        <h5 className="mb-0 text-white text-end me-1">{partnercount.todays_enquiry_count}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-6 cur_pointer" onClick={() => redirect("booking")}>
                                        <div className="card pb-4">
                                            <div className="card-body text-center">
                                                <h1 className="text-gradient text-primary"><span id="status1" countto="21">{partnercount.this_month_booking_count}</span> </h1>
                                                <h6 className="mb-0 font-weight-bolder"> This Month Booking</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-md-0 mt-4 cur_pointer" onClick={() => redirect("enquiry")}>
                                        <div className="card ">
                                            <div className="card-body text-center py-4">
                                                <h1 className="text-gradient text-primary"> <span id="status2" countto="44">{partnercount.this_month_enquiry_count}</span> </h1>
                                                <h6 className="mb-0 font-weight-bolder">This Month Enquiry</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-6 cur_pointer" onClick={() => redirect("venue")}>
                                        <div className="card pb-4">
                                            <div className="card-body text-center">
                                                <h1 className="text-gradient text-primary"><span id="status1" countto="21">{partnercount.total_venue_count}</span> </h1>
                                                <h6 className="mb-0 font-weight-bolder"> Total venue count</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-md-0 mt-4 cur_pointer" onClick={() => redirect("enquiry")}>
                                        <div className="card ">
                                            <div className="card-body text-center py-4">
                                                <h1 className="text-gradient text-primary"> <span id="status2" countto="44">{partnercount.total_enquiry_count}</span> </h1>
                                                <h6 className="mb-0 font-weight-bolder">Total enquiry count</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-6 cur_pointer" onClick={() => redirect("booking")}>
                                        <div className="card pb-4">
                                            <div className="card-body text-center">
                                                <h1 className="text-gradient text-primary"><span id="status1" countto="21">{partnercount.total_booking_count}</span> </h1>
                                                <h6 className="mb-0 font-weight-bolder"> Total booking count</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 cur_pointer" onClick={() => redirect("booking")}>
                                        <div className="card pb-4">
                                            <div className="card-body text-center">
                                                <h1 className="text-gradient text-primary"><span id="status3" countto="87">{partnercount.total_cancelled_count}</span> </h1>
                                                <h6 className="mb-0 font-weight-bolder">Cancelled event count</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row mt-5">

                                    <div className="col-md-6 mt-md-0 mt-4">

                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                    <div className="row mt-4">

                    </div>

                    <div className='row mt-4'>

                        <div class="col-xl-3">
                            <div class="row">
                                <div class="col-xl-12 col-md-6 mt-xl-0 mt-4">

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>

        </div>
    )
}

export default Dashboard
