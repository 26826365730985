import React, { useContext, useEffect, useState } from 'react'
import Aside from '../../../Layout/Aside'
import Navbar from '../../../Layout/Navbar'
import Footer from '../../../Layout/Footer'
import { MainContext } from '../../../Store/Context'
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Icons } from '../../../Icons/IconIndex'

const List = () => {
  const { user } = useContext(MainContext)
  const [data, setData] = useState([]);


  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    axios.get("https://jsonplaceholder.typicode.com/comments").then((res) => {
      console.log(res, "lllllllll");
      setData(res.data);

    });

  };






  const columns = [


    {
      dataField: "id",
      text: "Model Number",
      sort: true,
    },
    {
      dataField: "email",
      text: "Bulid Model Name",
      sort: true,
    },
    {
      dataField: "postId",
      text: "Dataset Count",
      sort: true,
    },
    {
      dataField: 'df14',
      isDummyField: true,
      text: 'Build By',
      formatter: (cellContent, row) => {
        console.log();
        if (row.id % 2 === 0 && row.id % 3 === 0) {
          return (
            <>
              <p className=""> Akash</p>
            </>
          );
        } else if (row.id % 2 === 0) {
          return (
            <>
              <p className="">sangeetha </p>
            </>
          );
        } else if (row.id % 3 === 0) {
          return (
            <>
              <p className="">kabil </p>
            </>
          );
        } else {
          return (
            <>
              <p className="">Aswanth </p>
            </>
          );
        }
      }
    },
    {
      dataField: 'df2',
      isDummyField: true,
      text: ' Build At',
      formatter: (cellContent, row) => {

        return (
          <>
            10-12-2022 05.34 pm
          </>
        );
      }
    },
    {
      dataField: 'df1',
      isDummyField: true,
      text: ' Delete',


    },


  ];
  const MySearch = (props) => {
    let input;
    const handleClick = () => {
      props.onSearch(input.value);
    };
    return (

      <div className="dataTable-search">
        <input className="dataTable-input"
          ref={n => input = n}
          type="text"
          placeholder="search"
          onChange={handleClick} />
      </div>
    );
  };



  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const sizePerPageOptionRenderer = ({
    text,
    page,
    onSizePerPageChange
  }) => (
    <div className="dataTable-dropdown">

      <li
        key={text}
        role="presentation"
        className="dataTable-dropdown"
      >
        <a 
          tabIndex="-1"
          role="menuitem"
          data-page={page}
          onMouseDown={(e) => {
            e.preventDefault();
            onSizePerPageChange(page);
          }}
        >
          {text}
        </a>
      </li>
    </div>
  );
  const pageButtonRenderer = ({
    page,
    active,
    disable,
    title,
    onPageChange
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    const activeStyle = {};
    if (active) {
      activeStyle.backgroundColor = '#cb0c9f';
      activeStyle.color = 'white';
    } else {
      activeStyle.backgroundColor = 'white';
      activeStyle.color = 'black';
    }
    if (typeof page === 'string') {
      activeStyle.backgroundColor = 'white';
      activeStyle.color = 'black';
    }
    return (
      <div className='dataTable-pagination'>
        <li className="pager">
          <a  onClick={handleClick} style={activeStyle}>{page}</a>
        </li>
      </div>
    );
  };
  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    sizePerPageOptionRenderer,
    pageButtonRenderer,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
      text: '6', value: 6
    }, {
      text: 'All', value: data.length
    }]
  };


  return (
    <div className={user ? "g-sidenav-show bg-gray-100 g-sidenav-hidden " : "g-sidenav-show  bg-gray-100"} >


      <Aside />

      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

        <Navbar Name='Enquiry' />

        <div className="container-fluid py-4 " style={{ minHeight: "560px" }}>

          <div className="row mt-4">
            <div className="col-12 card">
              <div className="card">

                <div className="card-header">
                  <h5 className="mb-0">All Booking Details</h5>
                  <p className="text-sm mb-0">
                    A lightweight, extendable, dependency-free javascript HTML table plugin.
                  </p>
                </div>


                <div className="card" >


                  <ToolkitProvider
                    keyField="id"
                    data={data}
                    columns={columns}
                    search
                  >
                    {
                      props => (
                        <div>
                          <div className=" dataTable-top">
                            <div className="dataTable-dropdown">    </div>

                            <div className=" " >
                              <MySearch  {...props.searchProps} />

                            </div>

                          </div>


                          <br />
                          <BootstrapTable
                            keyField="id"
                            {...props.baseProps}
                            striped
                            hover
                            condensed
                            pagination={paginationFactory(options)}


                          />
                        </div>
                      )
                    }
                  </ToolkitProvider>

                </div>
                {/* <div className="dataTable-bottom"><div className="dataTable-info">Showing 1 to 10 of 57 entries</div>
                      <nav className="dataTable-pagination"><ul className="dataTable-pagination-list"><li className="pager"><a  data-page="1">‹</a></li><li className="active"><a  data-page="1">1</a></li><li className=""><a  data-page="2">2</a></li><li className=""><a  data-page="3">3</a></li><li className=""><a  data-page="4">4</a></li><li className=""><a  data-page="5">5</a></li><li className=""><a  data-page="6">6</a></li><li className="pager"><a  data-page="2">›</a></li></ul></nav></div> */}
              </div>

            </div>
          </div>














        </div>
        <Footer />
      </main>

    </div>
  )
}

export default List
