import React from 'react'
import Images from '../../../Images'
import "../Style.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
const Banner5 = () => {
  return (
    <div className="Banner2 px-3  mx-5">
    <div  >
        <h3 className='my-4 text-center  Carousel-name'>Testimonials</h3>
        <div className='row pt-5' >
    <div className='col-md-6  p-0'>
    <img className=" col-image w-100 " src={Images.profile} height={500}  alt="product image" />

    </div>
    <div className='col-md-6  d-flex align-items-center ' style={{backgroundColor:"#F7F7F7"}}>
<div className='d-flex justify-content-center'>
<div className='w-75 '>

<p className='Carousel-name ' style={{fontSize:"30px"}}>It was an massive club house,like kind of crazy there were people on the shoulders,It was so much fun.</p>
<p className='col-data  mt-5 pt-1'> Michael</p>
<p className='  mt-3' style={{color:"#836D5D"}}> Guest</p>

<div class="row">
    <div className='col-8'></div>
                                <div class="col-2">
                              <button class=" py-2 px-3" style={{borderRadius:"8px",backgroundColor:"whitesmoke"}}>
                              <FontAwesomeIcon icon={faChevronLeft} style={{color:"#836D5D"}}/>
                              </button>
                              </div>
                              <div class="col-1">

                              <button class="py-2 px-3" style={{borderRadius:"8px",backgroundColor:"#836D5D"}}>
                              <FontAwesomeIcon icon={faChevronRight} style={{color:"#FFFFFF" }}/></button>
                              </div>
                              <div className='col-1'></div>

                          
                              </div>

</div>
</div>
    </div>

        </div>
        </div>
      </div>
  )
}

export default Banner5
