import React, { useContext, useEffect, useState } from 'react'
import Aside from '../../Layout/Aside'
import Navbar from '../../Layout/Navbar'
import Footer from '../../Layout/Footer'
import { MainContext } from '../../Store/Context'
import Axios from 'axios'
import { apiURL } from '../../Layout/Common/confiqurl'
import { Toaster, toast } from 'react-hot-toast'  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' 
import { Icons } from '../../Icons/IconIndex'
import { Button, Modal, Form } from "react-bootstrap";


const Contactdetail = () => {
  const { user } = useContext(MainContext)
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'descending' },
    { key: 'message', direction: 'descending' },
    { key: 'created_at', direction: 'descending' },
    { key: 'email_id', direction: 'descending' },
    { key: 'mobile_number', direction: 'descending' });

  const [state, setState] = useState([])
  console.log("contactapi$$$$", state)
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);


  const [openpopupEdit, setOpenpopupEdit] = useState(false);
  const [editValue, setEditValue] = useState();
  const [editSubject, seteditSubject] = useState();
  const [editFull_name, seteditFull_name] = useState();
  const [editEmail_id, seteditEmail_id] = useState();
  const [editMobile_number, seteditMobile_number] = useState();
  const [editMessage, seteditMessage] = useState(); 

  const [openpopupDelete, setopenpopupDelete] = useState(false);
  const [deleteValue, setDeleteValue] = useState();

  useEffect(() => {
    getData()
    setCurrentPage(1);
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState()
  const [datas, setDatas] = useState()

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = String(date.getFullYear());
    const month = String(date.getMonth() + 1).padStart(2, ''); 
    const day = String(date.getDate()).padStart(2, '');

    return `${month}/${day}/${year}`;
  }



  const getData = () => {

    const project = JSON.parse(sessionStorage.getItem('data'));

    Axios.get(`${apiURL}/contact_us`, {
      headers: {
        'Authorization': `Bearer ${project.access_token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
      .then(response => { 
        if (response.status === 200) {
          return response.data; 
        } else {
          throw new Error('Network response was not ok');
        }
      })
      .then(data => {
        const formattedData = data.db_contact_details.map(item => ({
          ...item,
          created_at: formatDate(item.created_at)  
        }));
        setState(formattedData);
      })
      .catch(error => {
        console.error('Error fetching data:', error); 
      });
  }

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };
  const filteredData = state.filter(item =>
    item.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.email_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.mobile_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.message.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.created_at.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const sortedData = [...filteredData].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });


  const getDatadelete = () => {
    const id = deleteValue;

    console.log("idbinddelete***", id)


    const project = JSON.parse(sessionStorage.getItem('data')); 

    Axios.delete(`${apiURL}/contact_us/${id}`, {
      headers: {
        'Authorization': `Bearer ${project.access_token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
      .then(response => {
        console.log("responseDataDeleteApi", response.data);
        if (response.data.detail === "Contact detail deleted successfully") {
          toast.success("Contact detail deleted successfully!");
          getData(response);  
          setopenpopupDelete(false); 
        }
      })
      .catch(error => {
        console.error('Error deleting data:', error);
        toast.error("Error deleting contact detail.");
      });

  }; 
  const getDataEdit = (id) => { 
    const project = JSON.parse(sessionStorage.getItem('data'));

    Axios.get(`${apiURL}/contact_us/${id}`, {
      headers: {
        'Authorization': `Bearer ${project.access_token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
      .then(response => {
        console.log("responseDatagetApi", response.data);
        seteditSubject(response.data.contact_details[0].subject);
        seteditFull_name(response.data.contact_details[0].full_name);
        seteditEmail_id(response.data.contact_details[0].email_id);
        seteditMobile_number(response.data.contact_details[0].mobile_number);
        seteditMessage(response.data.contact_details[0].message);
      })
      .catch(error => {
        console.error('Error get data:', error);
        toast.error("Error get contact detail.");
      });

  };


  const handleClick = (page) => {
    setCurrentPage(page);

  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);  
  };
  const pages = Math.ceil(filteredData.length / itemsPerPage);
  const startPage = Math.max(1, currentPage - 2);
  const endPage = Math.min(pages, startPage + 4);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, filteredData.length - 1);
  const formattedData = sortedData.slice(startIndex, endIndex + 1);
  const paginationButtons = [];
  for (let i = startPage; i <= endPage; i++) {
    paginationButtons.push(
      <li key={i} className={i === currentPage ? "active" : ""}>
        <a
          key={i}
          className='active'
          onClick={() => handleClick(i)}
          style={{ cursor: "pointer" }}
        >
          {i}
        </a>
      </li>
    );
  }
  const closeModalDelete = () => {
    setopenpopupDelete(false);
  };

  const openModalDelete = (id) => {
    setopenpopupDelete(true);
    setDeleteValue(id)
  }
  const openModalEdit = (id) => {
    setOpenpopupEdit(true);
    setEditValue(id);
    getDataEdit(id);
  }
  const closeModalEdit = () => {
    setOpenpopupEdit(false);
  }

  return (
    <div className={user ? "g-sidenav-show bg-gray-100 g-sidenav-hidden " : "g-sidenav-show  bg-gray-100"} >
      <Toaster toastOptions={{ position: "top-right" }} />

      <Aside />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

        <Navbar Name='Enquiry' />


        <div className="container-fluid py-4" style={{ minHeight: "560px" }}>
          <div className="row mt-4">
            <div className="col-12">
              <div className="card">

                <div className="card-header">
                  <h5 className="mb-0 mt-3">Contact Details</h5>
                  <p className="text-sm mb-0"> </p>
                </div>
                <div className="table-responsive">
                  <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                    <div className="dataTable-top">
                      <div className="dataTable-dropdown">

                      </div>
                    </div>
                  </div>

                </div>
                <div className="table-responsive">
                  <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                    <div className="dataTable-top">
                      <div className="dataTable-dropdown">
                        <label style={{ width: "300px" }}>
                          <select class="dataTable-selector w-50 me-2"
                            onChange={handleItemsPerPageChange}
                            style={{ cursor: "pointer" }}>
                            <option value="5" style={{ cursor: "pointer" }} >5</option>
                            <option value="10" style={{ cursor: "pointer" }}>10</option>
                            <option value="15" style={{ cursor: "pointer" }}>15</option>
                            <option value="20" style={{ cursor: "pointer" }}>20</option>
                          </select>
                          entries per page</label>
                      </div>
                      <div className="dataTable-search">
                        <input className="dataTable-input" placeholder="Search..." type="text" value={searchQuery} onChange={(event) => setSearchQuery(event.target.value)} />
                      </div>
                    </div>
                    <div className="dataTable-container table-responsive w-100" >
                      <table className="table  " id="datatable-search">
                        <thead className="thead-light">
                          <tr>
                            <th data-sortable="" >
                              <a  className="" style={{ fontSize: "12px", color: "#344767" }} > S.No </a>
                            </th>
                            <th data-sortable="" >
                              <a  className="" style={{ fontSize: "12px", color: "#344767" }} onClick={() => handleSort('created_at')} >Date {sortConfig.key === 'created_at' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</a>
                            </th>
                            <th data-sortable="" >
                              <a  className="" style={{ fontSize: "12px", color: "#344767" }} onClick={() => handleSort('subject')}> Subject {sortConfig.key === 'subject' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</a>
                            </th>
                            <th data-sortable="" >
                              <a  className="" style={{ fontSize: "12px", color: "#344767" }} onClick={() => handleSort('full_name')}> Name {sortConfig.key === 'full_name' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</a>
                            </th>
                            <th data-sortable="" >
                              <a  className="" style={{ fontSize: "12px", color: "#344767" }} onClick={() => handleSort('email_id')}>EmailID {sortConfig.key === 'email_id' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</a>
                            </th>
                            <th data-sortable="" style={{ width: "18.18577%" }}>
                              <a  className="" style={{ fontSize: "12px", color: "#344767" }} onClick={() => handleSort('mobile_number')} > Mobile No {sortConfig.key === 'mobile_number' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</a>
                            </th> 
                            <th data-sortable="" >
                              <a  className="" style={{ fontSize: "12px", color: "#344767" }} >Action </a>
                            </th>


                          </tr>
                        </thead>
                        <tbody>
                          {state.length > 0 ? (
                            sortedData && formattedData.map((item, index) => (
                              <tr key={index} className=''>
                                <td className="text-sm font-weight-normal " style={{ fontSize: "10px" }}>{index + 1}</td>
                                <td className="text-sm font-weight-normal" style={{ fontSize: "10px" }}>{item.created_at}</td>
                                <td className="text-sm font-weight-normal" style={{ fontSize: "10px" }}>{item.subject}</td>
                                <td className="text-sm font-weight-normal " style={{ fontSize: "10px" }}>{item.full_name}</td>
                                <td className="text-sm font-weight-normal" style={{ whiteSpace: 'normal' }} >{item.email_id}</td>
                                <td className="text-sm font-weight-normal" style={{ fontSize: "10px" }}>{item.mobile_number}</td>
                                 <td><a data-bs-toggle="tooltip" data-bs-original-title="Delete Hall"  >
                                  <i className="text-secondary" aria-hidden="true">
                                    <FontAwesomeIcon icon={Icons.faEye} onClick={() => openModalEdit(item.id)} />
                                  </i>
                                </a>
                                  <a data-bs-toggle="tooltip" data-bs-original-title="Delete Hall"  >
                                    <i className="text-secondary mx-3" aria-hidden="true">
                                      <FontAwesomeIcon icon={Icons.faTrash} onClick={() => openModalDelete(item.id)} />
                                    </i>
                                  </a></td>


                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="6" style={{ textAlign: 'center' }}>No data available</td>
                            </tr>
                          )}

                        </tbody>

                      </table>

                    </div>

                    <div className="dataTable-bottom mt-5">
                      <div className="dataTable-info">Showing {startIndex + 1} to  {currentPage === state.length ? filteredData.length : endIndex + 1}   of {filteredData.length} entries</div>
                      <nav className="dataTable-pagination">
                        <ul className="dataTable-pagination-list">
                          <li className="pager" style={{ cursor: "pointer" }}>
                            <a
                              onClick={() => handleClick(currentPage - 1)}
                              className={currentPage === 1 ? "button-disable" : ""}
                            > ‹ </a>
                          </li>
                          {paginationButtons}
                          <li className="pager" style={{ cursor: "pointer" }}><a data-page="2"
                            onClick={() => handleClick(currentPage + 1)}
                            className={currentPage === pages ? "button-disable" : ""}
                          >›</a></li>
                        </ul></nav>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>


          <Modal show={openpopupEdit} size='md' className='model-popup-design' >
            <div className='d-flex justify-content-between mt-3' >
              <Modal.Title className='ms-4 '>View Data</Modal.Title>
              <button className='me-3 btn btn-outline-secondary  ' onClick={closeModalEdit}>
                x
              </button>
            </div>
            <div className='loginmodalbody'>
              <Modal.Body className='testing-test' >
                <div className='row'>
                  <div className='col-md-12'>
                    <label for="full_name">Full Name</label>
                    <input className='form-control' placeholder="Full Name" type="text" value={editFull_name} disabled onChange={(event) => seteditFull_name(event.target.value)} />
                    
                    <label for="email_id">Email ID</label>
                    <input className='form-control' placeholder="Email ID" type="text" value={editEmail_id}disabled   onChange={(event) => seteditEmail_id(event.target.value)} />
                    
                    <label for="mobile_number">Mobile Number</label>
                    <input className='form-control' placeholder=" Mobile Number" type="text" value={editMobile_number} disabled  onChange={(event) => seteditMobile_number(event.target.value)} />

                    <label for="subject">Subject</label>
                    <input className='form-control' placeholder=" Subject" type="text" value={editSubject} disabled  onChange={(event) => seteditSubject(event.target.value)} />

                    <label for="message">Message</label>
                    <textarea rows={3} className="form-control" value={editMessage} disabled   onChange={(event) => seteditMessage(event.target.value)} />
                   
                  </div>
                </div>
              </Modal.Body>
            </div> 
          </Modal>


          <Modal show={openpopupDelete} size='md' className='model-popup-design' >
            <div className='d-flex justify-content-between mt-3' >
              <Modal.Title className='ms-4 '>Delete</Modal.Title>
              <button className='me-3 btn btn-outline-secondary  ' onClick={closeModalDelete}>
                x
              </button>
            </div>
            <div className='loginmodalbody' style={{ width: '785px' }}>
              <Modal.Body className='testing-test' >
                Are you sure you want to delete this item ?
              </Modal.Body>
            </div>
            <Modal.Footer>
              <Button variant="danger" style={{
                backgroundImage: 'linear-gradient(310deg, #7928ca, #ff0080)',
                color: 'white',
                border: 'none',
              }} onClick={getDatadelete}>
                Delete
              </Button>
              <Button variant="secondary" style={{
                backgroundColor: 'white',
                color: '#cb0c9f',
                border: '1px solid #cb0c9f',
              }} onClick={closeModalDelete}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>



        <Footer />
      </main>

    </div>
  )
}

export default Contactdetail