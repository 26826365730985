import React, { useState } from "react";
// npm install react-paginate (please install)
import ReactPaginate from 'react-paginate';

const TableDetails = [
    {
        id: 1,
        name: 'MSB Hall',
        place: 'Wedding Hall',
        location: 'Chennai',
        address: '23,Gst road',
        partner: 'Suresh',
        status: <span class="badge badge-danger badge-sm">Un Assigned</span>
    },
    {
        id: 2,
        name: 'VST Hall',
        place: 'Wedding Hall',
        location: 'Chennai',
        address: '23,North Street',
        partner: 'Siva',
        status: <span class="badge badge-danger badge-sm">Un Assigned</span>
    },
    {
        id: 3,
        name: 'PKD Hall',
        place: 'Mini Hall',
        location: 'Madurai',
        address: '5,Main road',
        partner: 'Raja',
        status:<span class="badge badge-success badge-sm"> Assigned</span>
    },
    {
        id: 4,
        name: 'SSR Hall',
        place: 'Mini Hall',
        location: 'Chennai',
        address: '23,Thambaram',
        partner: 'Suresh',
        status: <span class="badge badge-danger badge-sm">Un Assigned</span>
    },
    {
        id: 5,
        name: 'PPL Hall',
        place: 'Party Hall',
        location: 'Tirunelveli',
        address: '23,Nellai',
        partner: 'Arun',
        status:<span class="badge badge-success badge-sm"> Assigned</span>
    },
    {
        id: 6,
        name: 'PKB Hall',
        place: 'PKB Hall',
        location: 'Tirunelveli',
        address: '23,Nellai',
        partner: 'Bala',
        status: <span class="badge badge-danger badge-sm">Un Assigned</span>
    },
    {
        id: 7,
        name: 'SPS Hall',
        place: 'Meeting Hall',
        location: 'Tirunelveli',
        address: '78,Nellai',
        partner: 'Prabhu',
        status:<span class="badge badge-success badge-sm"> Assigned</span>
    },
    {
        id: 8,
        name: 'SPS Hall',
        place: 'Party Hall',
        location: 'Trichy',
        address: '23,North Street',
        partner: 'Vishnu',
        status: <span class="badge badge-danger badge-sm">Un Assigned</span>
    },
    {
        id: 9,
        name: 'MSB Hall',
        place: 'Wedding Hall',
        location: 'Chennai',
        address: '23,OMR Road',
        partner: 'Kumar',
        status:<span class="badge badge-success badge-sm"> Assigned</span>
    },
    {
        id: 10,
        name: 'VST Hall',
        place: 'Wedding Hall',
        location: 'Chennai',
        address: '23,ECR Road',
        partner: 'Thiru',
        status: <span class="badge badge-danger badge-sm">In Assigned</span>
    },

]


function Data()
{
  const [postdata,setPostdata]= useState(TableDetails);
  const [pagenumber, setPagenumber]= useState(0);
  const perpage=10;
  const pageclick= pagenumber*perpage;
  const countpage= Math.ceil(postdata.length/perpage);
 
  const changePage=({selected})=>{
    setPagenumber(selected);
  }
    return(
        <React.Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                    <h5 className="mt-2">Pagination</h5>                  
                    <table className="table table-bordered" >
                        <thead className="bg-light">
                           <tr>                            
                            <th>Sr. No</th>
                            <th>Post Title</th>   
                            <th>Body Contect</th>                
                           </tr> 
                        </thead>
                        <tbody>
                          { postdata.slice(pageclick, pageclick + perpage).map((datapost,index)=>(
                            <tr key={index}>
                            <td>{ datapost.id} </td>
                           <td>{ datapost.name} </td>
                           <td>{datapost.place} </td>
                           </tr> 
                             ))}
                         </tbody>                        
                    </table>       

                      <ReactPaginate 
                      className="d-flex-justify-content-between "
                      previousLable={"Previous"}
                      nextLable={"Next"}
                      pageCount= { countpage}
                      onPageChange={ changePage}
                      containerClassName={"dataTable-pagination-list"}
                      previousLinkClassName={"pager"}
                      nextLinkClassName={"pager"}
                      activeClassName={"active"}
                      disabledClassName={"paginationDisabled"}
                      style={{width:"700px"}}
/>   
                  </div>
                </div>
            </div>
           
        </React.Fragment>
    );
}
export default Data;














// import React, { useState, useContext, useEffect } from 'react'
// import Aside from '../../Layout/Aside'
// import Navbar from '../../Layout/Navbar'
// import { Button, Modal } from "react-bootstrap";
// import CloseButton from 'react-bootstrap/CloseButton';
// import Footer from '../../Layout/Footer'
// import { MainContext } from '../../Store/Context'
// //import Pagination from './pagenation'
// import { Icons } from '../../Icons/IconIndex'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import FileUpload from './Tablerecords';
// import { Link, useNavigate } from 'react-router-dom';
// import axios from 'axios'
// import ReactPaginate from 'react-paginate';

// //import TableDetails from './Tablerecords'


// const BulkUpload = () => {
//     const [state,setState]=useState([])

//     const { user } = useContext(MainContext)
//     const [currentPage, setCurrentPage] = useState(1);
//     console.log('dd',currentPage);
//     const [itemsPerPage, setItemsPerPage] = useState(5);
//     const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
//     const [openpopup, setopenpopup] = useState(false);
//     const [openpopupDelete, setopenpopupDelete] = useState(false);
//     const [searchQuery, setSearchQuery] = useState('');
// const [data,setData] = useState("")

//     useEffect(() => {
//         getData()
//       },[]);

//     const filteredData = state.filter(item => (item.mandapam_name||'').toLowerCase().includes(searchQuery.toLowerCase()));
//   console.log(state,"searchQuery");
   
//     const navigate=useNavigate()
//     const closeModal = () => {

//         setopenpopup(false);
//     };
//     const openModal = () => {
//         setopenpopup(true);
//     }

//     const closeModalDelete = () => {

//         setopenpopupDelete(false);
//     };
//     const openModalDelete = () => {
//         setopenpopupDelete(true);
//     }
// //     const pageclick= pagenumber*perpage;
// //   const countpage= Math.ceil(postdata.length/perpage);
//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     console.log(indexOfFirstItem, "indexOfFirstItem");
//     // const currentPages = filteredData.slice(indexOfFirstItem, indexOfLastItem);
//     const countpage= Math.ceil(filteredData.length/itemsPerPage);

//     const pageNumbers = [];
//     // for (let i = 1; i <= Math.ceil(filteredData.length / itemsPerPage); i++) {
//     //   pageNumbers.push(i);
//     // }
  
  
//     // const handlePageChange = (pageNumber) => {
//     //   setCurrentPage(pageNumber);
//     // };
//     const changePage=({selected})=>{
//         setCurrentPage(selected);
//       }

//     const getData = () =>{

//         axios.get('http://localhost:8000/Viewall')
//         .then((response) =>{
//           console.log(response.data);
//           setState(response.data.details)
//         })
//           }


//     // Handle items per page change

//     const handleCheckboxChange = (id) => {
//         let updatedCheckboxes;
//         if (selectedCheckboxes.includes(id)) {
//           updatedCheckboxes = selectedCheckboxes.filter(day => day !== id);
//         } else {
//           updatedCheckboxes = [...selectedCheckboxes, id];
//         }
//         setSelectedCheckboxes(updatedCheckboxes);
//       };
//     return (
//         <div className={user ? "g-sidenav-show bg-gray-100 g-sidenav-hidden " : "g-sidenav-show  bg-gray-100"} >
//             <Aside />
//             <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

//                 <Navbar Name='Bulk Upload' />

//                 <div className="container-fluid py-4">
//                     <div className="card">

//                         <div class="card-header pb-0">
//                             <div class="d-lg-flex">
//                                 <div>
//                                     <h5 class="mb-0">Bulk Upload</h5>
//                                     <p class="text-sm mb-0">
//                                         A lightweight, extendable, dependency-free javascript HTML table plugin.
//                                     </p>
//                                 </div>
//                                 <div class="ms-auto my-auto mt-lg-0 mt-4">
//                                     <div class="ms-auto my-auto">
//                                         <button  class="btn bg-gradient-primary btn-sm mb-0" target="_blank"  onClick={()=>{navigate("/AddNew")}}>+&nbsp; New Place</button>
//                                         <button type="button" class="btn btn-outline-primary mx-2 btn-sm mb-0" data-bs-toggle="modal" data-bs-target="#import" onClick={() => openModal()}>
//                                             Import
//                                         </button>
//                                         <div class="modal fade" id="import" tabindex="-1" aria-hidden="true">
//                                             <div class="modal-dialog mt-lg-10">
//                                                 <div class="modal-content">
//                                                     <div class="modal-header">
//                                                         <h5 class="modal-title" id="ModalLabel">Import CSV</h5>
//                                                         <i class="fas fa-upload ms-3" aria-hidden="true"></i>
//                                                         <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                                                     </div>
//                                                     <div class="modal-body">
//                                                         <p>You can browse your computer for a file.</p>
//                                                         <input type="text" placeholder="Browse file..." class="form-control mb-3" onfocus="focused(this)" onfocusout="defocused(this)" />
//                                                         <div class="form-check">
//                                                             <input class="form-check-input" type="checkbox" value="" id="importCheck" checked="" />
//                                                             <label class="custom-control-label" for="importCheck">I accept the terms and conditions</label>
//                                                         </div>
//                                                     </div>
//                                                     <div class="modal-footer">
//                                                         <button type="button" class="btn bg-gradient-secondary btn-sm" data-bs-dismiss="modal">Close</button>
//                                                         <button type="button" class="btn bg-gradient-primary btn-sm">Upload</button>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <button class="btn btn-outline-primary btn-sm export mb-0 mt-sm-0 mt-1" data-type="csv" type="button" name="button">Export</button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="card-body px-0 pb-0">
//                             <div className='table-responsive'>
//                                 <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
//                                     <div class="dataTable-top">
//                                     <div class="dataTable-dropdown">
//                         <label style={{ width: "300px" }}>
//                           <select class="dataTable-selector w-50 me-2" onClick={(e)=>setItemsPerPage(e.target.value)}>
//                             <option value="5" >5</option>

//                             <option value="15">15</option>
//                             <option value="20">20</option>
//                             <option value="25">25</option>
//                           </select>
//                           entries per page</label>
//                       </div>
//                                         <div class="dataTable-search">
//                                             <input class="dataTable-input" placeholder="Search..." type="text"  value={searchQuery} onChange={(event) => setSearchQuery(event.target.value)} />
//                                         </div>
//                                     </div>
//                                     <div className="dataTable-container">
//                                         <table class="table table-flush dataTable-table" id="products-list">
//                                             <thead class="thead-light">
//                                                 <tr>
//                                                     <th data-sortable="" style={{ width: "11.7819%;" }}><a href="#" class="dataTable-sorter">Place Name</a></th>
//                                                     <th data-sortable="" style={{ width: "11.7819%;" }}><a href="#" class="dataTable-sorter">Place Type</a></th>
//                                                     <th data-sortable="" style={{ width: "11.7819%;" }}><a href="#" class="dataTable-sorter">Location</a></th>
//                                                     <th data-sortable="" style={{ width: "11.7819%;" }}><a href="#" class="dataTable-sorter">Address</a></th>
//                                                     <th data-sortable="" style={{ width: "11.7819%;" }}><a href="#" class="dataTable-sorter">Partner</a></th>
//                                                     <th data-sortable="" style={{ width: "11.7819%;" }}><a href="#" class="dataTable-sorter">Status</a></th>
//                                                     <th data-sortable="" style={{ width: "11.7819%;" }}><a href="#" class="dataTable-sorter">Action</a></th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {filteredData && filteredData.slice(indexOfLastItem, indexOfLastItem + itemsPerPage).map((item, i) =>{
//                                                 //    const Data=item.mandap_link.slice(23, 30)
//                                                   console.log(item,"kkkkkkkkkkkkkkkkkkkkkk");
                                                
//                                                      if (item.address)
                                                        
                                                     
//                                                   return(  <tr key={i + 1}>
//                                                         <td>
//                                                             <div class="d-flex">
//                                                                 <div class="form-check my-auto">
//                                                                     <input class="form-check-input" type="checkbox" value={item.id} id="customCheck1" onChange={() => handleCheckboxChange(item.id)} />
//                                                                 </div>
//                                                                 <h6 class="my-auto">{item.name}</h6>
//                                                             </div>
//                                                         </td>
//                                                         <td class="text-sm">{item.mandapam_name}</td>
//                                                         <td class="text-sm">{item.mandap_link}</td>
//                                                         <td class="text-sm">{item.address}</td>
//                                                         <td class="text-sm">Unknown</td>
//                                                         <td class="text-sm">{
//                                                      item.id%2==0?(<span class="badge badge-success badge-sm"> Assigned</span>):( <span class="badge badge-danger badge-sm">In Assigned</span>)

//                                                         }</td>
//                                                         <td class="text-sm">
//                                                             <a data-bs-toggle="tooltip" data-bs-original-title="Preview product">
//                                                                 <i class="text-secondary" aria-hidden="true" onClick={()=>{navigate("/Editpage")}}>
//                                                                     <FontAwesomeIcon icon={Icons.faEye} />
//                                                                 </i>
//                                                             </a>
//                                                             <a data-bs-toggle="tooltip" className='mx-3' data-bs-original-title="Edit Hall" onClick={()=>{navigate("/Editpage")}} >
//                                                                 <i class="text-secondary" aria-hidden="true">
//                                                                     <FontAwesomeIcon icon={Icons.faPen} />
//                                                                 </i>
//                                                             </a>
//                                                             <a data-bs-toggle="tooltip" data-bs-original-title="Delete Hall" onClick={openModalDelete}>
//                                                                 <i class="text-secondary" aria-hidden="true">
//                                                                     <FontAwesomeIcon icon={Icons.faTrash} />
//                                                                 </i>
//                                                             </a>
//                                                         </td>
//                                                     </tr>)}
//                                                 )}
//                                             </tbody>
//                                         </table>

//                                     </div>
//                                     <div class="dataTable-bottom">
//                                     <div class="dataTable-info">Showing {indexOfFirstItem+1} to  {currentPage === countpage?filteredData.length:indexOfLastItem}   of {filteredData.length} entries</div>
//                                     <nav class="dataTable-pagination">
//                         {/* <ul class="dataTable-pagination-list">
//                           <li class="pager">
                    
//                           <a 
//                            onClick={() => handlePageChange(currentPage - 1)}
//                            className={currentPage === 1?"button-disable":""}
                          
//                       > ‹ </a>
//                           </li>
//                           {pageNumbers.map((number) => (
//                             <li key={number} className={number == currentPage ? "active" : " pager"}>
//                               <a
//                                 onClick={() => setCurrentPage(number)}
//                                 href="#"
//                                 className={""}
//                               >
//                                 {number}
//                               </a>
//                             </li>
//                           ))}


//                           <li class="pager"><a  data-page="2"
//                           onClick={() => handlePageChange(currentPage + 1)}

//                           className={currentPage === pageNumbers.length?"button-disable":""}
//                           >›</a></li>

//                         </ul> */}
//                          <ReactPaginate
//                       className="d-flex-justify-content-between "
//                       previousLable={"Previous"}
//                       nextLable={"Next"}
//                       pageCount= { countpage}
//                       onPageChange={ changePage}
//                       containerClassName={"dataTable-pagination-list"}
//                       previousLinkClassName={"pager"}
//                       nextLinkClassName={"pager"}
//                       activeClassName={"active"}
//                       disabledClassName={"paginationDisabled"}
                      
// />  
//                         </nav>
                                       
//                                     </div>
//                                 </div>
//                             </div>

//                         </div>
//                     </div>

//                 </div>
//                 <Modal show={openpopup} size='lg' className='model-popup-design' >
                 
//                     <div className='d-flex justify-content-between mt-3' >
//           <Modal.Title className='ms-4 '>Bulk Upload</Modal.Title>
//           <button  className='me-3 btn btn-outline-secondary  ' onClick={closeModal}>
//             x
//           </button>
//         </div>

//                     <div className='loginmodalbody' style={{ width: '785px' }}>
//                         <Modal.Body className='testing-test' >
                           
//                            <FileUpload />

//                         </Modal.Body>
//                     </div>
//                     <Modal.Footer>
//                     <Button variant="primary" onClick={closeModal}>
//                             Upload
//                         </Button>
//                         <Button variant="secondary" onClick={closeModal}>
//                             Cancel
//                         </Button>
                   

//                     </Modal.Footer>
//                 </Modal>


//                 <Modal show={openpopupDelete} size='md' className='model-popup-design' >
//                 <div className='d-flex justify-content-between mt-3' >
//           <Modal.Title className='ms-4 '>Delete</Modal.Title>
//           <button  className='me-3 btn btn-outline-secondary  ' onClick={closeModalDelete}>
//             x
//           </button>
//         </div>
//                     <div className='loginmodalbody' style={{ width: '785px' }}>
//                         <Modal.Body className='testing-test' >
                           
//                          Are you sure you want to delete this item ?
//                         </Modal.Body>
//                     </div>
//                     <Modal.Footer>
//                     <Button variant="danger" onClick={closeModalDelete}>
//                             Delete
//                         </Button>
//                         <Button variant="secondary" onClick={closeModalDelete}>
//                             Cancel
//                         </Button>
                     

//                     </Modal.Footer>
//                 </Modal>
//                 <Footer />
//             </main>

//         </div>
//     )
// }

// export default BulkUpload
