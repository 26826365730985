import React, { useEffect } from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Enquiry from '../Form/Enquiry';
import Card from '../Form/Card';
import Images from '../../../Images';
import { faCodeFork, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { Icons } from '../../../Icons/IconIndex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toaster, toast } from 'react-hot-toast';
import Axios from 'axios';
import { apiURL } from '../../../Layout/Common/confiqurl';
import { maxLength, numberMethod, validPasswordRegex, validEmailRegex, validPhoneNumberRegex } from '../../../Layout/Common/common';

const About = (props) => {
    const {allData}=props
    const today = new Date().toISOString().substr(0, 10); // get today's date in ISO format

    const [form, setForm] = useState(false)
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [alertShow, setAlertShow] = useState("")
    const [events, setevents] = useState([]
    )
    const [state, setState] = useState({

        first: "",
        second: "",
        third: "",
        forth: "",


    })
    const [stateData, setStateData] = useState({

     email_id:"",
        enter_event_organizer: '',
        select_end_time: "",
        select_start_time: "",
        select_to_date: "",
        select_from_date: "",
        event_type:"",
        place_name:"",

    })


    const handleDataChange = (event) => {
        setStateData({
            ...stateData,
            [event.target.name]: event.target.value,
        });
    };

    console.log(state);
    const handleAlertClose = () => setAlertShow(false);
    const handleAlertOpen = () => setAlertShow(true);

    const handleFormClose = () => setForm(false);
    const handleFormShow = () => setForm(true);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [inputValue, setInputValue] = useState('');
    const [inputPhone, setInputPhone] = useState('');

    const [inputUser, setInputUser] = useState('');

    const handleInputUserChange = (event) => {
        const capitalizedValue = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        setInputUser(capitalizedValue);
    }

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
    };

    const handleInputChange = (event) => {
        const value = event.target.value;
        if (/^\d{0,10}$/.test(value)) { // Limit input to 10 numbers
            setInputValue(value);
        }
    };


    const handlePhoneChange = (event) => {
        const value = event.target.value;
        if (/^\d{0,10}$/.test(value)) { // Limit input to 10 numbers
            setInputPhone(value);
        }
    };



 

 
    const EventData = () => {
        const token = JSON.parse(sessionStorage.getItem('data'));
        console.log(token.access_token, "jhhhh")
        Axios.get(`${apiURL}/get_eventType`, {
            headers: {
                'Authorization': `Bearer ${token.access_token}`,

                "accept": "application/json",
                "Content-Type": " application/json",
            }
        },)
            .then((response) => {
                // console.log(response.data);
                setevents(response.data)
            })
    }
    const storageValue=()=>{
        setInputPhone(sessionStorage.getItem("number"))
    }

    const handleclick = (e) => {
        const project = JSON.parse(sessionStorage.getItem('data'));
        console.log(project.access_token, "access token");

        const payload = {

            place_name: allData.mandapam_name,
            mandapam_id:allData.id,
            event_type: stateData.event_type,
            name: inputUser,
            contact_number: inputValue,
            start_date: stateData.select_from_date,
            end_date: stateData.select_to_date,
            start_time: stateData.select_start_time,
            end_time: stateData.select_end_time,
            updated_by:project.user_name,
            email_id: stateData.email_id,
          
        }
        if (stateData.event_type.trim()) {
            if (inputUser.trim()) {
                if (validPhoneNumberRegex.test(inputValue)) {
                    if (validEmailRegex.test(stateData.email_id)) {

                            if (stateData.select_from_date.trim()) {

                                if (stateData.select_to_date.trim()) {

                                    if (stateData.select_start_time.trim()) {
                                        if (stateData.select_end_time.trim()) {

                                            Axios.post(`${apiURL}/EnquiyList?user_id=${project.user_id}`, payload,
                                                {
                                                    headers: {
                                                        'Authorization': `Bearer ${project.access_token}`,

                                                        "accept": "application/json",
                                                        "Content-Type": " application/json",
                                                    }

                                                },

                                            ).then((response) => {


                                                handleShow()
                                                toast.success(' Event Created Successfully')
                                                console.log(response.data.Number,"kk");
                                                sessionStorage.setItem("number",response.data.Number)
                                                sessionStorage.setItem("id",response.data.id)
                                               storageValue()


                                                setStateData([])
                                            })
                                                .catch((err) => {
                                                    {
                                                        toast.error("Invalid User")


                                                    }
                                                });

                                        } else {
                                            toast.error('Please Enter End Time');
                                        }
                                    } else {
                                        toast.error('Please Enter Start Time');
                                    }
                                } else {
                                    toast.error('Please Enter End Date');
                                }

                            } else {
                                toast.error('Please Enter Start Date');
                            }
                    } else {
                        toast.error('Please Enter Valid Email');
                    }
                } else {
                    toast.error('Please Enter Mobile Number');
                }
            } else {
                toast.error('Please Enter Organizer Name');
            }
        } else {
            toast.error('Select Event Type');
        }

    }

console.log(inputPhone,"kk");
    const handleMobileClick = (e) => {

const id=sessionStorage.getItem("id")
  
const project = JSON.parse(sessionStorage.getItem('data'));

console.log(project,"project");

        if (validPhoneNumberRegex.test(inputPhone)) {

            Axios.post(`${apiURL}/Enquiry_OTP?id=${id}&mob=${inputPhone}`, {
                headers: {
                    "accept": "application/json",
                    // 'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${project.access_token}`,

                }
            },
            ).then((response) => {

                console.log('loginsuccess', response.data);
                sessionStorage.setItem("number",response.data.OTP)

                handleShow1()
                handleSplit()



            })
                .catch((err) => {
                    {
                        toast.error(err)
                    }
                });

        } else {
            toast.error('Please Enter Valid Mobile Number');
        }

    }


    useEffect(() => {
        
        EventData()
        //     const project=JSON.parse(sessionStorage.getItem('response'));
        //  setOtp(project.OTPCode)
        //     const value=project.OTPCode
        //     handleSplit(value)

    }, []);
    const handleSplit = () => {
     const otp=sessionStorage.getItem("number")

        const [first, second, third, forth] = otp.split("");
        setState({ first, second, third, forth })

    }

    const handleOtpClick = (e) => {

        const data = `${state.first}${state.second}${state.third}${state.forth}`
        const id=sessionStorage.getItem("id")

        const token = JSON.parse(sessionStorage.getItem('data'));
        // console.log(token.access_token,"jhhhh")
        const options = {

            Enter_OTP: data,

        }


console.log(options,"kkkkkk",id);



        Axios.post(`${apiURL}/verify_otp?id=${id}`, options, {
            headers: {
                "accept": "application/json",
                "Content-Type": " application/json",

            }
        },
        ).then((response) => {
            console.log('loginsuccess', response);
            //    navigate("/ResetPassword")
            
                // toast.success('otp genarating') 
                handleClose1()
                handleClose()
                handleFormClose()
                handleAlertOpen()
            
        })
            .catch((err) => {
                {
                    toast.error("Invalid User")
                    // setIsLoading(false);
                }
            });



    }





    return (
        <div className='px-3  mx-5  mb-5'>
                  <Toaster toastOptions={{ position: "top-right" }} />

            <h5 className='text-black mx-3 '>About {allData.mandapam_name?allData.mandapam_name :"Pai Vista "} </h5>
            <div className='row mt-3'>
                <div className='col-md-7 px-3'>
                    <p className='' style={{ fontSize: "20px" }}>in a leafy area with businesses, this low-key hotel is a 13-minute walk from a metro station, 3 km from the 16th-century Hindu Nandi Temple and 5 km from the circa-1700s Bangalore Fort.
                        <br />
                        Warmly decorated rooms come with flat-screen TVs, free Wi-Fi, and tea and coffeemaking facilities, as well as air-conditioners and minibars. Room service is available</p>
                </div>
                <div className='col-md-5 px-3 card'>
                    <div className='row mt-5 '>
                        <div className='col-6 px-3 '>
                            <h6>Check in </h6>
                            <div >
                                <input className="form-select-md p-1 w-100 border border-black" type='Date' />
                            </div>
                            <button type="button" className="btn  me-5  bg-white w-100 mt-5 " style={{ color: " #836D5D" }} >Contact</button>

                        </div>
                        <div className='col-6'>
                            <h6>Check Out</h6>
                            <div>
                                <input className="form-select-md p-1 w-100 border border-black " type='Date' />
                            </div>
                            <button type="button" className="btn  me-5  text-white w-100 mt-5" style={{ backgroundColor: " #836D5D" }} onClick={handleFormShow}>Check Availability</button>

                        </div>

                    </div>


                </div>

            </div>
            <Modal size='xl' show={form} >
                <Modal.Header className='d-flex justify-content-end '>
                    <button className='me-3 btn btn-outline-secondary  ' onClick={handleFormClose}>
                        x
                    </button>
                </Modal.Header>

                <Modal.Body className='d-flex justify-content-center'>
                    <div>

                        <div className="container">
                            <div className="row mt-5">
                                <div className="col-lg-6  ">
                                    <img src={Images.galleryimage14} alt='' className='h-100 w-100 mb-5' style={{ backgroundSize: "cover" }} />

                                </div>
                                <div className="col-lg-6 ps-4 ">
                                    <div className="card card-plain " >
                                        <div className=" pb-0 text-left ms-4">
                                            <h4 className="font-weight-bolder">Event Date & Sessions</h4>
                                        </div>
                                        <div className="card-body ">
                                            <form role="form">

                                                <div className='' >
                                                    <label>Event Type	 </label>

                                                    <select id="inputState" className="form-select " name='event_type' value={stateData.event_type} onChange={handleDataChange} style={{ cursor: "pointer" }}>
                                                        <option value="" disabled selected hidden>
                                                            Select an option
                                                        </option>

                                                        {
                                                            events.map((data) => {

                                                                return (
                                                                    <option style={{ cursor: "pointer" }}> {data.data_value}</option>

                                                                )
                                                            })
                                                        }
                                                    </select>

                                                </div>

                                                <label className='' >Event Organizer</label>
                                                <div className="">
                                                    <input type="text" className="form-control form-control-lg" placeholder="Event Organizer" aria-label="username" name='enter_event_organizer' value={inputUser} onChange={handleInputUserChange} />
                                                </div>
                                                <label className='mt-3'>Mobile Number</label>
                                                <div className="">
                                                    <input
                                                        className="form-control"
                                                        placeholder='  mobile number'

                                                        type="text"
                                                        id="input"
                                                        maxLength={10} // Set the maximum length of input to 10
                                                        pattern="[0-9]*" // Only allow numbers
                                                        value={inputValue}
                                                        onChange={handleInputChange}
                                                    />                                        </div>
                                                <label className='mt-3'>Email</label>
                                                <div className="">
                                                    <input type="email" className="form-control" placeholder="Email" aria-label="Email" name='email_id' value={stateData.email_id} onChange={handleDataChange} />
                                                </div>
                                               

                                                <div className='' >
                                                    <label>Place Name  </label>
                                                    <input className="form-control" type="text" placeholder='place Name ' name='place_name' value={allData.mandapam_name} disabled/>
                                                </div>
                                                <div className='row mt-3'>
                                                    <div className='col-6' >
                                                        <label> From Date</label>
                                                        <input className="form-control" type="Date" placeholder='Enter  From Date ' name='select_from_date' value={stateData.select_from_date} min={today}  onChange={handleDataChange} onFocus={(e) => e.target.showPicker()}/>
                                                    </div>
                                                    <div className='col-6' >
                                                        <label> To Date</label>

                                                        <input className="form-control" type="Date" placeholder='Enter To Date' name='select_to_date' value={stateData.select_to_date} min={today}  onChange={handleDataChange} onFocus={(e) => e.target.showPicker()}/>


                                                    </div>

                                                </div>
                                                <div className='row mt-3'>
                                                    <div className='col-6' >
                                                        <label> Start Time</label>
                                                        <input className="form-control" type="time" placeholder='Enter Start Time' name='select_start_time' onFocus={(e) => e.target.showPicker()} value={stateData.select_start_time} onChange={handleDataChange} />
                                                    </div>
                                                    <div className='col-6' >
                                                        <label> End Time</label>

                                                        <input className="form-control" type="time" placeholder='Enter End Time' name='select_end_time' onFocus={(e) => e.target.showPicker()} value={stateData.select_end_time} onChange={handleDataChange} />


                                                    </div>

                                                </div>
                                                <div className="text-center mt-4">
                                                    <button type="button" className="btn text-white  w-100 mt-4 mb-0" style={{ backgroundColor: "#836D5D" }} onClick={()=>{handleclick()}}>Submit</button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="container mt-5 mb-4 " style={{ backgroundColor: "#F1EDE7" }}>
                            <h5 className='text-black mx-3 mt-4'>Pai Vista <span className='ms-3'> 4 <img src={Images.Vector} alt='' /></span> </h5>
                            <div className='mt-1 mx-3'>
                                <p className='text-black '><FontAwesomeIcon className='  me-1 ' icon={faLocationDot} />Basavanagudi</p>

                            </div>
                            <div className='mt-1 row '>
                                <div className='col-xl-4 col-md-6'><h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={Icons.faUser} />Hall Capacity - 700</h6>
                                </div>
                                <div className='col-xl-4 col-md-6'><h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={faCodeFork} />Food Type - Veg/Non Veg</h6>
                                </div>
                            </div>

                            <h6 className='mt-1'><FontAwesomeIcon className='Details-data text-black mx-4' icon={Icons.faBox} /> Rooms :   AC / Yes -NonAC / Yes</h6>

                            <p>For Instant help in Booking contact us on  <span className='text-danger'>+919876543210</span></p>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
            <Modal size='lg' show={show} >
                <Modal.Header className='d-flex justify-content-end '>
                    <button className='me-3 btn btn-outline-secondary  ' onClick={handleClose}>
                        x
                    </button>
                </Modal.Header>
                <Modal.Body className='d-flex justify-content-center'>
                    <div className="card w-75 ">

                        <div className=" pb-0 text-center">
                            <h6 className="font-weight-bolder">Verify Your Mobile Number to Contact Venue</h6>
                            <p className="mb-0 mt-2">You will Receive 4 DIgit Code To verify next</p>
                        </div>
                        <div className="card-body">
                            <form role="form">
                                <label>Mobile Number</label>

                                <div className="mb-3">


                                    <input
                                        placeholder='  mobile number'
                                        className="form-control"
                                        type="text"
                                        id="input"
                                        maxLength={10} // Set the maximum length of input to 10
                                        pattern="[0-9]*" // Only allow numbers
                                        value={inputPhone}
                                        onChange={handlePhoneChange}
                                    />

                                </div>
                                {/* <div className="mb-3">
            <input type="text" className="form-control form-control-lg" placeholder="Verification Code" aria-label="code" />
        </div> */}
                                <div className="text-center">
                                    <button type="button" className="btn text-white" onClick={handleMobileClick} style={{ backgroundColor: " #836D5D" }}>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>

            </Modal>
            <Modal size='lg' show={show1} >
                <Modal.Header className='d-flex justify-content-end '>
                    <button className='me-3 btn btn-outline-secondary  ' onClick={handleClose1}>
                        x
                    </button>
                </Modal.Header>
                <Modal.Body className='d-flex justify-content-center'>
                    <div className="card w-75 ">

                        <div className=" pb-0 text-center">
                            <h5 className="font-weight-bolder">OTP</h5>
                        </div>
                        <div className="card-body">
                            <form role="form">
                                <h6 className='text-center'>Please enter a 4 Digit OTP sent by us</h6>

                                <div className="row w-50  mx-auto ">
                                    <div className="col">
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-lg" maxlength="1" name='first' value={state.first} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-lg" maxlength="1" name='second' value={state.second} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-lg" maxlength="1" name='third' value={state.third} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-lg" maxlength="1" name='forth' value={state.forth} onChange={handleChange} />
                                        </div>
                                    </div>


                                </div>
                                {/* <div className="mb-3">
            <input type="text" className="form-control form-control-lg" placeholder="Verification Code" aria-label="code" />
        </div> */}
                                <div className="text-center">
                                    <button type="button" className="btn text-white" onClick={handleOtpClick} style={{ backgroundColor: " #836D5D" }}>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>

            </Modal>
            <Modal size='md' show={alertShow} >
                <Modal.Header className='d-flex justify-content-between '>
                    <h4>Confirmation</h4>
                    <button className='me-3 btn btn-outline-secondary  ' onClick={handleAlertClose}>
                        x
                    </button>
                </Modal.Header>
                <Modal.Body className='d-flex justify-content-center'>
                   
                  <h6 className='mx-4 text-center'>Thank you for choosing us! Your enquire details shared with the venue's partner, They will contact you soon. Cheers!</h6>
                </Modal.Body>
                <Modal.Footer>
                        <Button variant="primary" onClick={handleAlertClose}>
                            Ok
                        </Button>
                       


                    </Modal.Footer>
            </Modal>

        </div>
    )
}

export default About
