import React, { useState, useContext, useEffect } from 'react'
import Aside from '../../Layout/Aside'
import Navbar from '../../Layout/Navbar'
import { Button, Modal } from "react-bootstrap";
import CloseButton from 'react-bootstrap/CloseButton';
import Footer from '../../Layout/Footer'
import { MainContext } from '../../Store/Context'
import { Icons } from '../../Icons/IconIndex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FileUpload from './Tablerecords';
import { Link, useNavigate } from 'react-router-dom';
import Axios from 'axios'
import axios from 'axios';
import "./style.css"
import css from '../../../src/View/Dashboard/Style.css';
import { apiURL } from '../../Layout/Common/confiqurl';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import ExcelExport from './ExcelExport';
import { Avatar } from '@material-ui/core'
import avathar from "../../Images/avathar.jpg";
import { BeatLoader } from "react-spinners";
import Images from '../../Images';
import { faSearch } from '@fortawesome/free-solid-svg-icons';


const BulkUpload = () => {
    const [state, setState] = useState([])
    const { user } = useContext(MainContext)
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(30);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState(false);
    const [openpopup, setopenpopup] = useState(false);
    const [openpopupDelete, setopenpopupDelete] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalCount, setTotalCount] = useState(0);


    useEffect(() => {
        getData()
    }, []);
    const getData = () => {
        setIsLoading(true);
        const token = JSON.parse(sessionStorage.getItem('data'));
        const url = token.user_role === "admin"
            ? `${apiURL}/all_mandapam_details1?start=0&end=${itemsPerPage - 1}`
            : `${apiURL}/all_mandapam_details1?user_id=${token.user_id}&start=0&end=${itemsPerPage - 1}`;

        Axios.get(url, {
            headers: {
                'Authorization': `Bearer ${token.access_token}`,
                'accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                setState(response.data.data);
                setTotalCount(response.data.total_count);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            });
    };


    const navigate = useNavigate()
    const closeModal = () => {

        setopenpopup(false);
    };
    const openModal = () => {
        setopenpopup(true);
    }

    const closeModalDelete = () => {

        setopenpopupDelete(false);
    };
    const openModalDelete = () => {
        setopenpopupDelete(true);
    }

    const columns = [
        {
            dataField: "mandapam_name",
            text: 'Place Name',
            sort: true
        },
        {
            dataField: 'place_type',
            text: 'Place Type',
        },
        {
            dataField: 'address',
            text: 'Address',
            style: {
                width: '400px'
            }
        },
        {
            dataField: 'dd',
            text: 'Partner',
            formatter: () => {
                return (
                    <p>Unknown</p>
                )
            }
        },
        {
            dataField: 'admin_name',
            text: 'Status',

            formatter: (cellContent, row) => {
                console.log();
                if (row.id % 2 === 0 && row.id % 3 === 0) {
                    return (
                        <>
                            <span class="badge badge-danger badge-sm">NOT Assigned</span>
                        </>
                    );
                } else if (row.id % 2 === 0) {
                    return (
                        <>
                            <span class="badge badge-success badge-sm"> Assigned</span>
                        </>
                    );
                } else if (row.id % 3 === 0) {
                    return (
                        <>
                            <span class="badge badge-danger badge-sm">In Assigned</span>
                        </>
                    );
                } else {
                    return (
                        <>
                            <span class="badge badge-success badge-sm"> Assigned</span>
                        </>
                    );
                }
            }

        },
        {
            dataField: 'trips',
            text: 'Actions',
            formatter: (cellContent, row) => {
                return (
                    <>
                        <a data-bs-toggle="tooltip" data-bs-original-title="Preview product">
                            <i className="text-secondary" aria-hidden="true" >
                                <FontAwesomeIcon icon={Icons.faEye} />
                            </i>
                        </a>
                        <a data-bs-toggle="tooltip" className='mx-3' data-bs-original-title="Edit Hall">
                            <i className="text-secondary" aria-hidden="true">
                                <FontAwesomeIcon icon={Icons.faPen} />
                            </i>
                        </a>
                        <a data-bs-toggle="tooltip" data-bs-original-title="Delete Hall"  >
                            <i className="text-secondary" aria-hidden="true">
                                <FontAwesomeIcon icon={Icons.faTrash} />
                            </i>
                        </a>
                    </>
                );
            }
        },

    ];
    const pageButtonRenderer = ({
        page,
        active,
        disable,
        title,
        onPageChange
    }) => {
        const handleClick = (e) => {
            e.preventDefault();
            onPageChange(page);
        };
        const activeStyle = {};
        if (active) {
            activeStyle.backgroundColor = '#cb0c9f';
            activeStyle.color = 'white';
        } else {
            activeStyle.backgroundColor = 'white';
            activeStyle.color = 'black';
        }
        if (typeof page === 'string') {
            activeStyle.backgroundColor = 'white';
            activeStyle.color = 'black';
        }
        return (
            <li className="page-item w-100 d-flex justify-content-center ">
                <button className="btn btn-circles " style={activeStyle}>{page}</button>
            </li>
        );
    };
    const MySearch = (props) => {
        let input;
        const handleClick = () => {
            props.onSearch(input.value);
        };
        return (
            <input
                className="form-control inputs w-75 me-5 mx-5"
                ref={n => input = n}
                type="text"
                placeholder="search"
                onChange={handleClick}
            />


        );
    };
    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={text}
            role="presentation"
            className="dropdown-item "

        >
            <a
                tabIndex="-1"
                role="menuitem"
                data-page={page}
                onMouseDown={(e) => {
                    e.preventDefault();
                    onSizePerPageChange(page);
                }}

            >
                {text}
            </a>
        </li>
    );

    console.log(state);

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ms-3" style={{ color: '8392AB', fontSize: '14px' }}>
            Showing {from} to {to} of {size} Results
        </span>
    );

    const options = {
        paginationSize: 4,
        pageStartIndex: 1,
        firstPageText: '<<',
        prePageText: '<',
        nextPageText: '>',
        lastPageText: '>>',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageOptionRenderer,
        //   pageButtonRenderer
    };
    const filteredData = state.filter((item) => {
        const itemValues = Object.values(item).join('').toLowerCase();
        return itemValues.includes(searchQuery.toLowerCase());
    });
    const handleClick = (page) => {
        setCurrentPage(page);

    };
    const pages = Math.ceil(filteredData.length / itemsPerPage);
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(pages, startPage + 4);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage - 1, filteredData.length - 1);
    const displayedItems = filteredData.slice(startIndex, endIndex + 1);

    const paginationButtons = [];
    for (let i = startPage; i <= endPage; i++) {
        paginationButtons.push(
            <li key={i} className={i === currentPage ? "active" : ""}>
                <a
                    key={i}
                    className='active'
                    onClick={() => handleClick(i)}
                    style={{ cursor: "pointer" }}
                >
                    {i}
                </a>
            </li>
        );
    }
    const handlePageChange = async (page) => {
        const project = JSON.parse(sessionStorage.getItem('data'));
        const start = (page - 1) * itemsPerPage;
        const end = start + itemsPerPage - 1;
        setIsLoading(true);
        try {
            const url = project.user_role === "admin"
                ? `${apiURL}/all_mandapam_details1?start=${start}&end=${end}`
                : `${apiURL}/all_mandapam_details1?user_id=${project.user_id}&start=${start}&end=${end}`;

            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${project.access_token}`,
                    "accept": "application/json",
                    "Content-Type": "application/json",
                }
            });
            setState(response.data.data);
            setCurrentPage(page);
            window.scrollTo(0, 0);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching paginated data:', error);
            window.scrollTo(0, 0);
            setIsLoading(false);
        }
    };

    const page = Math.ceil(totalCount / itemsPerPage);

    const renderPaginationButtons = () => {
        const buttons = [];
        const maxButtons = 5;
        let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
        let endPage = Math.min(page, startPage + maxButtons - 1);

        if (endPage - startPage < maxButtons - 1) {
            startPage = Math.max(1, endPage - maxButtons + 1);
        }

        if (startPage > 1) {
            buttons.push(
                <li key="first" className="page-item">
                    <a onClick={() => handlePageChange(1)} className="page-link">1</a>
                </li>
            );
            if (startPage > 2) {
                buttons.push(<li key="ellipsis-start" className="page-item disabled"><span className="page-link">...</span></li>);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
                    <a onClick={() => handlePageChange(i)} className="page-link">{i}</a>
                </li>
            );
        }

        if (endPage < page) {
            if (endPage < page - 1) {
                buttons.push(<li key="ellipsis-end" className="page-item disabled"><span className="page-link">...</span></li>);
            }
            buttons.push(
                <li key={page} className="page-item">
                    <a onClick={() => handlePageChange(page)} className="page-link">{page}</a>
                </li>
            );
        }

        return buttons;
    };

    const truncateText = (text, maxLength) => {
        if (text != null) {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            }
            return text;
        } else {
            return "";
        }

    };



    return (
        <div className={user ? "g-sidenav-show bg-gray-100 g-sidenav-hidden " : "g-sidenav-show  bg-gray-100"} >
            <Aside />
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <Navbar Name='Bulk Upload' />
                <div className="container-fluid py-4">
                    <div className="card">
                        <div class="card-header pb-0 mt-3">
                            <div class="d-lg-flex">
                                <div>
                                    <h5 class="mb-0 mt-2">Bulk Upload</h5>
                                    <p class="text-sm mb-0">
                                        {/* A lightweight, extendable, dependency-free javascript HTML table plugin. */}
                                    </p>
                                </div>
                                <div class="ms-auto my-auto mt-lg-0 mt-5">
                                    <div class="ms-auto my-auto">
                                        <ExcelExport />
                                        <button type="button" class="btn btn-outline-primary mx-2 btn-sm mb-0" data-bs-toggle="modal" data-bs-target="#import" onClick={() => openModal()}>
                                            Upload File
                                        </button>
                                        <div class="modal fade" id="import" tabindex="-1" aria-hidden="true">
                                            <div class="modal-dialog mt-lg-10">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="ModalLabel">Import CSV</h5>
                                                        <i class="fas fa-upload ms-3" aria-hidden="true"></i>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <p>You can browse your computer for a file.</p>
                                                        <input type="text" placeholder="Browse file..." class="form-control mb-3" onfocus="focused(this)" onfocusout="defocused(this)" />
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="" id="importCheck" checked="" />
                                                            <label class="custom-control-label" for="importCheck">I accept the terms and conditions</label>
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn bg-gradient-secondary btn-sm" data-bs-dismiss="modal">Close</button>
                                                        <button type="button" class="btn bg-gradient-primary btn-sm">Upload</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body px-0 pb-0">
                            <div className=''>
                                <div className="row mt-4">
                                    <div className="col-12">
                                        <div className="mb-4">
                                            <div className='container'>
                                                <div className="dataTable-search d-flex justify-content-end">
                                                    <input
                                                        className="dataTable-inputs"
                                                        placeholder="Search..."
                                                        type="text"
                                                        value={searchQuery}
                                                        onChange={(event) => setSearchQuery(event.target.value)}
                                                    />
                                                    <button type="submit" className='search_button_color' >
                                                        <FontAwesomeIcon className="mb-0 text-white text-end me-1" icon={faSearch} style={{ cursor: "pointer" }} />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="card-body p-3">
                                                <div className="row pb-3">
                                                    {isLoading ? (
                                                        <div className="d-flex justify-content-center my-5 pt-5">
                                                            <div className='pt-5 mt-5'>
                                                                <BeatLoader className='mt-5' />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        filteredData.map((item) => (
                                                            <div className='col-lg-4 col-sm-6 my-2' key={item.id}>
                                                                <div className="card px-4 pt-4">
                                                                    {item.images && item.images.length > 0 && item.images[0].image ? (
                                                                        <img src={`data:image/jpeg;base64,${item.images[0].image}`} height={220} alt="Venue Image" />
                                                                    ) : (
                                                                        <img src={Images.mahall} height={220} alt='' />
                                                                    )}
                                                                    <h6 className='mt-2'>{item.mandapam_name}</h6>
                                                                    <p className="price" style={{ fontSize: "18px" }}>{truncateText(item.address, 40)}</p>
                                                                    <p>
                                                                        <button type="button" className="btn btn-outline-primary btn-sm mb-4" onClick={() => { navigate('/Editpage', { state: { id: item.id, flag: "upload" } }); }}>
                                                                            View Details
                                                                        </button>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ))
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center my-3">
                                            <ul className="pagination">
                                                {renderPaginationButtons()}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={openpopup} size='lg' className='model-popup-design' >
                    <div className='d-flex justify-content-between mt-3' >
                        <Modal.Title className='ms-4 '>Bulk Upload</Modal.Title>
                        <button className='me-3 btn btn-outline-secondary  ' onClick={closeModal}>
                            x
                        </button>
                    </div>
                    <div className='loginmodalbody' style={{ width: '785px' }}>
                        <Modal.Body className='testing-test' >
                            <FileUpload closeModal={closeModal} />
                        </Modal.Body>
                    </div>
                </Modal>
                <Modal show={openpopupDelete} size='md' className='model-popup-design' >
                    <div className='d-flex justify-content-between mt-3' >
                        <Modal.Title className='ms-4 '>Delete</Modal.Title>
                        <button className='me-3 btn btn-outline-secondary  ' onClick={closeModalDelete}>
                            x
                        </button>
                    </div>
                    <div className='loginmodalbody' style={{ width: '785px' }}>
                        <Modal.Body className='testing-test' >
                            Are you sure you want to delete this item ?
                        </Modal.Body>
                    </div>
                    <Modal.Footer>
                        <Button variant="danger" onClick={closeModalDelete}>
                            Delete
                        </Button>
                        <Button variant="secondary" onClick={closeModalDelete}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Footer />
            </main>
        </div>
    )
}

export default BulkUpload
