import React, { useState, useEffect, useContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Axios from 'axios';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { apiURL } from '../../Layout/Common/confiqurl';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Button, Carousel, Modal } from 'react-bootstrap';
import Aside from '../../Layout/Aside'
import Navbar from '../../Layout/Navbar'
import Footer from '../../Layout/Footer'
import { MainContext } from '../../Store/Context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icons } from '../../Icons/IconIndex';
import Images from '../../Images';
import axios from 'axios';
import AddNew from './AddNew';


const EditAdminpage = () => {
    const { user } = useContext(MainContext)
    const navigate = useNavigate()
    const [popup, setPopup] = useState(false)
    const [openpopup, setopenpopup] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageId, setSelectedImageId] = useState(null);
    const [addTextBox, setAddTextBox] = useState([{ FirstName: "", image: "" }]);
    const [imageType, setImageType] = useState([]);
    const [foodType, setFoodType] = useState([])
    const [placeType, setPlaceType] = useState([])

    const [editData, setEditData] = useState({})
    const [isToastOpen, setIsToastOpen] = useState(false);

    const location = useLocation();
    const data = location.state;


    const exceptThisSymbols = ["e", "E", "+", "-", "."];

    const [state, setState] = useState({
        // mandap_link: "",
        mandapam_name: "",
        address: "",
        hallseatingcapacity: "",
        hallfloatingcapacity: "",
        foodtype: "",
        outsidedecorationallowed: "",
        outsideentertainment: "",
        about: "",
        valetparking: "",
        outdoor_catering_allowed: "",
        nonveg_cooking: "",
        nonveg_service_allowed: "",
        roomsnonac: "",
        roomsac: "",
        startingpriceperday: "",
        carparking: "",
        bikeparking: "",
        mobile_number: "",
        contact_name: "",
        place_type: "",
        bikeparking_count: "",
        carparking_count: "",
    })
    


    const [nonveg_service_allowed, setnonveg_service_allowed] = useState()
    const [nonveg_cooking, setnonveg_cooking] = useState(false)
    const [outdoor_catering_allowed, setoutdoor_catering_allowed] = useState(false)
    const [valetparking, setvaletparking] = useState(false)
    const [carparking, setcarparking] = useState()
    const [bikeparking, setbikeparking] = useState(false)
    const [outsidedecorationallowed, setoutsidedecorationallowed] = useState(false)
    const [outsideentertainment, setoutsideentertainment] = useState(false)
    const [fullhall_ac, setFullhall_ac] = useState(false)

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
    };
    const closeModal = () => {

        setopenpopup(false);
    };
    const openModal = (image, image_id) => {
        setSelectedImage(image);
        setSelectedImageId(image_id);
        setopenpopup(true);
    }


    const uploadOpenPopUp = () => {
        setPopup(true)

    }
    const uploadClosePopUp = () => {
        setPopup(false)

    }





    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
        const value = event.target.value;
        if (/^\d{0,10}$/.test(value)) { // Limit input to 10 numbers
            setInputValue(value);
        }
    };



    const [usernamevalue, setUsernamevalue] = useState('');

    const handleInputNameChange = (event) => {
        const capitalizedValue = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        setUsernamevalue(capitalizedValue);
    }




    useEffect(() => {

        getPlaceType()
        getData()
        getFoodType()
        getEditData()
    }, []);
    const getEditData = () => {
        const token = JSON.parse(sessionStorage.getItem('data'));
        Axios.get(`${apiURL}/mandapam_details_by_id?Mandapam_id=${data}`, {
            // Axios.get(`${apiURL}/mandapam_details_by_id?Mandapam_id=12`, {
            headers: {
                'Authorization': `Bearer ${token.access_token}`,

                "accept": "application/json",
                "Content-Type": " application/json",
            }
        },)
            .then((response) => {
                setState(response.data)
                setnonveg_service_allowed(response.data.nonveg_service_allowed)
                setcarparking(response.data.carparking)
                setoutsideentertainment(response.data.outsideentertainment)
                setoutsidedecorationallowed(response.data.outsidedecorationallowed)
                setbikeparking(response.data.bikeparking)
                setvaletparking(response.data.valetparking)
                setoutdoor_catering_allowed(response.data.outdoor_catering_allowed)
                setnonveg_cooking(response.data.nonveg_cooking)
                setFullhall_ac(response.data.fullhall_ac)
                setInputValue(response.data.mobile_number)
                setUsernamevalue(response.data.contact_name)

            })
    }


    const getData = () => {
        const token = JSON.parse(sessionStorage.getItem('data'));
        Axios.get(`${apiURL}/get_ImageType`, {
            headers: {
                'Authorization': `Bearer ${token.access_token}`,

                "accept": "application/json",
                "Content-Type": " application/json",
            }
        },)
            .then((response) => {
                setImageType(response.data)
            })
    }



    const getFoodType = () => {
        const token = JSON.parse(sessionStorage.getItem('data'));
        Axios.get(`${apiURL}/get_foodType`, {
            headers: {
                'Authorization': `Bearer ${token.access_token}`,

                "accept": "application/json",
                "Content-Type": " application/json",
            }
        },)
            .then((response) => {
                setFoodType(response.data)
            })
    }
    const getPlaceType = () => {
        const token = JSON.parse(sessionStorage.getItem('data'));
 
        Axios.get(`${apiURL}/get_Venue_Type`, {
  
            headers: {
                'Authorization': `Bearer ${token.access_token}`,

                "accept": "application/json",
                "Content-Type": " application/json",
            }
        },)
            .then((response) => {
                setPlaceType(response.data.place_types);
            })
    }

    const handleEdit = () => {

        const payload = {
            mandapam_name: state.mandapam_name,
            address: state.address,
            hallseatingcapacity: state.hallseatingcapacity,
            hallfloatingcapacity: state.hallfloatingcapacity,
            foodtype: state.foodtype,
            outsidedecorationallowed: outsidedecorationallowed,
            outsideentertainment: outsideentertainment,
            about: state.about,
            valetparking: valetparking,
            outdoor_catering_allowed: outdoor_catering_allowed,
            nonveg_cooking: nonveg_cooking,
            nonveg_service_allowed: nonveg_service_allowed,
            roomsnonac: state.roomsnonac,
            roomsac: state.roomsac,
            startingpriceperday: state.startingpriceperday,
            carparking: carparking,
            bikeparking: bikeparking,
            carparking_count: state.carparking_count,
            bikeparking_count: state.bikeparking_count,
            place_type: state.place_type,
            mobile_number: inputValue,
            contact_name: usernamevalue,
            fullhall_ac: fullhall_ac,
        }

        const project = JSON.parse(sessionStorage.getItem('data'));
        const mandapam_id = sessionStorage.getItem('mandapam_id')
        if (!isToastOpen) {
            setIsToastOpen(true);
            if (state.mandapam_name.trim()) {

                if (state.place_type.trim()) {



                    if (state.address) {



                        if (state.startingpriceperday) {
                            if (inputValue) {

                                if (usernamevalue) {
                                    if (state.about.trim()) {




                                        if (state.roomsac) {

                                            if (state.roomsnonac) {

                                                if (state.hallseatingcapacity) {

                                                    if (state.hallfloatingcapacity) {
                                                        if (state.carparking_count) {
                                                            if (state.bikeparking_count) {



                                                                Axios.put(`${apiURL}/edit_details/?id=${data}`, payload, {
                                                                    headers: {
                                                                        "accept": "application/json",
                                                                        "Content-Type": " application/json",
                                                                        'Authorization': `Bearer ${project.access_token}`,

                                                                    }
                                                                },
                                                                ).then((response) => {
                                                                    toast.success('Account Created Successfully')


                                                                    setTimeout(() => {
                                                                        navigate("/AllPlaces")
                                                                    }, 2000);
                                                                })
                                                                    .catch((err) => {
                                                                        {
                                                                            toast.error("Invalid User")
                                                                            // setIsLoading(false);
                                                                        }
                                                                    });
                                                            } else {
                                                                toast.error('Please Enter No Of Bike')

                                                            }
                                                        } else {
                                                            toast.error('Please Enter No Of Car');

                                                        }
                                                    } else {
                                                        toast.error('please Enter Hall Floating Nos');

                                                    }
                                                } else {
                                                    toast.error('please Enter Hall Seating Nos');

                                                }
                                            } else {
                                                toast.error('Please Enter No Of Rooms')

                                            }
                                        } else {
                                            toast.error('Please Enter No Of Air Conditioned Rooms')

                                        }





                                    } else {

                                        toast.error('Please Enter Description ')


                                    }
                                }
                                else {

                                    toast.error('Please Enter Contact Name')

                                }
                            } else {

                                toast.error('Please Enter Mobile Number ')


                            }
                        } else {

                            toast.error('Please Enter Fee Per Day');

                        }
                    } else {

                        toast.error('Please Enter Address');

                    }

                }

                else {

                    toast.error('Select a Place Type')

                }
            }

            else {

                toast.error('Please Enter Place Name')

            }




            setTimeout(() => {
                setIsToastOpen(false);
            }, 3000); // set the timeout based on how long the toast message will be displayed
        }



    }
    const handleDeleteImage = (e) => {

        const DeleteValue = e.target.value
        const project = JSON.parse(sessionStorage.getItem('data'));

        axios.delete(`${apiURL}/image_details?id=${DeleteValue}`, {
            headers: {
                'Authorization': `Bearer ${project.access_token}`,

                "accept": "application/json",
                "Content-Type": " application/json",
            }

        },)
            .then((response) => {
                toast.success("Image Deleted Sucessfully")
                getEditData()
                closeModal()
            })

    }


    return (
        <div className={user ? "g-sidenav-show bg-gray-100 g-sidenav-hidden " : "g-sidenav-show  bg-gray-100"} >
            <Aside />
            <Toaster toastOptions={{ position: "top-right" }} />

            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <Navbar Name='EditPage' path='/AllPlaces' />
                <div className="container-fluid py-4">
                    <div class="row mt-4">
                        <div class="col-lg-12">
                            <div class="card h-100">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center mb-3">
                                        <h5 class="font-weight-bolder">Images</h5>
                                        <button type="button" class="btn btn-outline-primary mx-2 btn-sm mb-0" data-bs-toggle="modal" style={{ wordSpacing: "0.5em" }} onClick={() => { uploadOpenPopUp() }}>
                                            + Add Images & Videos
                                        </button>
                                    </div>
                                    <div class="row">
                                        <Carousel>
                                            {state.images && state.images.length >= 1 ? (
                                                state.images.map((image, index) => (
                                                    (image.image && image.image !== '') ? (
                                                        <Carousel.Item key={index} onClick={() => { openModal(image, image.image_id) }}>
                                                            <img src={`data:image/jpeg;base64,${image.image}`} style={{ height: "350px", objectFit: "cover" }} />
                                                            <Carousel.Caption></Carousel.Caption>
                                                        </Carousel.Item>
                                                    ) : null
                                                ))
                                            ) : (
                                                <img src={Images.madabam02} height={350} alt='' />
                                            )}
                                        </Carousel>
                                        {/* {state.images && (state.images.length == 1 && state.images[0].image == '') ?
                                            (<Carousel onClick={() => { openModal() }}>
                                                <Carousel.Item>
                                                    <img src={Images.madabam02} height={350} alt='' />
                                                    <Carousel.Caption></Carousel.Caption>
                                                </Carousel.Item>
                                            </Carousel>
                                            ) : ""
                                        } */}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mt-4">
                            <div class="card h-100">
                                <div class="card-body">
                                    <h5 class="font-weight-bolder">Images</h5>
                                    <div class="row">


                                        <Carousel onClick={() => {}}>
                                            {state.videos && state.videos.length >= 1 && state.videos.map((video) =>
                                                (video.video && video.video !== '') ? (
                                                    <Carousel.Item key={video.id} style={{ height: "350px", objectFit: "cover" }}>
                                                        <video controls  >
                                                            <source type="video/mp4" src={`data:video/mp4;base64,${video.video}`} />
                                                           
                                                            Your browser does not support the video tag.
                                                        </video>
                                                        <Carousel.Caption></Carousel.Caption>
                                                    </Carousel.Item>
                                                ) : null
                                            )}
                                        </Carousel>

                                        {/* {state.images && (state.images.length == 1 && state.images[0].image == '') ?
                                            (<Carousel onClick={() => { openModal() }}>
                                                <Carousel.Item>
                                                    <img src={Images.madabam02} height={350} alt='' />
                                                    <Carousel.Caption></Carousel.Caption>
                                                </Carousel.Item>
                                            </Carousel>
                                            ) : ""
                                        } */}


                                        {/* <div class="col-12 mt-5">
                                            <div class="d-flex d-flex justify-content-end">
                                            </div>
                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 mt-4">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="font-weight-bolder mt-2">Basic</h5>
                                    <div class="row mt-4 mb-4">
                                        <div class="col-12 col-sm-6">
                                            <label>Venue Name<span style={{ color: 'red' }}>*</span> </label>
                                            <input class="form-control" type="text" name='mandapam_name' value={state.mandapam_name} onChange={handleChange} />
                                        </div>
                                        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                                            <label>Venue Type<span style={{ color: 'red' }}>*</span> </label>
                                            <select id="inputState" class="form-select " name='place_type' value={state.venue_type} onChange={handleChange}  >
                                                <option value="" disabled selected hidden>
                                                    Select an option
                                                </option>
                                                {/* {
                                                    placeType.map((data) => {
                                                        return (
                                                            <option > {data.data_value}</option>

                                                        )
                                                    })
                                                } */}
                                                 {
                    placeType.map((data, index) => (
                        <option key={index} value={data}>{data}</option>
                    ))
                }
                                            </select>
                                        </div>

                                        <div class="col-12 col-sm-6 mt-4">
                                            <label>Address<span style={{ color: 'red' }}>*</span></label>
                                            <input class="form-control" type="text" name='address' value={state.address} onChange={handleChange} />
                                        </div>

                                        <div class="col-12 col-sm-6 mt-3 ">
                                            <label className='mt-2'>Fee per day<span style={{ color: 'red' }}>*</span></label>
                                            <input class="form-control" type="number" name='startingpriceperday' value={state.startingpriceperday} onChange={handleChange} />
                                        </div>
                                        <div className='col-12 col-sm-6 mt-3' >
                                            <label>Mobile Number <span style={{ color: 'red' }}>*</span></label>
                                            <input class="form-control" type="text" name='mobile_number' onKeyDown={(e) =>
                                                exceptThisSymbols.includes(e.key) && e.preventDefault()} value={inputValue} onChange={handleInputChange} />
                                        </div>
                                        <div className='col-12 col-sm-6 mt-3' >
                                            <label>Contact Name<span style={{ color: 'red' }}>*</span></label>
                                            <input class="form-control" type="text" name='contact_name'
                                                value={usernamevalue} onChange={handleInputNameChange} />
                                        </div>

                                        <div class=" col-sm-12 mt-4">
                                            <label>Description<span style={{ color: 'red' }}>*</span></label>
                                            <textarea class="form-control" type="text" name='about' value={state.about} onChange={handleChange} style={{
                                                height: "110px"
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-sm-4">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="font-weight-bolder">Food & Service</h5>
                                    <label>Food Type<span style={{ color: 'red' }}>*</span> </label>
                                    <select id="inputState" class="form-select " name='foodtype' value={state.foodtype} onChange={handleChange}   >
                                        <option value="" disabled selected hidden>
                                            Select an option
                                        </option>
                                        {
                                            foodType.map((data) => {

                                                return (
                                                    <option > {data.data_value}</option>

                                                )
                                            })
                                        }
                                    </select>
                                    <div className=''>
                                        <label for="rememberMe"   >Outdoor Catering Allowed </label>
                                    </div>
                                    <div className=''>
                                        <div class="form-check form-switch pt-1">
                                            <input class="form-check-input" type="checkbox" checked={outdoor_catering_allowed} id="rememberMe" onClick={() => { setoutdoor_catering_allowed(!outdoor_catering_allowed) }} />
                                        </div>
                                    </div>
                                    <div className=''>
                                        <label for="rememberMe" >Non-veg Cooking Allowed </label>
                                    </div>
                                    <div className=''>
                                        <div class="form-check form-switch pt-1">
                                            <input class="form-check-input" type="checkbox" checked={nonveg_cooking} id="rememberMe" onClick={() => { setnonveg_cooking(!nonveg_cooking) }} />
                                        </div>
                                    </div>
                                    <div className=''>
                                        <label >Non-veg Service Allowed </label>
                                    </div>
                                    <div className=''>
                                        <div class="form-check form-switch pt-1">
                                            <input class="form-check-input" type="checkbox" name='nonveg_service_allowed' checked={nonveg_service_allowed} onClick={() => { setnonveg_service_allowed(!nonveg_service_allowed) }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-8 mt-sm-0 mt-4">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="font-weight-bolder">Room & Hall</h5>
                                    <div class="row">
                                        <div class="col-12 col-sm-6">
                                            <label>No Of Air Conditioned Rooms<span style={{ color: 'red' }}>*</span></label>
                                            <input class="form-control" type="Number" name='roomsac' onKeyDown={(e) =>
                                                exceptThisSymbols.includes(e.key) && e.preventDefault()} value={state.roomsac} onChange={handleChange} />
                                        </div>
                                        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                                            <label>No Of Rooms<span style={{ color: 'red' }}>*</span>	</label>
                                            <input class="form-control" type="Number" name='roomsnonac' onKeyDown={(e) =>
                                                exceptThisSymbols.includes(e.key) && e.preventDefault()} value={state.roomsnonac} onChange={handleChange} />
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <label>Hall Seating Nos<span style={{ color: 'red' }}>*</span> </label>
                                            <input class="form-control" type="number" name='hallseatingcapacity' onKeyDown={(e) =>
                                                exceptThisSymbols.includes(e.key) && e.preventDefault()} value={state.hallseatingcapacity} onChange={handleChange} />
                                        </div>
                                        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                                            <label>Hall Floating Nos<span style={{ color: 'red' }}>*</span></label>
                                            <input class="form-control" type="number" name='hallfloatingcapacity' onKeyDown={(e) =>
                                                exceptThisSymbols.includes(e.key) && e.preventDefault()} value={state.hallfloatingcapacity} onChange={handleChange} />
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <label for="rememberMe">Full hall AC</label>
                                            <div class="form-check form-switch pt-1">
                                                <input class="form-check-input" type="checkbox" onClick={() => setFullhall_ac(!fullhall_ac)} name='fullhall_ac' checked={fullhall_ac} id="rememberMe" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card w-100 mt-4">
                        <div class="card-body">
                            <h5 class="font-weight-bolder">Parking</h5>
                            <div className='row'>
                                <div className='col-6' >
                                    <div className=' d-flex justify-content-start mt-4 pt-2  ' >
                                        <div className='ms-3 w-50' >
                                            <label>Car Parking </label>
                                        </div>
                                        <div className='ms-4'>
                                            <div class="form-check form-switch pt-1 ms-3">
                                                <input class="form-check-input" type="checkbox" name='carparking' checked={carparking} id="rememberMe" onClick={() => setcarparking(!carparking)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=' d-flex justify-content-start mt-4 pt-2  ' >
                                        <div className='ms-3 w-50'>
                                            <label>Bike Parking </label>
                                        </div>
                                        <div className='ms-4'>
                                            <div class="form-check form-switch pt-1 ms-3">
                                                <input class="form-check-input" type="checkbox" name='bikeparking' checked={bikeparking} id="rememberMe" onClick={() => { setbikeparking(!bikeparking) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=' d-flex justify-content-start mt-4 pt-2  ' >
                                        <div className='ms-3 w-50'>
                                            <label>Valet Parking 	 </label>
                                        </div>
                                        <div className='ms-4'>
                                            <div class="form-check form-switch pt-1 ms-3">
                                                <input class="form-check-input" type="checkbox" id="rememberMe" name='valetparking' checked={valetparking} onClick={() => { setvaletparking(!valetparking) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=' d-flex justify-content-start mt-4 pt-2  ' >
                                        <div className='ms-3 w-50'>
                                            <label>Outside Vendor Allowed </label>
                                        </div>
                                        <div className='ms-4'>
                                            <div class="form-check form-switch pt-1 ms-3">
                                                <input class="form-check-input" type="checkbox" id="rememberMe" name='outsidedecorationallowed' checked={outsidedecorationallowed} onClick={() => { setoutsidedecorationallowed(!outsidedecorationallowed) }} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className=' d-flex justify-content-start mt-4 pt-2  ' >
                                        <div className='ms-3 w-50'>
                                            <label>Outside Entertainment Allowed</label>
                                        </div>
                                        <div className='ms-4'>
                                            <div class="form-check form-switch pt-1 ms-3">
                                                <input class="form-check-input" type="checkbox" id="rememberMe" name='outsideentertainment' checked={outsideentertainment} onClick={() => { setoutsideentertainment(!outsideentertainment) }} />
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className='col-6' >
                                    <div className='mt-1' >
                                        <label>No .of cars<span style={{ color: 'red' }}>*</span></label>
                                        <input class="form-control" type="text" name='carparking_count' onKeyDown={(e) =>
                                            exceptThisSymbols.includes(e.key) && e.preventDefault()} value={state.carparking_count} onChange={handleChange} />

                                    </div>
                                    <div className=' mt-4' >
                                        <label>No .of Bike <span style={{ color: 'red' }}>*</span></label>
                                        <input class="form-control" type="text" name='bikeparking_count' onKeyDown={(e) =>
                                            exceptThisSymbols.includes(e.key) && e.preventDefault()} value={state.bikeparking_count} onChange={handleChange} />

                                    </div>
                                    {/* <div className='mt-4' >
                                        <label>Valet fee	</label>
                                        <input class="form-control" type="text" name='valetFee'  onChange={handleChange} />

                                    </div> */}
                                </div>
                            </div>
                            <button className="btn bg-gradient-white btn-sm  mt-6 mb-0" onClick={() => navigate('/AllPlaces')}>Back</button>

                            <button className="btn bg-gradient-dark btn-sm float-end mt-6 mb-0" onClick={handleEdit}>save</button>

                        </div>
                    </div>
                    <Modal show={openpopup} size='md' className='model-popup-design' >

                        <div className='d-flex justify-content-between mt-3' >
                            <Modal.Title className='ms-4 '>Images</Modal.Title>
                            <button className='me-3 btn btn-outline-secondary  ' onClick={closeModal}>
                                x
                            </button>
                        </div>
                        <Modal.Body>
                            <p>Hello</p>
                            {selectedImage && (
                                <img
                                    src={`data:image/jpeg;base64,${selectedImage.image}`}
                                    style={{ height: "350px", width: "100%", objectFit: "cover" }}
                                    alt='Selected Image'
                                />
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" style={{
                                backgroundImage: 'linear-gradient(310deg, #7928ca, #ff0080)',
                                color: 'white',
                                border: 'none',
                            }}
                                value={selectedImageId}
                                onClick={(e) => { handleDeleteImage(e) }}
                            >
                                Remove
                            </Button>

                            <Button variant="secondary" style={{
                                backgroundColor: 'white',
                                color: '#cb0c9f',
                                border: '1px solid #cb0c9f',
                            }} onClick={closeModal}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>



                    <Modal show={popup} size='lg' className='model-popup-design' >
                        <div className='d-flex justify-content-between mt-3' >
                            <Modal.Title className='ms-4 '>Upload</Modal.Title>
                            <button className='me-3 btn btn-outline-secondary  ' onClick={uploadClosePopUp}>
                                x
                            </button>
                        </div>
                        <div className='loginmodalbody' >
                            <Modal.Body className='testing-test' >

                                <AddNew
                                    closeModal={closeModal}
                                    uploadClosePopUp={uploadClosePopUp}
                                    data={data} getEditData={getEditData} />

                            </Modal.Body>
                        </div>




                    </Modal>

                </div>
                <Footer />
            </main >
        </div >
    )
}

export default EditAdminpage