import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { Icons } from '../../../../Icons/IconIndex';
import { useEffect } from 'react';
import axios from 'axios';
import { apiURL } from '../../../../Layout/Common/confiqurl';

const Image = (props) => {
  const { addTextBox, addboxt, onchangename, onclickRemove, next, back, uploadImage } = props;

  // const [addTextBox, setAddTextBox] = useState([{ FirstName: "" ,image:"" }]);
  const [state, setState] = useState([])

  useEffect(() => {


    getData()
  }, []);
  const getData = () => {
    const token = JSON.parse(sessionStorage.getItem('data'));
    axios.get(`${apiURL}/get_ImageType`, {
      headers: {
        'Authorization': `Bearer ${token.access_token}`,

        "accept": "application/json",
        "Content-Type": " application/json",
      }
    },)
      .then((response) => {
        // console.log(response.data);
        setState(response.data)
      })
  }

  console.log(addTextBox, "addTextBox");
  return (
    <div>

      <div class="row">
        <div class="col-12 col-lg-8 mx-auto  w-100">





          <div class="card w-100">
            <div class="card-body">
              <h5 class="font-weight-bolder">Images</h5>



              {addTextBox && addTextBox.map((item, key) => (

                <div className='row'>
                  <div className='col-5' >
                    <div >
                      <label> Image Type</label>
                      <select id="inputState" class="form-select " value={addTextBox[key].FirstName} onChange={(e) => onchangename(e, key)} name='FirstName' style={{ cursor: "pointer" }} >
                        <option value="" disabled selected hidden>
                          Select an option
                        </option>

                        {
                          state.map((data) => {

                            return (
                              <option style={{ cursor: "pointer" }}> {data.data_value}</option>

                            )
                          })
                        }
                      </select>
                    </div>
                  </div>
                  <div className='col-5 ' >
                    <div className=''>
                      <label>Image </label>
                      <input class="form-control" type="file" accept="image/*" onChange={(e) => uploadImage(e, key)} name='image' />
                    </div>

                  </div>
                  <div className='col-1 d-flex ' >
                    <div className='pt-2  mt-4'>
                      <button class="btn bg-gradient-primary    " type="button" name="button" onClick={addboxt}><i class="" ><FontAwesomeIcon icon={Icons.faPlusCircle} /></i></button>

                    </div>
                    <div className='pt-2 ms-3 mt-4'>{addTextBox.length > 1 && (
                      <button type="button" class="btn btn-outline-dark " onClick={onclickRemove}><i class="" ><FontAwesomeIcon icon={Icons.faTrash} /></i></button>
                    )}
                    </div>
                  </div>
                </div>
              ))}

              <div className='row mt-5'>
                {/* <div className="col-6 ">
                  <button
                    onClick={back}
                    className="btn bg-gradient-secondary mb-0 js-btn-prev"
                  >
                    back
                  </button>
                </div> */}
                <div className="col-6 ">
                  <button
                    onClick={next}
                    className="btn bg-gradient-dark ms-auto mb-0 js-btn-next  d-flex justify-content-end"
                  >
                    Next
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Image
