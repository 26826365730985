import Aside from '../../Layout/Aside'
import Navbar from '../../Layout/Navbar'
import Footer from '../../Layout/Footer'
import "./Style.css";
import React, { useContext, useState, useEffect } from "react";
import { Stepper, Step } from "react-form-stepper";
import Basic from './Components/Basic';
import Contact from './Components/Contact/Contact';
import Image from './Components/Image/Image';
import RoomHall from './Components/Rooms&Hall';
import FoodService from './Components/FoodService/Index';
import Parking from './Components/Parking';
import { MainContext } from '../../Store/Context';
import Axios from 'axios';
import { apiURL } from '../../Layout/Common/confiqurl';
import { Toaster, toast } from 'react-hot-toast';
import Viedo from './Viedo';

const AddNew = () => {
  const { user } = useContext(MainContext)
  const [activeStep, setActiveStep] = useState(1);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [formData, setFormData] = useState({
    mandapam_name: "",
    about: "",
    startingpriceperday: "",
    place_type: "",
    mobile_number: "",
    contactName: "",
    mandap_link: "",
    address: "",
    foodtype: "",
    hallseatingcapacity: "",
    hallfloatingcapacity: "",
    carparking_count: "0",
    bikeparking_count: "0",
    roomsnonac: "",
    roomsac: "",
    valetparking: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  const [nonveg_service_allowed, setnonveg_service_allowed] = useState(false)
  const [nonveg_cooking, setnonveg_cooking] = useState(false)
  const [outdoor_catering_allowed, setoutdoor_catering_allowed] = useState(false)
  const [valetparking, setvaletparking] = useState(false)
  const [carparking, setcarparking] = useState(false)
  const [bikeparking, setbikeparking] = useState(false)
  const [outsidedecorationallowed, setoutsidedecorationallowed] = useState(false)
  const [outsideentertainment, setoutsideentertainment] = useState(false)
  const [fullhall_ac, setFullhall_ac] = useState(false)
  const [addTextBox, setAddTextBox] = useState([{ FirstName: "", image: "" }]);
  const [video, setVideo] = useState([{ videoType: "", video: "" }]);
  const [image, setImage] = useState([])
  const [imageType, setImageType] = useState([])
  const [videoStore, setVideoStore] = useState([])
  const [videoType, setVideoType] = useState([])
  const [inputValue, setInputValue] = useState('');
  const [inputName, setInputName] = useState('');

  const handleInputNameChange = (event) => {
    const capitalizedValue = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
    // const alphabetsOnly = capitalizedValue.replace(/[^A-Za-z]/g, '');
    const alphabetsAndSpacesOnly = capitalizedValue.replace(/[^A-Za-z\s]/g, '');
    setInputName(alphabetsAndSpacesOnly);
  }

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1),
    });
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (/^\d{0,10}$/.test(value)) { // Limit input to 10 numbers
      setInputValue(value);
    }
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const VideoRemove = (e, index) => {
    const List = [...video];
    List.splice(index, 1);
    setVideo(List)
  }

  const ViedoAdd = () => {
    let tempAddTextBox = video;
    let addTextBoxRow = { videoType: "", video: "" };
    tempAddTextBox.push(addTextBoxRow);
    setVideo([...tempAddTextBox]);
  }


  const onclickRemove = (e, index) => {
    const List = [...addTextBox];
    List.splice(index, 1);
    setAddTextBox(List)
  }

  const addboxt = () => {
    let tempAddTextBox = addTextBox;
    let addTextBoxRow = { FirstName: "", image: "" };
    tempAddTextBox.push(addTextBoxRow);
    setAddTextBox([...tempAddTextBox]);
  }
  const onchangename = (e, key) => {
    const { name, value } = e.target;
    let data = addTextBox;
    data[key][name] = e.target.value
    setAddTextBox([...data]);
  }



  const uploadImage = (e, index) => {
    let file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        let binaryString = e.target.result;
        setAddTextBox((prevState) => {
          let newState = [...prevState];
          newState[index].image = btoa(binaryString);
          return newState;
        });
      };
      reader.readAsBinaryString(file);
    }
  };

  const onchangeVideotype = (e, key) => {
    const { name, value } = e.target;
    let data = video;
    data[key][name] = e.target.value
    setVideo([...data]);
  }

  const handleFileChange = (event, index) => {
    const fileViedo = event.target.files[0];
    if (fileViedo) {
      const reader = new FileReader();
      reader.readAsDataURL(fileViedo);
      reader.onload = () => {
        setVideo((prevState) => {
          let newState = [...prevState];
          newState[index].video = reader.result;
          return newState;
        })
      };
    }
  };



  const handleSubmit = () => {
    var mandapam_id = sessionStorage.getItem("mandapam_id")

    if (!isToastOpen) {
      setIsToastOpen(true);
      const project = JSON.parse(sessionStorage.getItem('data'));
      const payload = {
        mandapam_name: formData.mandapam_name,
        address: formData.address,
        hallseatingcapacity: formData.hallseatingcapacity,
        hallfloatingcapacity: formData.hallfloatingcapacity,
        foodtype: formData.foodtype,
        outsidedecorationallowed: outsidedecorationallowed,
        outsideentertainment: outsideentertainment,
        about: formData.about,
        valetparking: valetparking,
        outdoor_catering_allowed: outdoor_catering_allowed,
        nonveg_cooking: nonveg_cooking,
        nonveg_service_allowed: nonveg_service_allowed,
        roomsnonac: formData.roomsnonac,
        roomsac: formData.roomsac,
        startingpriceperday: formData.startingpriceperday,
        carparking: carparking,
        bikeparking: bikeparking,
        place_type: formData.place_type,
        mobile_number: inputValue,
        contact_name: inputName,
        carparking_count: formData.carparking_count,
        bikeparking_count: formData.bikeparking_count,
        fullhall_ac: fullhall_ac
      }

      Axios.post(`${apiURL}/new_details?user_id=${project.user_id}`, payload, {
        headers: {
          'Authorization': `Bearer ${project.access_token}`,
          "accept": "application/json",
          "Content-Type": " application/json"
        }
      },
      ).then((response) => {
        handleNext()
        sessionStorage.setItem("mandapam_id", response.data.Mandapam_id)
      })
        .catch((err) => {
          {
            toast.error("Invalid User")
          }
        });
      setTimeout(() => {
        setIsToastOpen(false);
      }, 3000); // set the timeout based on how long the toast message will be displayed
    }
  }


  const stepStyleConfig = {
    activeBgColor: "white",
    activeTextColor: "white",
    completedBgColor: "#db23bd",
    inactiveBgColor: "white",
    size: "1.5rem"
  };

  const handleImage_VideoClick = () => {
    if (!isToastOpen) {
      setIsToastOpen(true);
      const imageurl = []
      const imageUrlType = []
      addTextBox.map((value) => {
        setImage([...image, value.image])
        setImageType([...imageType, value.FirstName])
        imageurl.push(value.image)
        imageUrlType.push(value.FirstName)
      })
      const videoArray = []
      const VideoTypeArray = []
      video.map((value) => {
        videoArray.push(value.video)
        VideoTypeArray.push(value.videoType)
      })
      const project = JSON.parse(sessionStorage.getItem('data'));
      const mandapam_id = sessionStorage.getItem('mandapam_id')

      const payload = {
        images: imageurl,
        image_types: imageUrlType,
        videos: videoArray,
        video_types: VideoTypeArray
      }
      Axios.post(`${apiURL}/add_images_videos?user_id=${project.user_id}&mandapam_id=${mandapam_id}`, payload, {
        headers: {
          'Authorization': `Bearer ${project.access_token}`,
          "accept": "application/json",
          "Content-Type": " application/json"
        }
      },
      ).then((response) => {
        toast.success('Create Mandapam Successfully')
        setActiveStep(1)
        setFormData({
          mandapam_name: "",
          about: "",
          startingpriceperday: "",
          place_type: "",
          mobile_number: "",
          contactName: "",
          mandap_link: "",
          address: "",
          foodtype: "",
          hallseatingcapacity: "",
          hallfloatingcapacity: "",
          carparking_count: "",
          bikeparking_count: "",
          roomsnonac: "",
          roomsac: "",
          valetparking: "",
        })
      })
        .catch((err) => {
          {
            toast.error("Invalid Credentials")
          }
        });
      setTimeout(() => {
        setIsToastOpen(false);
      }, 3000); // set the timeout based on how long the toast message will be displayed
    }
  }


  return (
    <div className={user ? "g-sidenav-show bg-gray-100 g-sidenav-hidden " : "g-sidenav-show  bg-gray-100 "}  >
      <Toaster toastOptions={{ position: "top-right" }} />
      <Aside />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg " style={{ height: "100%" }}>
        <Navbar Name='Add New' />
        <div className="container-fluid py-4" style={{ minHeight: "600px" }}>
          <div className="row d-flex justify-content-center">
            <div className="col-12 ">
              <Stepper styleConfig={stepStyleConfig} activeStep={activeStep}>
                <Step label="Basic" />
                <Step label="Contact" />
                <Step label="Room & Hall" />
                <Step label="Food & Services" />
                <Step label="Parking " />
                <Step label="Images" />
                <Step label="Videos" />
              </Stepper>
              {activeStep === 1 ? <Basic
                data={formData}
                handleChange={handleChange}
                next={handleNext}
              /> : ""}
              {activeStep === 2 ? <Contact
                data={formData}
                handleChange={handleChange}
                next={handleNext}
                back={handleBack}
                handleInputChange={handleInputChange}
                inputValue={inputValue}
                inputName={inputName}
                handleInputNameChange={handleInputNameChange}
              /> : ""}
              {activeStep === 3 ? <RoomHall
                data={formData}
                handleChange={handleChange}
                next={handleNext}
                back={handleBack}
                fullhall_ac={fullhall_ac}
                setFullhall_ac={setFullhall_ac}
              /> : ""}
              {activeStep === 4 ? <FoodService
                data={formData}
                handleChange={handleChange}
                next={handleNext}
                back={handleBack}
                nonveg_cooking={nonveg_cooking}
                setnonveg_cooking={setnonveg_cooking}
                setnonveg_service_allowed={setnonveg_service_allowed}
                nonveg_service_allowed={nonveg_service_allowed}
                setoutdoor_catering_allowed={setoutdoor_catering_allowed}
                outdoor_catering_allowed={outdoor_catering_allowed}
              /> : ""}
              {activeStep === 5 ? <Parking
                data={formData}
                handleChange={handleChange}
                next={handleNext}
                back={handleBack}
                valetparking={valetparking}
                setvaletparking={setvaletparking}
                carparking={carparking}
                setcarparking={setcarparking}
                bikeparking={bikeparking}
                setbikeparking={setbikeparking}
                outsidedecorationallowed={outsidedecorationallowed}
                setoutsidedecorationallowed={setoutsidedecorationallowed}
                outsideentertainment={outsideentertainment}
                setoutsideentertainment={setoutsideentertainment}
                handleSubmit={handleSubmit}
              /> : ""}
              {activeStep === 6 ? <Image data={formData}
                next={handleNext}
                addTextBox={addTextBox}
                setAddTextBox={setAddTextBox}
                onclickRemove={onclickRemove}
                onchangename={onchangename}
                addboxt={addboxt}
                uploadImage={uploadImage}
                back={handleBack} />
                : ""}
              {activeStep === 7 ? <Viedo data={formData}
                video={video}
                setVideo={setVideo}
                VideoRemove={VideoRemove}
                onchangeVideotype={onchangeVideotype}
                ViedoAdd={ViedoAdd}
                handleFileChange={handleFileChange}
                handleImage_VideoClick={handleImage_VideoClick}
                back={handleBack} />
                : ""}
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </div>
  )
}

export default AddNew
