import React, { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import { apiURL } from '../../Layout/Common/confiqurl';
import { validPhoneNumberRegex } from '../../Layout/Common/common';
import log2 from '../../Images/log2.jpg';
import Images from '../../Images';

function ForgotPassword() {

    const navigate = useNavigate()
    const [isToastOpen, setIsToastOpen] = useState(false);

    const [state, setState] = useState({

        contactNumber: '',

    })

    // const [data,setData] = useState()
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
        const value = event.target.value;
        if (/^\d{0,10}$/.test(value)) { // Limit input to 10 numbers
            setInputValue(value);
        }
    };
    const handleClick = (e) => {






        if (validPhoneNumberRegex.test(inputValue)) {

            if (!isToastOpen) {
                setIsToastOpen(true);

            Axios.post(`${apiURL}/forget_password?mob=${inputValue}`, {
                headers: {
                    "accept": "application/json",
                    'Content-Type': 'application/json; charset=UTF-8',

                }
            },
            ).then((response) => {
                console.log('loginsuccess', response.data);
                const data = response.data.user_id
                sessionStorage.setItem("project_id", data)
                sessionStorage.setItem("response", JSON.stringify(response.data))


                navigate("/otp")
            
            })
                .catch((err) => {
                    {
                        toast.error(err.response.data.detail)                                          
                    }
                });

                setTimeout(() => {
                  setIsToastOpen(false);
                }, 3000); // set the timeout based on how long the toast message will be displayed
              }

        } else {
            if (!isToastOpen) {
                setIsToastOpen(true);
                toast.error('Please Enter Valid Mobile Number');
                setTimeout(() => {
                  setIsToastOpen(false);
                }, 3000); // set the timeout based on how long the toast message will be displayed
              }
        }

    }


    return (
        <div>
            <Toaster toastOptions={{ position: "top-left" }} />

            <main class="main-content mt-0 ps">
                <section>
                    <div class="page-header min-vh-100">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
                                    <div class="card card-plain">
                                    <a href="http://uat.bookmevenue.com:3001/">
                                                <img src={Images.logobook} alt="Logo" className="img-fluid logo"  />
                                            </a>
                                        <div class=" pb-0 text-left ms-4">
                                            {/* <div style={{paddingLeft: '0px' }}>
                                       
                                            </div > */}
                                            <h4 class="font-weight-bolder">Forgot Password </h4>
                                            {/* <p class="mb-0">You will receive an e-mail in maximum 60 seconds</p> */}
                                        </div>
                                        <div class="card-body">
                                            <form role="form">
                                            <label>Mobile Number<span style={{color:'red'}}>*</span></label>

                                                <div class="mb-3">


                                                    <input
                                                        placeholder='  Mobile Number'
                                                        class="form-control"
                                                        type="text"
                                                        id="input"
                                                        maxLength={10} // Set the maximum length of input to 10
                                                        pattern="[0-9]*" // Only allow numbers
                                                        value={inputValue}
                                                        onChange={handleInputChange}
                                                    />

                                                </div>
                                                {/* <div class="mb-3">
                                                    <input type="text" class="form-control form-control-lg" placeholder="Verification Code" aria-label="code" />
                                                </div> */}
                                                <div class="text-center">
                                                    <button type="button" class="btn bg-gradient-primary btn-lg w-100 my-4" onClick={handleClick}>Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column"> */}
                                    {/* <div class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center">
                                       
                                        <h4 class="mt-5 text-white font-weight-bolder">Soft UI Design</h4>
                                        <p class="text-white">Just as it takes a company to sustain a product, it takes a community to sustain a protocol.</p>
                                    </div> */}
                                      <div class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
                        <div className="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center" style={{ backgroundImage: `url(${log2})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        </div>
                        </div>

                                </div>
                            </div>
                        </div>
                    {/* </div> */}
                </section>
                <div class="ps__rail-x" style={{ left: '0px', bottom: '0px' }}><div class="ps__thumb-x" tabindex="0" style={{ left: '0px', width: '0px' }}></div></div><div class="ps__rail-y" style={{ top: '0px', right: '0px' }}><div class="ps__thumb-y" tabindex="0" style={{ top: '0px', height: '0px' }}></div></div></main>

        </div>
    )
}

export default ForgotPassword