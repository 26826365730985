const events =[
    {
      title: "Birthday Function",
      start: "2023-03-27",
      end: "2023-03-27",
      className: "success",
      
    },
  
    {
      title: "Meeting with Mary",
      start: "2022-02-03",
      end: "2022-02-03",
      className: "info",
    },
  
    {
      title: "Marriage function",
      start: "2023-02-04",
      end: "2023-02-04",
      className: "success",
    },
  
    {
      title: "Wedding Reception",
      start: "2023-02-24",
      end: "2023-02-24",
      className: "error",
    },
  
    {
      title: "Business Meeting",
      start: "2023-02-09",
      end: "2023-02-11",
      className: "warning",
    },
  
    {
      title: "Arts Festival",
      start: "2023-02-12",
      end: "2023-02-12",
      className: "primary",
    },
  
  
  ];
  
  // function getDate(dayString) {
  //   const today = new Date();
  //   const year = today.getFullYear().toString();
  //   let month = (today.getMonth() + 1).toString();
  
  //   if (month.length === 1) {
  //     month = "0" + month;
  //   }
  
  //   return dayString.replace("YEAR", year).replace("MONTH", month);
  // }
  
  export default events;
  