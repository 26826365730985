import React, { useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import MapPicker from "react-google-map-picker";

const DefaultLocation = { lat: 22.2604, lng: 84.8536 };
const DefaultZoom = 10;
const Contact = (props) => {
  const { data, handleChange, next, back, error, inputValue, handleInputChange, handleInputNameChange, inputName } = props;
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  function handleChangeLocation(lat, lng) {
    setLocation({ lat: lat, lng: lng });
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }
  function handleResetLocation() {
    setDefaultLocation({ ...DefaultLocation });
    setZoom(DefaultZoom);
  }

  const nextData = () => {
    if (inputValue.trim()) {
      if (inputName.trim()) {
        if (data.address.trim()) {
          next()
        } else {
          if (!isToastOpen) {
            setIsToastOpen(true);
            toast.error('Please Enter Address')
            setTimeout(() => {
              setIsToastOpen(false);
            }, 3000);
          }
        }
      }
      else {
        if (!isToastOpen) {
          setIsToastOpen(true);
          toast.error('Please Enter Contact Name')
          setTimeout(() => {
            setIsToastOpen(false);
          }, 3000);
        }
      }
    } else {
      if (!isToastOpen) {
        setIsToastOpen(true);
        toast.error('Please Enter Mobile Number')
        setTimeout(() => {
          setIsToastOpen(false);
        }, 3000);
      }

    }

  }


  return (
    <div class="row">
      {/* <Toaster toastOptions={{ position: "top-right" }} /> */}
      <div class="col-12 col-lg-8 mx-auto  w-100">
        <div class="card w-100">
          <div class="card-body">
            <h5 class="font-weight-bolder">Contact</h5>
            <div className='row'>
              <div className='col-6' >
                <label>Mobile Number<span style={{ color: 'red' }}>*</span></label>
                <input
                  class="form-control"
                  placeholder='  Mobile Number'
                  type="text"
                  id="input"
                  maxLength={10} // Set the maximum length of input to 10
                  pattern="[0-9]*" // Only allow numbers
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
              <div className='col-6' >
                <label htmlFor="contactName">Contact Name<span style={{ color: 'red' }}>*</span></label>
                <input class="form-control"
                  type="text"
                  value={inputName}
                  placeholder='Contact Name'
                  name='contactName'
                  onChange={handleInputNameChange} />
              </div>
            </div>
            <div className='row'>
              <label>Location<span style={{ color: 'red' }}>*</span></label>
              <div className='col-6 ' >
                <MapPicker
                  defaultLocation={defaultLocation}
                  zoom={zoom}
                  style={{ height: "500px", width: "100%" }}
                  onChangeLocation={handleChangeLocation}
                  onChangeZoom={handleChangeZoom}
                  className="ms-2"
                  apiKey="AIzaSyAkBhTU6Tc8FNdu64ZRG4rPm2bin7H7OOI"
                />
              </div>
              <div className='col-6' >
                <div className='col-6 w-100' >
                  <label>Address<span style={{ color: 'red' }}>*</span></label>
                  <textarea className='form-control w-100' type="text" name='address' placeholder='Address' value={data.address} onChange={handleChange} style={{ height: "120px" }} />
                </div>
              </div>
            </div>
            <div className='row mt-5'>
              <div className="col-6 ">
                <button
                  onClick={back}
                  className="btn bg-gradient-secondary mb-0 js-btn-prev"
                >
                  back
                </button>
              </div>
              <div className="col-6 text-right">
                <button
                  onClick={nextData}
                  className="btn bg-gradient-dark ms-auto mb-0 js-btn-next  d-flex justify-content-end"

                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
