import React, { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import { apiURL } from '../../Layout/Common/confiqurl';
import "./Style.css"
import { Icons } from '../../Icons/IconIndex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import log from "../../../src/Images/log.jpg";
import log1 from "../../../src/Images/log1.jpg";
import Images from '../../Images';
// import signinimg from '../../../src/Images/signinimg';
import lognew from "../../../src/Images/lognew.jpg";


function Signin() {
    const navigate = useNavigate();
    // const history = useHistory();
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [state, setState] = useState({
        username: '',
        password: '',
    })

    const [icons, setIcons] = useState(true)


    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
    };

    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
        const capitalizedValue = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        setInputValue(capitalizedValue);
    }
 
    const handleSubmit = () => {
        // e.preventDefault();
        sessionStorage.clear();
        const formData = new FormData();
        formData.append('username', inputValue);
        formData.append('password', state.password);
    
        if (inputValue.trim() !== '' || state.password !== '') {
            if (inputValue.length >= 3) {
                if (state.password) {

                    if (!isToastOpen) {
                        setIsToastOpen(true);

                        Axios.post(`${apiURL}/token`, formData
                        ).then((response) => {
                            sessionStorage.setItem("data", JSON.stringify(response.data))
                            navigate("/Dashboard");
                        })
                            .catch((err) => {

                                toast.error(err.response.data.detail)

                            })
                        setTimeout(() => {
                            setIsToastOpen(false);
                        }, 3000); 
                    };
                } else {
                    if (!isToastOpen) {
                        setIsToastOpen(true);
                        toast.error('Please Enter Valid Password')
                        setTimeout(() => {
                            setIsToastOpen(false);
                        }, 3000); 
                    }
                }
            } else {
                if (!isToastOpen) {
                    setIsToastOpen(true);
                    toast.error('Please Enter Valid Username')
                    setTimeout(() => {
                        setIsToastOpen(false);
                    }, 3000); 
                }

            }
        } else {
            if (!isToastOpen) {
                setIsToastOpen(true);
                toast.error('Please Enter Fields')
                setTimeout(() => {
                    setIsToastOpen(false);
                }, 3000); 
            }
        }

    }

    const handleKeyDown = (event) => {
      
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit();
        }
    };

    return (
        <div>
            <Toaster toastOptions={{ position: "top-left" }} />
            <main class="main-content mt-0 ps">
                <section>
                    <div class="page-header min-vh-100">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
                                    <div class="card card-plain">
                                    <a href="http://uat.bookmevenue.com:3001/">
                                                <img src={Images.logobook} alt="Logo" className="img-fluid logo"/>
                                            </a>
                                        <div class=" pb-0 text-start ms-3">
                                           
                                            <h4 class="font-weight-bolder">Login </h4>
                                            <p class="" style={{ fontSize: "15px" }}>Enter your username and password to sign in</p>
                                        </div>
                                        <div class="card-body">
                                            <form role="form">
                                                <label style={{ marginLeft: "0" }}>Username<span style={{color:'red'}} >*</span></label>
                                                <div class="mb-3">
                                                    <input type="text" class="form-control form-control-lg" placeholder="Username" aria-label="username" name='username' value={inputValue} onChange={handleInputChange} onKeyDown={handleKeyDown} required />
                                                </div>
                                                <label style={{ marginLeft: "0" }}>Password<span style={{color:'red'}} >*</span></label>
                                                <div class="mb-3">
                                                    <input type={icons ? "password" : "text"} class="form-control form-control-lg" placeholder="Password" aria-label="password" name='password' value={state.password} onChange={handleChange} onKeyDown={handleKeyDown} />
                                                    <span class="input-group-text">
                                                        <i onClick={() => setIcons(!icons)} className="text-black" style={{ fontSize: "24px", cursor: "pointer" }}>
                                                            {
                                                                icons ? (<FontAwesomeIcon icon={Icons.faEye} />
                                                                ) : (<FontAwesomeIcon icon={Icons.faEyeSlash} />
                                                                )
                                                            }
                                                        </i>
                                                    </span>
                                                    <Link to='/ForgotPassword'><p style={{ float: 'right', marginTop: '17px' }}>ForgotPassword?</p></Link>
                                                </div>
                                                <div class="text-center">
                                                    <button type="button" class="btn btn-lg bg-gradient-primary btn-lg w-100  mb-0" onClick={handleSubmit} >Sign in</button>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="card-footer text-center pt-0 px-lg-2 px-1 ">
                                            <p class="mb-4 text-sm mx-auto">
                                                Don't have an account?
                                                <Link to='/Signup' class="text-primary text-gradient font-weight-bold">  Sign up</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class='col-md-6 col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column'>
                                    <div className="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center" style={{ backgroundImage: `url(${lognew})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <div class="ps__rail-x" style={{ left: '0px', bottom: '0px' }}><div class="ps__thumb-x" tabindex="0" style={{ left: '0px', width: '0px' }}></div></div><div class="ps__rail-y" style={{ top: '0px', right: '0px' }}><div class="ps__thumb-y" tabindex="0" style={{ top: '0px', height: '0px' }}></div></div></main>
        </div>
    )
}

export default Signin
