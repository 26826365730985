import React from 'react'
import { data, variable } from './data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Details = (props) => {
  const {allData}=props
  return (
    <div className='px-3   mt-5 mx-5  mb-5'>
             <div className='row pt-5'>
             <div className='col-lg-6'>
             <h5 className='text-black mx-3 mb-3 '>Details</h5>
<div className='card pt-4'>
    {data.map((value)=>{

        return (
<div className='row py-4'>

            <div className='col-5'><h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={value.icons} /> {value.name}</h6></div>
            <div className='col-7 text-end pe-5 Details-data' style={{color:"#818181"}}>{value.value}</div>
            
            </div> )
    })}


</div>
             </div>
             <div className='col-lg-6'>
             <h5 className='text-black mx-3 mb-3'>What this place offers</h5>
             <div className='card pt-4'>
             {variable.map((value)=>{

return (
<div className='row py-4'>

    <div className='col-6'><h6 style={{color:"#818181"}}><FontAwesomeIcon className='Details-data text-black mx-4' icon={value.icons} /> {value.name}</h6></div>
    <div className='col-6'><h6 style={{color:"#818181"}}><FontAwesomeIcon className='Details-data text-black mx-4' icon={value.iconss} /> {value.names}</h6></div>
    
    </div> )
})}
  
  </div>
             </div>

             </div>

    </div>
  )
}

export default Details
