import React from 'react'
import Images from '../../../Images'
import { faArrowUpFromBracket, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
const ImageGallery = (props) => {
    const {allData}=props
console.log(allData.mandapam_name,"About");
    const navigate=useNavigate()
    return (
        <div className='px-3  mx-5 mt-5'>
            <h5 className='text-black mx-3'>{allData.mandapam_name?allData.mandapam_name :"Pai Vista "} <span className='ms-3'> 4 <img src={Images.Vector} alt='' /></span> </h5>
            <div className='d-flex justify-content-between mt-1 mx-3'>
                <h6 className='text-black '><FontAwesomeIcon className='  me-1 ' icon={faLocationDot} />Basavanagudi</h6>
                <h6 className='text-black pt-1'><FontAwesomeIcon className='  mx-1' icon={faArrowUpFromBracket} />Share</h6>

            </div>
            <div className='row mb-5' >
                <div className='col-5  galleryimage-1 '>
                <img src={Images.galleryimage1} alt='' className='w-100 mb-5' />

                </div>
                <div className='col-7 '>
                   <div className='row mt-1 '>
                        <div className='col-6 galleryimage-2' >

<img src={Images.galleryimage2} alt='' className='w-100' />
                        </div>   <div className='col-6 galleryimage-3'>

                        <img src={Images.galleryimage3} alt='' className='w-100'/>
                        </div>

                    </div>
                     <div className='row mt-4 pt-3' >
                        <div className='col-6 galleryimage-4'>
                        <img src={Images.galleryimage5} alt='' className='w-100 h-100' />

                        </div>   
                        <div className='col-6  '>
                        <div className='galleryimage-5 d-flex align-items-end flex-column mb-3 h-100 '>
                        <button  type="button"  class="btn mt-auto me-5  bg-white "  style={{color:" #836D5D"}} onClick={()=>navigate("/Gallery")}>Show All Photos</button>


                        </div>

                        </div>

                    </div>


                </div>

            </div>

        </div>
    )
}

export default ImageGallery
