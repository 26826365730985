import React, { useContext, useEffect, useState } from 'react'
import Aside from '../../../Layout/Aside'
import Navbar from '../../../Layout/Navbar'
import Footer from '../../../Layout/Footer'
import { MainContext } from '../../../Store/Context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Icons } from '../../../Icons/IconIndex'
import "./style.css"
import Axios from 'axios'
import { apiURL } from '../../../Layout/Common/confiqurl'
import { Toaster, toast } from 'react-hot-toast'
import { Button, Modal, Form } from "react-bootstrap";
const ManageMasterData = () => {
  const { user } = useContext(MainContext)
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState({
    master_data_type: "",
    data_value: "",
    created_by: ""
  })
  const [state, setState] = useState("")
  const [value, setValue] = useState([])
  const [editmasterdata, seteditmasterdata] = useState('')
  const [openpopupView1, setopenpopupView1] = useState(false);
  const [openpopupDelete, setopenpopupDelete] = useState(false);
  const [openpopupView, setopenpopupView] = useState(false);
  const [deleteValue, setDeleteValue] = useState()
  const [editeValue, setEditValue] = useState()
  const [viewValue, setviewValue] = useState()
  const [master_data_type, setmaster_data_type] = useState();
  const [name, setname] = useState();
  const [created_by, setcreated_by] = useState();
  const [editedData, setEditedData] = useState({});
  const [dataToEdit, setDataToEdit] = useState({});
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'descending' })
  useEffect(() => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    const username = project.user_name;
    setState(username);
    setCurrentPage(1);
    setuserRole(project.user_role)
    getData()
    window.scrollTo(0, 0);
  }, []);
  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (event) => {
    const value = event.target.value;
    const capitalizedValue = value
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    setInputValue(capitalizedValue);
  };
  const handleclick = (event) => {
    event.preventDefault();
    const token = JSON.parse(sessionStorage.getItem('data'));
    const payload = {
      master_data_type: data.master_data_type,
      data_value: inputValue,
      created_by: state
    }
    if (!isToastOpen) {
      setIsToastOpen(true);
      if (data.master_data_type.trim()) {
        if (inputValue.trim()) {
          Axios.post(`${apiURL}/MasterType`, payload,
            {
              headers: {
                'Authorization': `Bearer ${token.access_token}`,
                "accept": "application/json",
                "Content-Type": " application/json",
              }
            },
          ).then((response) => {
            setData({
              master_data_type: "",
              created_by: ""
            })
            setInputValue("")
            getData();
            toast.success('Master Data Create Successfully')
          })
            .catch((err) => {
              {
                toast.error(err.response.data.detail)
              }
            });
        }
        else {
          toast.error('Please Enter Data Value')
        }
      }
      else {
        toast.error('Select Master Data Type')
      }
      setTimeout(() => {
        setIsToastOpen(false);
      }, 3000);
    }
  }
  const getData = () => {
    const token = JSON.parse(sessionStorage.getItem('data'));
    Axios.get(`${apiURL}/All_MasterType`, {
      headers: {
        'Authorization': `Bearer ${token.access_token}`,
        "accept": "application/json",
        "Content-Type": " application/json",
      }
    },).then((response) => {
      const formattedData = response.data.map(item => ({
        ...item,
        created_at: new Date(item.created_at).toLocaleString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
          timeZone: 'UTC'
        })
      }));
      setValue(formattedData)
      sessionStorage.setItem('data_value', JSON.stringify(formattedData));
    })
      .catch((err) => {
        {
          console.log(err)
        }
      });
  }
  const filteredData = value.filter((item) => {
    const itemValues = Object.values(item).join('').toLowerCase();
    return itemValues.includes(searchQuery.toLowerCase());
  });
  const sortedData = filteredData;
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPages = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };
  const [userRole, setuserRole] = useState()
  const openModalDelete = (user_id) => {
    setopenpopupDelete(true);
    setEditValue(user_id)
    setDeleteValue(user_id)
  }
  const [masterDataType, setMasterDataType] = useState("");
  const [dataValue, setDataValue] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const geteditmasterdata = (editeValue) => {
    const token = JSON.parse(sessionStorage.getItem('data'));
    const payloadData = {
      master_data_type: editedData.master_data_type,
      data_value: editedData.data_value,
      created_by: editedData.created_by
    };
    Axios.put(
      `${apiURL}/update_mastertype?id=${editeValue}`, payloadData,
      {
        headers: {
          'Authorization': `Bearer ${token.access_token}`,
          'accept': 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then((response) => {
      getData();
      closeModalView1();
    })
      .catch((err) => {
        {
          console.log(err)
        }
      });
  }
  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({ ...prevData, [name]: value }));
  };
  const closeModalDelete = () => {
    setopenpopupDelete(false);
  };
  const handleDelete = () => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    Axios.delete(`${apiURL}/delete_mastertype?id=${deleteValue}`,
      {
        headers: {
          'Authorization': `Bearer ${project.access_token}`,
          "accept": "application/json",
          "Content-Type": " application/json",
        }
      },)
      .then((res) => {
        getData();
        closeModalDelete()
        toast.success("Data Deleted Successfully");
      });
  }
  const closeModalView = (id) => {
    setviewValue(id)
    setopenpopupView(false);
  };
  const openModalView = (id) => {
    setopenpopupView(true);
    setviewValue(id)
  }
  const openModalView1 = (id) => {
    handleView(id);
    setEditedData({ ...data });
    setopenpopupView1(true);
    setviewValue(id)
    setEditValue(id)
  }
  const closeModalView1 = (id) => {
    setviewValue(id)
    setopenpopupView1(false);
  };
  const handleView = (id) => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    Axios.get(`${apiURL}/get_mastertype_by_id?id=${id}`,
      {
        headers: {
          'Authorization': `Bearer ${project.access_token}`,
          "accept": "application/json",
          "Content-Type": " application/json",
        }
      },)
      .then((response) => {
        seteditmasterdata(response.data)
        setEditedData(response.data)
        setname(response.data.data_value)
        setmaster_data_type(response.data.master_data_type)
        setcreated_by(response.data.created_by)
      });
  }

  const getDisplayedPageNumbers = () => {

    const maxPageNumbersToShow = 5;

    const totalPages = pageNumbers.length;

    let startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);

    let endPage = startPage + maxPageNumbersToShow - 1;
    if (endPage > totalPages) {

      endPage = totalPages;

      startPage = Math.max(endPage - maxPageNumbersToShow + 1, 1);

    }
    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

  };

  return (
    <div className={user ? "g-sidenav-show bg-gray-100 g-sidenav-hidden " : "g-sidenav-show  bg-gray-100"} >
      <Toaster toastOptions={{ position: "top-right" }} />
      <Aside />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Navbar Name='Master Data' />
        <div className="container-fluid py-4" style={{ minHeight: "560px" }}>
          <div className='card p-4 mt-4'>
            <div className='row '>
              <div className='col-4' >
                <label>Choose Master Data Type</label>
                <select id="inputState" class="form-select " name='master_data_type' value={data.master_data_type} onChange={handleChange} >
                  <option value="" disabled selected hidden> Select an option </option>
                  <option>Event Type</option>
                  <option>Food Type</option>
                  <option>Image Type</option>
                  <option>Venue Type</option> 
                </select>
              </div>
              <div className='col-4' >
                <label>Enter Type Name</label>
                <input class="form-control" type="text" placeholder='Enter Type Name ' name='data_value' value={inputValue} onChange={handleInputChange} />
              </div>
              <div className='col-4 d-flex justify-content-start mt-4 pt-2' >
                <div className=' '>
                  <button type="button" class="btn btn-outline-primary mx-2 btn-sm  ms-3" data-bs-toggle="modal" data-bs-target="#import" style={{ backgroundColor: 'white', color: '#cb0c9f', border: '1px solid #cb0c9f', }}>cancel</button>
                </div>
                <div className=' '>
                  <button type="button" class="btn bg-gradient-primary btn-sm mb-0 ms-3  " target="_blank" onClick={handleclick} > Save</button>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <div class="card">
                <div class="card-header mt-2 d-flex justify-content-between ">
                  <div>
                    <h5 class="mb-0">Master Data</h5>
                    <p class="text-sm mb-0"> </p>
                  </div>
                </div>
                <div class="table-responsive">
                  <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                    <div class="dataTable-top">
                      <div class="dataTable-dropdown">
                        <label style={{ width: "300px" }}>
                          <select class="dataTable-selector w-50 me-2" onChange={handleItemsPerPageChange} >
                            <option value="5" >5</option>
                            <option value="10" >10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="25">25</option>
                          </select>
                          entries per page</label>
                      </div>
                      <div class="dataTable-search">
                        <input class="dataTable-input" placeholder="Search..." type="text" value={searchQuery} onChange={(event) => { setSearchQuery(event.target.value); setCurrentPage(1); }} />
                      </div>
                    </div>
                    <div class="dataTable-container" >
                      <table class="table table-flush dataTable-table" id="datatable-search">
                        <thead class="thead-light">
                          <tr><th data-sortable="" style={{ width: "10.8797%",cursor: "pointer"  }} onClick={() => handleSort('id')}>
                            <a  class=""><label>S.No </label></a>
                          </th>
                            <th data-sortable="" style={{ width: "10.8797%",cursor: "pointer"  }} onClick={() => handleSort('master_data_type')}>
                              <a  class=""><label>Master Data Type {sortConfig.key === 'master_data_type' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</label></a>
                            </th>
                            <th data-sortable="" style={{ width: "10.8797%",cursor: "pointer"  }} onClick={() => handleSort('data_value')}>
                              <a  class=""><label>Name {sortConfig.key === 'data_value' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</label></a>
                            </th>
                            <th data-sortable="" style={{ width: "10.8797%",cursor: "pointer"  }} onClick={() => handleSort('created_by')}>
                              <a  class=""><label>Created By {sortConfig.key === 'created_by' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</label></a>
                            </th>
                            <th data-sortable="" style={{ width: "14.7845%",cursor: "pointer"  }} onClick={() => handleSort('created_at')}>
                              <a  class=""><label>Created At {sortConfig.key === 'created_at' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</label></a>
                            </th>
                            <th data-sortable="" style={{ width: "13.0304%",cursor: "pointer"  }}>
                              <label>Actions</label>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentPages ? currentPages.map((item, i) =>
                            <tr key={i + 1}>
                              <td class="text-sm"> <div className='mt-4 ms-3 '>  {i + 1}</div></td>
                              <td class="text-sm">{item.master_data_type}</td>
                              <td class="text-sm">{item.data_value}</td>
                              <td class="text-sm">{item.created_by}</td>
                              <td class="text-sm">{item.created_at}</td>
                              <td class="text-sm">
                                <a data-bs-toggle="tooltip" data-bs-original-title="Preview product" onClick={() => { handleView(item.id); openModalView(item.id); }}>
                                  <i className="text-secondary" aria-hidden="true" > <FontAwesomeIcon icon={Icons.faEye} /> </i>
                                </a>
                                <a data-bs-toggle="tooltip" className='mx-3' data-bs-original-title="Edit Hall" onClick={() => { openModalView1(item.id); }}>
                                  <i class="text-secondary" aria-hidden="true"> <FontAwesomeIcon icon={Icons.faPen} /> </i>
                                </a>
                                <a data-bs-toggle="tooltip" data-bs-original-title="Delete Hall" onClick={() => openModalDelete(item.id)} >
                                  <i className="text-secondary" aria-hidden="true">  <FontAwesomeIcon icon={Icons.faTrash} /> </i>
                                </a>
                              </td>
                            </tr>
                          ) : (<p>No</p>)}
                        </tbody>
                      </table>
                    </div>
                    <div className="dataTable-bottom">

                      <div className="dataTable-info">Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filteredData.length)} of {filteredData.length} entries</div>

                      <nav className="dataTable-pagination">

                        <ul className="dataTable-pagination-list">

                          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>

                            <a className="page-link"  onClick={() => handlePageChange(currentPage - 1)}> ‹ </a>

                          </li>

                          {getDisplayedPageNumbers().map((number) => (

                            <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>

                              <a className="page-link"  onClick={() => handlePageChange(number)}>{number}</a>
                            </li>
                          ))}
                          <li className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
                            <a className="page-link"  onClick={() => handlePageChange(currentPage + 1)}>›</a>
                          </li>

                        </ul>

                      </nav>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal show={openpopupView} size='lg' className='model-popup-design' >
            <div className='d-flex justify-content-between mt-3' >
              <Modal.Title className='ms-4 '>View Details</Modal.Title>
              <button className='me-3 btn btn-outline-secondary  ' onClick={closeModalView}>
                x
              </button>
            </div>
            <div className='ms-4'>
            </div>
            <div className='loginmodalbody' style={{ width: 'auto' }}>
              <Modal.Body className='testing-test'>
                <Form.Group >
                  <Form.Label>Master data type:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={master_data_type}
                    placeholder="Name input"
                    disabled='true'
                  />
                </Form.Group>
                <Form.Group >
                  <Form.Label>Name:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={name}
                    placeholder="Name input"
                    disabled='true'
                  />
                </Form.Group>
                <Form.Group >
                  <Form.Label>Created_by:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleChange}

                    value={created_by}

                    placeholder="Name input"
                    disabled='true'
                  />
                </Form.Group>
              </Modal.Body>
            </div>
            <Modal.Footer>
              <Button variant="secondary" style={{
                backgroundColor: 'white',
                color: '#cb0c9f',
                border: '1px solid #cb0c9f',
              }} onClick={closeModalView}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={openpopupView1} size='lg' className='model-popup-design' >
            <div className='d-flex justify-content-between mt-3' >
              <Modal.Title className='ms-4 '>Update Details</Modal.Title>
              <button className='me-3 btn btn-outline-secondary  ' onClick={closeModalView1}>
                x
              </button>
            </div>
            <div className='ms-4'>
            </div>
            <div className='loginmodalbody' style={{ width: 'auto' }}>
              <Modal.Body className='testing-test'>
                <Form.Group >
                  <Form.Label>Master data type:</Form.Label>
                  <Form.Control
                    type="text"
                    name="master_data_type"
                    onChange={handleChange1}
                    value={editedData.master_data_type}
                    placeholder="Name input"
                  />
                </Form.Group>
                <Form.Group >
                  <Form.Label>Name:</Form.Label>
                  <Form.Control
                    type="text"
                    name="data_value"
                    onChange={handleChange1}
                    value={editedData.data_value}
                    placeholder="Name input"
                  />
                </Form.Group>
                <Form.Group >
                  <Form.Label>Created_by:</Form.Label>
                  <Form.Control
                    type="text"
                    name="created_by"
                    onChange={handleChange1}
                    value={editedData.created_by}
                    placeholder="Name input"
                  />
                </Form.Group>
              </Modal.Body>
            </div>
            <Modal.Footer>
              <Button variant="secondary" style={{
                backgroundImage: 'linear-gradient(310deg, #7928ca, #ff0080)',
                color: 'white',
                border: 'none',
              }} onClick={() => geteditmasterdata(editeValue)}>
                update
              </Button>

              <Button variant="secondary" style={{
                backgroundColor: 'white',
                color: '#cb0c9f',
                border: '1px solid #cb0c9f',
              }} onClick={closeModalView1}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={openpopupDelete} size='md' className='model-popup-design' >
            <div className='d-flex justify-content-between mt-3' >

              <Modal.Title className='ms-4 '>Delete</Modal.Title>
              <button className='me-3 btn btn-outline-secondary  ' onClick={closeModalDelete}>
                x
              </button>
            </div>
            <div className='loginmodalbody' style={{ width: '785px' }}>
              <Modal.Body className='testing-test' >

                Are you sure you want to delete this item ?
              </Modal.Body>
            </div>
            <Modal.Footer>
              <Button variant="danger" style={{
                backgroundImage: 'linear-gradient(310deg, #7928ca, #ff0080)',
                color: 'white',
                border: 'none',
              }} onClick={handleDelete}>
                Delete
              </Button>
              <Button variant="secondary" style={{
                backgroundColor: 'white',
                color: '#cb0c9f',
                border: '1px solid #cb0c9f',
              }} onClick={closeModalDelete}>
                Cancel
              </Button>


            </Modal.Footer>
          </Modal>

        </div>
        <Footer />
      </main>

    </div>
  )
}

export default ManageMasterData