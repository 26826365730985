import React from 'react'
import "../Style.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationCrosshairs, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import Images from '../../../Images'
import { Icons } from '../../../Icons/IconIndex'
const Banner4 = () => {
  return (
    <div className="Banner2 px-3  mx-5">
      <div style={{ backgroundColor: "#F1EDE7" }}>
        <h3 className='my-4 text-center Carousel-name pt-4'>How It Works?</h3>
        <div className='d-flex align-items-center px-5' style={{ height: "350px" }}>

          <div className='container  d-flex justify-content-center  ' >
            <div className=''>
              <div className='d-flex justify-content-start ms-5 ps-2'>

                <div className='part4-subpart d-flex align-items-end flex-column'><div className='px-3 mt-auto part4-column  bg-white  d-flex justify-content-center d-flex align-items-center' style={{ height: "75px" }}><FontAwesomeIcon className='iconpart4  mx-2' icon={faLocationDot} /></div></div>
                <div className='part4-subpart d-flex align-items-end flex-column mt-4'><div className=' me-auto  d-flex justify-content-center d-flex align-items-center' style={{ height: "125px" }}>    <img className=" col-image w-100 " src={Images.line15} alt="product image" />
                </div>
                </div>
              </div>
              <div className='w-75 text-center'><h6>Select Location</h6><p>Lorem Ipsum is simply dummy text </p></div>
            </div>
            <div className=''>
              <div className='d-flex justify-content-start '>

                <div className='part4-subpart d-flex align-items-end flex-column'><div className='px-3 me-auto part4-column  bg-white  d-flex justify-content-center d-flex align-items-center' style={{ height: "75px" }}><FontAwesomeIcon className='iconpart4  mx-2' icon={Icons.faRectangleList} /></div>


                </div>
                <div className='part4-subpart d-flex align-items-end flex-column mt-4'><div className=' me-auto  d-flex justify-content-center d-flex align-items-center' style={{ height: "125px" }}>    <img className=" col-image w-100 " src={Images.line16} alt="product image" />
                </div></div>
              </div>
              <div className='w-75 text-center padding-card ' style={{marginTop:"-130px",marginLeft:"-39px"}}><h6>Search Venue</h6><p>Lorem Ipsum is simply dummy text </p></div>

            </div>
            <div className='' style={{marginLeft:"-3px"}}>
              <div className='d-flex justify-content-start '>

                <div className='part4-subpart d-flex align-items-end flex-column'><div className='px-3 mt-auto part4-column  bg-white  d-flex justify-content-center d-flex align-items-center' style={{ height: "75px" }}><FontAwesomeIcon className='iconpart4  mx-2' icon={Icons.faBook} /></div></div>
                <div className='part4-subpart d-flex align-items-end flex-column mt-4'><div className=' me-auto  d-flex justify-content-center d-flex align-items-center' style={{ height: "125px" }}>    <img className=" col-image w-100 " src={Images.line17} alt="product image" />
                </div>
                </div>
              </div>
              <div className='w-75 text-center' style={{marginLeft:"-50px"}}><h6>Get Details</h6><p>Lorem Ipsum is simply dummy text </p></div>
            </div>
            <div className=''>
              <div className='d-flex justify-content-start '>

                <div className='part4-subpart d-flex align-items-end flex-column'><div className='px-3 me-auto part4-column  bg-white  d-flex justify-content-center d-flex align-items-center' style={{ height: "75px" }}><FontAwesomeIcon className='iconpart4  mx-2' icon={faLocationDot} /></div>


                </div>
                <div className='part4-subpart d-flex align-items-end flex-column mt-4'><div className=' me-auto  d-flex justify-content-center d-flex align-items-center' style={{ height: "125px" }}>    <img className=" col-image w-100 " src={Images.line18} alt="product image" />
                </div></div>
              </div>
              <div className='w-75 padding-card ' style={{marginTop:"-130px",marginLeft:"-10px"}}><h6>Book Venue </h6><p>Lorem Ipsum is simply dummy text </p></div>

            </div>
            <div className=''style={{marginLeft:"-80px"}}>
              <div className='d-flex justify-content-start ms-5 ps-2'>

                <div className='part4-subpart d-flex align-items-end flex-column'><div className='px-3 mt-auto part4-column  bg-white  d-flex justify-content-center d-flex align-items-center' style={{ height: "75px" }}><FontAwesomeIcon className='iconpart4  mx-2' icon={faLocationDot} /></div></div>
              
              </div>
              <div className='w-75 text-center'><h6>Relax</h6><p>Lorem Ipsum is simply dummy text </p></div>
            </div>

            {/* <div className=''>
              <div className='d-flex justify-content-start '>

                <div className='part4-subpart d-flex align-items-end flex-column'><div className='px-3 mt-auto part4-column  bg-white  d-flex justify-content-center d-flex align-items-center' style={{ height: "75px" }}><FontAwesomeIcon className='iconpart4  mx-2' icon={faLocationDot} /></div></div>
                <div className='part4-subpart d-flex align-items-end flex-column mt-4'><div className=' me-auto  d-flex justify-content-center d-flex align-items-center' style={{ height: "125px" }}>    <img className=" col-image w-100 " src={Images.line15} alt="product image" />
                </div>
                </div>
              </div>
              <div className='w-75 '><h6>Select Location</h6><p>Lorem Ipsum is simply dummy text </p></div>
            </div>
            <div className=''>
              <div className='d-flex justify-content-start '>

                <div className='part4-subpart d-flex align-items-end flex-column'><div className='px-3 me-auto part4-column  bg-white  d-flex justify-content-center d-flex align-items-center' style={{ height: "75px" }}><FontAwesomeIcon className='iconpart4  mx-2' icon={faLocationDot} /></div>


                </div>
                <div className='part4-subpart d-flex align-items-end flex-column mt-4'><div className=' me-auto  d-flex justify-content-center d-flex align-items-center' style={{ height: "125px" }}>    <img className=" col-image w-100 " src={Images.line16} alt="product image" />
                </div></div>
              </div>
              <div className='w-75 padding-card '><h6>Select Location</h6><p>Lorem Ipsum is simply dummy text </p></div>

            </div> */}
            {/* <div className='part4-subpart d-flex align-items-end flex-column'><div className='px-3 mt-auto part4-column  bg-white  d-flex justify-content-center d-flex align-items-center' style={{height:"75px"}}><FontAwesomeIcon className='iconpart4  mx-2' icon={faLocationDot} /></div></div>
<div className='part4-subpart d-flex align-items-end flex-column mt-4'><div className=' me-auto  d-flex justify-content-center d-flex align-items-center' style={{height:"125px"}}>    <img className=" col-image w-100 " src={Images.line15}   alt="product image" />
</div></div>

<div className='part4-subpart d-flex align-items-end flex-column'><div className='px-3 me-auto part4-column  bg-white  d-flex justify-content-center d-flex align-items-center' style={{height:"75px"}}><FontAwesomeIcon className='iconpart4  mx-2' icon={faLocationDot} /></div></div>
<div className='part4-subpart d-flex align-items-end flex-column mt-4'><div className=' me-auto  d-flex justify-content-center d-flex align-items-center' style={{height:"125px"}}>    <img className=" col-image w-100 " src={Images.line16}   alt="product image" />
</div></div>
<div className='part4-subpart d-flex align-items-end flex-column'><div className='px-3 mt-auto part4-column  bg-white  d-flex justify-content-center d-flex align-items-center' style={{height:"75px"}}><FontAwesomeIcon className='iconpart4  mx-2' icon={faLocationDot} /></div></div>
<div className='part4-subpart d-flex align-items-end flex-column mt-4'><div className=' me-auto  d-flex justify-content-center d-flex align-items-center' style={{height:"125px"}}>    <img className=" col-image w-100 " src={Images.line17}   alt="product image" />
</div></div>

<div className='part4-subpart d-flex align-items-end flex-column'><div className='px-3 me-auto part4-column  bg-white  d-flex justify-content-center d-flex align-items-center' style={{height:"75px"}}><FontAwesomeIcon className='iconpart4  mx-2' icon={faLocationDot} /></div></div>
<div className='part4-subpart d-flex align-items-end flex-column mt-4'><div className=' me-auto  d-flex justify-content-center d-flex align-items-center' style={{height:"125px"}}>    <img className=" col-image w-100 " src={Images.line18}   alt="product image" />
</div></div>
<div className='part4-subpart d-flex align-items-end flex-column'><div className='px-3 mt-auto part4-column  bg-white  d-flex justify-content-center d-flex align-items-center' style={{height:"75px"}}><FontAwesomeIcon className='iconpart4  mx-2' icon={faLocationDot} /></div></div> */}


          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner4
