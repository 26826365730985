import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Images from '../../../Images'
import { faCodeFork, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { Icons } from '../../../Icons/IconIndex'

const Card = () => {
  return (
    <div class="container mt-5 mb-4 " style={{ backgroundColor: "#F1EDE7", width: "75%" }}>
      <h5 className='text-black mx-3 mt-4'>Pai Vista <span className='ms-3'> 4 <img src={Images.Vector} alt='' /></span> </h5>
      <div className='mt-1 mx-3'>
        <p className='text-black '><FontAwesomeIcon className='  me-1 ' icon={faLocationDot} />Basavanagudi</p>

      </div>
      <div className='mt-1 row '>
        <div className='col-xl-4 col-md-6'>            <h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={Icons.faUser} />Hall Capacity - 700</h6>
        </div>
        <div className='col-xl-4 col-md-6'>            <h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={faCodeFork} />Food Type - Veg/Non Veg</h6>
        </div>
      </div>

      <h6 className='mt-1'><FontAwesomeIcon className='Details-data text-black mx-4' icon={Icons.faBox} /> Rooms :   AC / Yes -NonAC / Yes</h6>

      <p>For Instant help in Booking contact us on  <span className='text-danger'>+919876543210</span></p>
    </div>
  )
}

export default Card
