import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BeatLoader } from "react-spinners";
import Aside from '../../Layout/Aside';
import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';
import { MainContext } from '../../Store/Context';
import Images from '../../Images';
import { apiURL } from '../../Layout/Common/confiqurl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Icons } from '../../Icons/IconIndex'
import { faSearch, faAsterisk } from '@fortawesome/free-solid-svg-icons';

const AllPlaces = () => {
  const { user } = useContext(MainContext);
  const navigate = useNavigate();
  const [state, setState] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [userRole, setUserRole] = useState('');
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    setUserRole(project.user_role);
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    setIsLoading(true);
    try {
      const url = project.user_role === "admin"
        ? `${apiURL}/all_mandapam_details?start=0&end=${itemsPerPage - 1}`
        : `${apiURL}/all_mandapam_details?user_id=${project.user_id}&start=0&end=${itemsPerPage - 1}`;

      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${project.access_token}`,
          "accept": "application/json",
          "Content-Type": "application/json",
        }
      });

      console.log(response.data.data, "response.data.data");
      setState(response.data.data);
      setTotalCount(response.data.total_count);
      window.scrollTo(0, 0);
    } catch (error) {
      console.error('Error fetching initial data:', error);
      window.scrollTo(0, 0);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = async (page) => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    const start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage - 1;
    setIsLoading(true);
    try {
      const url = project.user_role === "admin"
        ? `${apiURL}/all_mandapam_details?start=${start}&end=${end}`
        : `${apiURL}/all_mandapam_details?user_id=${project.user_id}&start=${start}&end=${end}`;

      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${project.access_token}`,
          "accept": "application/json",
          "Content-Type": "application/json",
        }
      });
      setState(response.data.data);
      setCurrentPage(page);
      window.scrollTo(0, 0);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching paginated data:', error);
      window.scrollTo(0, 0);
      setIsLoading(false);
    }
  };

  const handleSearchChange = async () => {
    let search_Query = searchQuery;
    const project = JSON.parse(sessionStorage.getItem('data'));
    const end = totalCount;
    setIsLoading(true);
    try {
      const url = project.user_role === "admin"
        ? `${apiURL}/all_mandapam_details_search?search_query=${search_Query}&start=0&end=${end}`
        : `${apiURL}/all_mandapam_details_search?user_id=${project.user_id}&search_query=${search_Query}&start=0&end=${end}`;

      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${project.access_token}`,
          "accept": "application/json",
          "Content-Type": "application/json",
        }
      });
      console.log(response.data.data, "response.data.data");
      setState(response.data.data);
      setTotalCount(response.data.total_count);
      window.scrollTo(0, 0);
    } catch (error) {
      console.error('Error fetching initial data:', error);
      window.scrollTo(0, 0);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredData = state.filter((item) => {
    const itemValues = Object.values(item).join('').toLowerCase();
    return itemValues.includes(searchQuery.toLowerCase());
  });

  const pages = Math.ceil(totalCount / itemsPerPage);

  const renderPaginationButtons = () => {
    const buttons = [];
    const maxButtons = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
    let endPage = Math.min(pages, startPage + maxButtons - 1);

    if (endPage - startPage < maxButtons - 1) {
      startPage = Math.max(1, endPage - maxButtons + 1);
    }

    if (startPage > 1) {
      buttons.push(
        <li key="first" className="page-item">
          <a onClick={() => handlePageChange(1)} className="page-link">1</a>
        </li>
      );
      if (startPage > 2) {
        buttons.push(<li key="ellipsis-start" className="page-item disabled"><span className="page-link">...</span></li>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
          <a onClick={() => handlePageChange(i)} className="page-link">{i}</a>
        </li>
      );
    }

    if (endPage < pages) {
      if (endPage < pages - 1) {
        buttons.push(<li key="ellipsis-end" className="page-item disabled"><span className="page-link">...</span></li>);
      }
      buttons.push(
        <li key={pages} className="page-item">
          <a onClick={() => handlePageChange(pages)} className="page-link">{pages}</a>
        </li>
      );
    }

    return buttons;
  };
  const truncateText = (text, maxLength) => { 
    if (text != null) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      }
      return text;
    } else {
      return "";
    }

  };

  return (
    <div className={user ? "g-sidenav-show bg-gray-100 g-sidenav-hidden" : "g-sidenav-show bg-gray-100"}>
      <Aside />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Navbar Name='All Places' />
        <div className="container-fluid py-4" style={{ minHeight: "560px" }}>
          {userRole === "Partner" ? (
            <div className="row mt-4">
              <div className="col-12">
                <div className="card mb-4">
                  <div className="card-header pb-0 p-3">
                    <h5 className="mb-1">Venues</h5>
                  </div>
                  <div className='container'>
                    <div className="dataTable-search d-flex justify-content-end">
                      <input
                        className="dataTable-inputs"
                        placeholder="Search..."
                        type="text"
                        value={searchQuery}
                        onChange={(event) => setSearchQuery(event.target.value)}
                      />
                      <button type="submit" className='search_button_color' onClick={handleSearchChange}>
                        <FontAwesomeIcon className="mb-0 text-white text-end me-1" icon={faSearch} style={{  cursor: "pointer" }} />

                      </button>
                    </div>
                  </div>
                  <div className="card-body p-3">
                    <div className="row pb-3">
                      {isLoading ? (
                        <div className="d-flex justify-content-center my-5 pt-5">
                          <div className='pt-5 mt-5'>
                            <BeatLoader className='mt-5' />
                          </div>
                        </div>
                      ) : (
                        filteredData.map((item) => (
                          <div className='col-lg-4 col-sm-6 my-2' key={item.id}>
                            <div className="card px-4 pt-4 ">
                              {item.images && item.images.length > 0 && item.images[0].image ? (
                                <img src={`data:image/jpeg;base64,${item.images[0].image}`} height={220} alt="Venue Image" />
                              ) : (
                                <img src={Images.mahall} height={220} alt='' />
                              )}
                              <h6 className='mt-2'>{item.mandapam_name}</h6>
                              <p className="price" style={{ fontSize: "18px" }}>{truncateText(item.address, 40)}</p>
                              {/* <p className="price" style={{ fontSize: "18px", minHeight: "80px" }}>{item.address}</p> */}
                              <p>
                                <button type="button" className="btn btn-outline-primary btn-sm mb-4" onClick={() => { navigate('/Editpage', { state: { id: item.id } }); }}>
                                   View Details
                                </button>
                              </p>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center my-3">
                  <ul className="pagination">
                    {renderPaginationButtons()}
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <div className="row mt-4">
              <div className="col-12">
                <div className="card mb-4">
                  <div className="card-header pb-0 p-3">
                    <h5 className="mb-1">Venues</h5>
                  </div>
                  <div className='container'>
                    <div className="dataTable-search d-flex justify-content-end">
                      <input
                        className="dataTable-inputs"
                        placeholder="Search..."
                        type="text"
                        value={searchQuery}
                        onChange={(event) => setSearchQuery(event.target.value)}
                      />
                      <button type="submit" className='search_button_color' onClick={handleSearchChange}>
                        <FontAwesomeIcon className="mb-0 text-white text-end me-1" icon={faSearch} style={{   cursor: "pointer" }} />
                      </button>
                    </div>
                  </div>
                  <div className="card-body p-3">
                    <div className="row pb-3">
                      {isLoading ? (
                        <div className="d-flex justify-content-center my-5 pt-5">
                          <div className='pt-5 mt-5'>
                            <BeatLoader className='mt-5' />
                          </div>
                        </div>

                      ) : (
                        filteredData.map((item) => (
                          <div className='col-lg-4 col-sm-6 my-2' key={item.id}>
                            <div className="card px-4 pt-4 ">
                              {item.images && item.images.length > 0 && item.images[0].image ? (
                                <img src={`data:image/jpeg;base64,${item.images[0].image}`} height={220} alt="Venue Image" />
                              ) : (
                                <img src={Images.mahall} height={220} alt='' />
                              )}
                              <h6 className='mt-2'>{item.mandapam_name}</h6>
                              <p className="price" style={{ fontSize: "18px" }}>{truncateText(item.address, 40)}</p>
                              {/* <p className="price" style={{ fontSize: "18px", minHeight: "80px" }}>{item.address}</p> */}
                              <p>
                                <button type="button" className="btn btn-outline-primary btn-sm mb-4" onClick={() => { navigate('/Editpage', { state: { id: item.id } }); }}>
                                  View Details
                                </button>
                              </p>
                            </div>

                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center my-3">
                  <ul className="pagination">
                    {renderPaginationButtons()}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default AllPlaces;
