import './App.css';
import * as React from 'react';
import  { useEffect } from 'react';

import { BrowserRouter,  Routes,Route,useNavigate} from 'react-router-dom';
import Enquiry from './View/Enquiry';
import PremiumPartner from './View/PremiumPartner/Index';
import AllPlaces from './View/AllPlaces';
import Settings from './View/Settings';
import Signin from './View/Signup/Signin';
import Signup from './View/Signup/Signup';
import AddNew from './View/AddNew';
import Dashboard from './View/Dashboard';
import RequestLocation from './View/RequestLocation';
import PolicyCondition from './View/PolicyCondition';
import ManageBooking from './View/ManageBooking/Index';
import Editpage from './View/EditPage/Editpage';
import ForgotPassword from './View/Signup/ForgotPassword';
// import Partner from './View/Admin/Partner';
import BulkUpload from './View/BulkUpload';
import Otp from './View/Signup/Otp';
import ResetPassword from './View/Signup/ResetPassword';
import Partners from './View/Admin/Partners';
import List from './View/ManageBooking/list/List';
import ManageMasterData from './View/Admin/ManageMasterData';
import Data from './View/BulkUpload/Data';
import SuperAdmin from './View/SuperAdmin';
import LoginOtp from './View/Signup/LoginOtp';
import LandingPage from './View/LandingPage';
import AssignPartner from './View/Admin/AssignPartner/AssignPartner';
import Second from './View/LandingPage/Second';
import FullDetails from './View/LandingPage/FullDetails';
import PrivateRoute from './Routes';
import Gallery from './View/LandingPage/Gallery';
import Form from './View/LandingPage/Form';
import EditAdminpage from './View/EditPage/EditAdminPage';
import Setting from './View/SuperAdmin/Settings';
import EnquiryAdmin from './View/Enquiry/EnquiryAdmin';
import Admintable  from './View/ManageBooking/Admintable';
import Partnertable from './View/ManageBooking/Partnertable';
import Contactdetail from './View/Contact/Contactdetail';
import Session from './View/AddNew/Components/Session/Session';
// import Session from '../src/View/AddNew/Components/Session';
import Managem from './View/ManageBooking/maga';



function App() {
  const token = JSON.parse(sessionStorage.getItem('data'));




  return (

    <div className="">
      
      <BrowserRouter>
      <Session timeout={ 300000} />
        <Routes>


          <Route path='/' element={<Signin />}></Route>       
          <Route path='/Signup' element={<Signup />}></Route>
          <Route path='/ForgotPassword' element={<ForgotPassword />}></Route>
          <Route path='/Otp' element={<Otp />}></Route>
          <Route path='/ResetPassword' element={<ResetPassword />}></Route>
          <Route path='/maga' element={< Managem/>}></Route>

          <Route element={<PrivateRoute />}>
            <Route path='/dashboard' element={<Dashboard />}></Route>

            <Route path='/AddNew' element={<AddNew />}></Route>
            <Route path='/ManageBooking' element={<ManageBooking />}></Route>
            <Route path='/PolicyCondition' element={<PolicyCondition />}></Route>
            <Route path='/Enquiry' element={<Enquiry />}></Route>
            <Route path='/Enquirys' element={<EnquiryAdmin />}></Route>

            <Route path='/RequestLocation' element={<RequestLocation />}></Route>
            <Route path='/PremiumPartner' element={<PremiumPartner />}></Route>
            <Route path='/AllPlaces' element={<AllPlaces />}></Route>
            <Route path='/Settings' element={<Settings />}></Route>
            <Route path='/Setting' element={<Setting />}></Route>

            <Route path='/Editpage' element={<Editpage />}></Route>
            <Route path='/Partners' element={<Partners />}></Route>
            <Route path='/Bulkupload' element={<BulkUpload />}></Route>
            <Route path='/List' element={<List />}></Route>
            <Route path='/ManageMasterData' element={<ManageMasterData />}></Route>
            <Route path='/CreateAdmin' element={<SuperAdmin />}></Route>
            <Route path='/LoginOtp' element={<LoginOtp />}></Route>
            <Route path='/LandingPage' element={<LandingPage />}></Route>
            <Route path='/AssignPartner' element={<AssignPartner />}></Route>
            <Route path='/Second' element={<Second />}></Route>
            <Route path='/FullDetails' element={<FullDetails />}></Route>
            <Route path='/Gallery' element={<Gallery />}></Route>
            <Route path='/Form' element={<Form />}></Route>
            <Route path='/EditAdminpage' element={<EditAdminpage />}></Route>
            {/* <Route path='/List' element={<List />}></Route> */}
            <Route path='/Admintable' element={<Admintable/>}></Route>
            <Route path='/Partnertable' element={<Partnertable/>}></Route>
            <Route path='/Contactdetail' element={<Contactdetail/>}></Route>

            <Route path='/Session' element={<Session/>}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
 





    </div>

  );
};


export default App;
