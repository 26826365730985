import React, { useContext, useState, useEffect } from "react";
import Avatar from '@material-ui/core/Avatar';
import { Modal, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import Aside from '../../../Layout/Aside';
import Navbar from '../../../Layout/Navbar';
import avathar from "../../../Images/avathar.jpg";
import { toast, Toaster } from 'react-hot-toast';
import { apiURL } from "../../../Layout/Common/confiqurl";
import { MainContext } from '../../../Store/Context';
import { validPasswordRegex, validEmailRegex, validPhoneNumberRegex } from '../../../Layout/Common/common';
import Footer from '../../../Layout/Footer'


const Partners = () => {
  const { user } = useContext(MainContext)
  const [allPartners, setAllPartners] = useState([]);
  const [displayedItems, setDisplayedItems] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [openpopupAddPartner, setopenpopupAddPartner] = useState(false);
  const [openpopupDelete, setopenpopupDelete] = useState(false);
  const [openpopupView, setopenpopupView] = useState(false);
  const [deleteValue, setDeleteValue] = useState();
  const [PartnerUserName, setPartnerUserName] = useState('');
  const [PartnerContactNumber, setPartnerContactNumber] = useState('');
  const [PartnerEmail, setPartnerEmail] = useState('');
  const [PartnerAddress, setPartnerAddress] = useState('');
  const [PartnerPassword, setPartnerPassword] = useState('');
  const [PartnerConfirmPswd, setPartnerConfirmPswd] = useState('');
  const [usercontact_number, setUserContactNumber] = useState('');
  const [editedData, setEditedData] = useState({});
  const [viewValue, setviewValue] = useState();
  const [openpopupView1, setopenpopupView1] = useState(false);
  const [profile_image, setProfileImage] = useState('');
  const [user_name, setUsernamevalue] = useState('');
  const [useremail_address, setUserEmailAddress] = useState('');
  const [user_address, setUserAddress] = useState('');
  const [user_role, setUserRole] = useState('');
  const [editeValue, setEditValue] = useState();
  const [data, setData] = useState({
    contact_number: "",
    email_address: "",
    user_name: "",
    address: "",
    status: "",
  })
  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);
  const getData = () => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    axios.get(`${apiURL}/All_partner_user_details`,
      {
        headers: {
          'Authorization': `Bearer ${project.access_token}`,
          "accept": "application/json",
          "Content-Type": " application/json",
        }
      },)
      .then((response) => {
        setAllPartners(response.data);
        setDisplayedItems(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sortedItems = [...displayedItems].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setSortConfig({ key, direction });
    setDisplayedItems(sortedItems);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handleClick = (page) => {
    setCurrentPage(page);
  };
  const handlePhoneChange = (event) => {
    const value = event.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setPartnerContactNumber(value);
    }
  };

  const filteredData = displayedItems.filter(item =>
    item.user_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredData.length);

  const paginationButtons = [];
  const pages = Math.ceil(filteredData.length / itemsPerPage);
  const maxPageButtons = 5;  

  const getVisiblePageNumbers = () => {
    const half = Math.floor(maxPageButtons / 2);
    let start = Math.max(1, currentPage - half);
    let end = Math.min(pages, currentPage + half);

    if (currentPage <= half) {
      end = Math.min(pages, maxPageButtons);
    } else if (currentPage + half >= pages) {
      start = Math.max(1, pages - maxPageButtons + 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const visiblePageNumbers = getVisiblePageNumbers();

  for (const pageNum of visiblePageNumbers) {
    paginationButtons.push(
      <li key={pageNum} className={currentPage === pageNum ? "active" : ""} style={{ cursor: "pointer" }}>
        <a
          className={currentPage === pageNum ? "active" : ""}
          onClick={() => handleClick(pageNum)}
        >{pageNum}</a>
      </li>
    );
  }

  const openModalAddPartner = () => {
    setopenpopupAddPartner(true);
  }
  const deletePartner = (user_id) => {
    setopenpopupDelete(true);
    setDeleteValue(user_id)
  }
  const closeModalAddPartner = () => {
    setopenpopupAddPartner(false);
  };
  const handleAddPartner = () => {
    if (!PartnerUserName || !PartnerContactNumber || !PartnerEmail || !PartnerPassword || !PartnerConfirmPswd || !PartnerAddress) {
      toast.error("Please Fill in all Mandatory Fields.");
      return;
    }
    const project = JSON.parse(sessionStorage.getItem('data'));
    const payload = {
      user_name: PartnerUserName,
      contact_number: PartnerContactNumber,
      email_address: PartnerEmail,
      address: PartnerAddress,
      password: PartnerPassword,
      confirm_password: PartnerConfirmPswd,

    }
    if (validEmailRegex.test(PartnerEmail)) {
      if (validPasswordRegex.test(PartnerPassword && PartnerConfirmPswd)) {
        if (validPhoneNumberRegex.test(PartnerContactNumber)) {
          axios.post(`${apiURL}/signup`, payload,
            {
              headers: {
                'Authorization': `Bearer ${project.access_token}`,
                "accept": "application/json",
                "Content-Type": " application/json",
              },
            },)
            .then((res) => {
              if (res.status === 200) {
                toast.success('Partner Added Successfully');
                getData();
                closeModalAddPartner();
              } else if (res.status === 400) {
                toast.error('Partner Already Exists');
              }
            })
            .catch((error) => {
              if (error.response && error.response.data && error.response.data.detail) {
                toast.error(error.response.data.detail);
              } else {
                toast.error('Failed To Add Partner');
              }
            });
        } else {
          toast.error('Invalid Phone Number');
        }
      } else {
        toast.error('Invalid Password');
      }
    } else {
      toast.error('Invalid Email Address');
    }
  };

  const handleView = (user_id) => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    axios.get(`${apiURL}/user_details?user_id=${user_id}`,
      {
        headers: {
          'Authorization': `Bearer ${project.access_token}`,
          "accept": "application/json",
          "Content-Type": " application/json",
        }

      },)
      .then((res) => {
        setEditedData(res.data);
        setProfileImage(res.data.profile_image);
        setUsernamevalue(res.data.user_name);
        setUserEmailAddress(res.data.email_address);
        setUserAddress(res.data.address);
        setUserRole(res.data.user_role);
        setUserContactNumber(res.data.contact_number);
        setEditedData(res.data)
      });
  }
  const openModalView = (id) => {
    setopenpopupView(true);
    setviewValue(id)
  }
  const openModalView1 = (id) => {
    handleView(id);
    setEditedData({ ...data });
    setopenpopupView1(true);
    setviewValue(id)
    setEditValue(id)
  }
  const openModalDelete = (user_id) => {
    setopenpopupDelete(true);
    setDeleteValue(user_id)
  }
  const closeModalDelete = () => {
    setopenpopupDelete(false);
  };

  const truncateText = (text, maxLength) => { 
    if (text != null) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      }
      return text;
    } else {
      return "";
    }

  };

  const closeModalView = () => {
    setopenpopupView(false);
  };

  const handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  const handleDelete = () => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    axios.delete(`${apiURL}/user_details?id=${deleteValue}`, {
      headers: {
        'Authorization': `Bearer ${project.access_token}`,
        "accept": "application/json",
        "Content-Type": "application/json",
      }
    })
      .then((res) => {
        if (res.status === 200) {
          getData();
          closeModalDelete();
          toast.success(res.data.detail);
        } else {
          closeModalDelete();
          toast.error("We can't delete a partner since it is mapped with other data.");
        }
      })
      .catch((error) => {
        if (error.response) {
          closeModalDelete();
          toast.error("We can't delete a partner since it is mapped with other data.");
        } else if (error.request) {
          closeModalDelete();
          toast.error("No response received from server.");
        } else {
          closeModalDelete();
          toast.error("An error occurred while setting up the request.");
        }
      });
  };
  const closeModalView1 = (id) => {
    setviewValue(id)
    setopenpopupView1(false);
  };
  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleStatusChange = (activeStatus) => {
    setEditedData((prevData) => ({
      ...prevData,
      active_status: activeStatus,
    }));
  };
  const geteditmasterdata = (editeValue, user_id) => {
    const token = JSON.parse(sessionStorage.getItem('data'));
    let hasError = false;
 
   if (!editedData.user_name || editedData.user_name.trim().length < 2 || editedData.user_name.trim().length > 50) {
    toast.error('Partner Name is required and must be between 2 to 50 characters long.');
    hasError = true;
} else if (!/^[a-zA-Z\s]+$/.test(editedData.user_name)) {
    toast.error('Partner Name should only contain alphabets and spaces.');
    hasError = true;
} 

if (!editedData.contact_number || !/^\d{10}$/.test(editedData.contact_number)) {
    toast.error('Contact Number is Required and should be 10 digits.');
    hasError = true;
}

if (!editedData.email_address || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(editedData.email_address)) {
    toast.error('A Valid Email Address is required.');
    hasError = true;
}

if (!editedData.address || editedData.address.length < 5) {
    toast.error('Address is Required.');
    hasError = true;
}

if (typeof editedData.active_status !== 'boolean') {
    toast.error('Status is required.');
    hasError = true;
}

if (editedData.hash_password && (editedData.hash_password.length < 6 || !/[a-z]/.test(editedData.hash_password) || !/[A-Z]/.test(editedData.hash_password) || !/[0-9]/.test(editedData.hash_password))) {
    toast.error('Password must be at least 6 characters long and include a mix of letters and numbers.');
    hasError = true;
}


if (hasError) {
    return;
}
    const payloadData = {
      user_name: editedData.user_name,
      contact_number: editedData.contact_number,
      email_address: editedData.email_address,
      hash_password: editedData.hash_password,
      address: editedData.address ? editedData.address : '',
      profile_image: editedData.profile_image ? editedData.profile_image : '',
      active_status: editedData.active_status,
      updated_by: token.user_name,
    };
    axios.put(
      `${apiURL}/edit_partner_data/?id=${editeValue}`, payloadData,
      {
        headers: {
          'Authorization': `Bearer ${token.access_token}`,
          'accept': 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message);
        getData();
        closeModalView1();
      }
    }).catch((err) => {
      console.log(err);
      toast.error('Partner Already Exists');
    });
  }


  return (
    <div className={user ? "g-sidenav-show bg-gray-100 g-sidenav-hidden " : "g-sidenav-show  bg-gray-100"} >
      <Aside />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Navbar Name='Partner' />
        <div className="container-fluid py-4" style={{ minHeight: "560px" }}>
          <Toaster toastOptions={{ position: "top-right" }} />
          <div className="row ">
            <div className="col-12">
              <div className="card">
                <div className="card-header mt-2 d-flex justify-content-between">
                  <div>
                    <h5 class="font-weight-bolder mb-0"> All Partners</h5>
                  </div>
                  <button type="button" class="btn btn-outline-primary mx-2 btn-sm mb-0" data-bs-toggle="modal" style={{ wordSpacing: "0.5em" }} onClick={() => { openModalAddPartner(); }}>
                    + Add Partners
                  </button>
                </div>
                <div className="table-responsive">
                  <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                    <div className="dataTable-top">
                      <div className="dataTable-dropdown">
                        <label style={{ width: "300px" }}>
                          <select class="dataTable-selector w-50 me-2"
                            onChange={handleItemsPerPageChange}
                            style={{ cursor: "pointer" }}>
                            <option value="5" style={{ cursor: "pointer" }} >5</option>
                            <option value="10" style={{ cursor: "pointer" }}>10</option>
                            <option value="15" style={{ cursor: "pointer" }}>15</option>
                            <option value="20" style={{ cursor: "pointer" }}>20</option>
                          </select>
                          entries per page</label>
                      </div>
                      <div className="dataTable-search">
                        <input className="dataTable-input" placeholder="Search..."
                          value={searchQuery}
                          onChange={(event) => {
                            setSearchQuery(event.target.value);
                            setCurrentPage(1);
                          }}
                          type="text" />
                      </div>
                    </div>
                    <div className="dataTable-container" >
                      <table className="table table-flush dataTable-table" id="datatable-search">
                        <thead className="thead-light">
                          <tr>
                            <th style={{ width: "18%", cursor: "pointer" }} onClick={() => handleSort('user_name')}>
                              <a className=""><label> Partner Name
                                {sortConfig.key === 'user_name' && (<span className={`sort_icon`}>{sortConfig.direction === 'ascending' ? '▲' : '▼'}</span>)}
                              </label></a>
                            </th>
                            <th style={{ width: "10%", cursor: "pointer" }} onClick={() => handleSort('contact_number')}>
                              <a className=""><label>Partner Contact{sortConfig.key === 'contact_number' && (<span className={`sort_icon`}>{sortConfig.direction === 'ascending' ? '▲' : '▼'}</span>)}</label></a>
                            </th>
                            <th style={{ width: "10%", cursor: "pointer" }} onClick={() => handleSort('email_address')}>
                              <a className=" "><label>Partner Email{sortConfig.key === 'email_address' && (<span className={`sort_icon`}>{sortConfig.direction === 'ascending' ? '▲' : '▼'}</span>)}</label></a>
                            </th>
                            <th style={{ width: "18%", cursor: "pointer" }} onClick={() => handleSort('address')}>
                              <a className=" "><label>Address{sortConfig.key === 'address' && (<span className={`sort_icon`}>{sortConfig.direction === 'ascending' ? '▲' : '▼'}</span>)}</label></a>
                            </th>

                            <th style={{ width: "5%", cursor: "pointer" }} onClick={() => handleSort('active_status')}>
                              <a className=""> <label>Status{sortConfig.key === 'active_status' && (<span className={`sort_icon`}>{sortConfig.direction === 'ascending' ? '▲' : '▼'}</span>)}</label></a>
                            </th>
                            <th style={{ width: "15%", cursor: "pointer" }} onClick={() => handleSort('created_at')} >
                              <a className=""> <label>Created Date{sortConfig.key === 'created_at' && (<span className={`sort_icon`}>{sortConfig.direction === 'ascending' ? '▲' : '▼'}</span>)}</label></a>
                            </th>
                            <th style={{ width: "18%", cursor: "pointer" }} >
                              <label>Actions</label>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.slice(startIndex, endIndex).map((item, i) =>
                            <tr key={i + 1}>

                              <td className="text-sm  " ><div className='d-flex justify-content-start'><div> <Avatar alt="Remy Sharp" src={avathar} /></div><div className='mt-2 ms-3 '> {item.user_name}</div></div></td>
                              <td className="text-sm" style={{ whiteSpace: 'pre-wrap' }}>{item.contact_number}</td>
                              <td className="text-sm" style={{ whiteSpace: 'pre-wrap' }}>{item.email_address}</td>
                              <td className="text-sm " >{truncateText(item.address, 20)}</td>
                              <td className="text-sm"
                              >
                                {item.active_status ? (
                                  <span className="badge badge-success badge-sm">Active</span>
                                ) : (
                                  <span className="badge badge-danger badge-sm">Inactive</span>
                                )}
                              </td>
                              <td className="text-sm" style={{ whiteSpace: 'pre-wrap' }}>{new Date(item.created_at).toLocaleDateString()}</td>

                              <td className="text-sm"  >
                                <a data-bs-toggle="tooltip" data-bs-original-title="Preview product" onClick={() => { handleView(item.user_id); openModalView(item.user_id); }}>
                                  <i className="text-secondary" aria-hidden="true" >
                                    <FontAwesomeIcon icon={Icons.faEye} />
                                  </i>
                                </a>
                                <a data-bs-toggle="tooltip" className='mx-3' data-bs-original-title="Edit Hall" onClick={() => { handleView(item.user_id); openModalView1(item.user_id); }}>
                                  <i class="text-secondary" aria-hidden="true">
                                    <FontAwesomeIcon icon={Icons.faPen} />
                                  </i>
                                </a>
                                <a data-bs-toggle="tooltip" data-bs-original-title="Delete Hall" onClick={() => openModalDelete(item.user_id)} >
                                  <i className="text-secondary" aria-hidden="true">
                                    <FontAwesomeIcon icon={Icons.faTrash} />
                                  </i>
                                </a>
                              </td>

                            </tr>
                          )}

                        </tbody>
                      </table>
                    </div>
                    <div className="dataTable-bottom mt-5">
                      <div className="dataTable-info">Showing {startIndex + 1} to {endIndex} of {filteredData.length} entries</div>
                      <nav className="dataTable-pagination">

                        <ul className="dataTable-pagination-list">
                          <li className="pager" style={{ cursor: "pointer" }}>

                            <a
                              onClick={() => handleClick(currentPage - 1)}
                              className={currentPage === 1 ? "button-disable" : ""}


                            > ‹ </a>
                          </li>
                          {paginationButtons}

                          <li className="pager" style={{ cursor: "pointer" }}><a data-page="2"
                            onClick={() => handleClick(currentPage + 1)}

                            className={currentPage === pages ? "button-disable" : ""}
                          >›</a></li>
                        </ul></nav>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <Modal show={openpopupAddPartner} size='lg' className='model-popup-design' >
            <div className='d-flex justify-content-between mt-3' >
              <Modal.Title className='ms-4 '>Add Partner</Modal.Title>
              <button className='me-3 btn btn-outline-secondary  ' onClick={closeModalAddPartner}>
                x
              </button>
            </div>
            <div className='loginmodalbody' style={{ width: 'auto' }}>
              <Modal.Body className='testing-test' >
                <Form.Group >
                  <Form.Label>Partner Name: <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={(e) => setPartnerUserName(e.target.value)}
                    value={PartnerUserName}
                    placeholder="Partner Name"
                    required
                  />
                </Form.Group>
                <Form.Group >
                  <Form.Label>Contact Number: <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handlePhoneChange}
                    value={PartnerContactNumber}
                    placeholder="Contact Number"
                    required
                  />
                </Form.Group>
                <Form.Group >
                  <Form.Label>Email Address: <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={(e) => setPartnerEmail(e.target.value)}
                    value={PartnerEmail}
                    placeholder="Email Address"
                    required
                  />
                </Form.Group>
                <Form.Group >
                  <Form.Label> Partner Address: <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="name"
                    onChange={(e) => setPartnerAddress(e.target.value)}
                    value={PartnerAddress}
                    placeholder="Partner Address"
                    required
                  />
                </Form.Group>
                <Form.Group >
                  <Form.Label>Password: <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={(e) => setPartnerPassword(e.target.value)}
                    value={PartnerPassword}
                    placeholder="Password"
                    required
                  />
                  <Form.Text className="text-muted">
                    <ul style={{ color: 'black', paddingLeft: '20px' }}>
                      <li>Password should be minimum 8 characters</li>
                      <li>First letter should be uppercase</li>
                      <li>Password should contain lowercase letters, numbers and  at least one special character</li>
                    </ul>
                  </Form.Text>
                </Form.Group>
                <Form.Group >
                  <Form.Label>Re-Enter Password: <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={(e) => setPartnerConfirmPswd(e.target.value)}
                    value={PartnerConfirmPswd}
                    placeholder="Re-Enter Password"
                    required
                  />
                </Form.Group>
              </Modal.Body>
            </div>
            <Modal.Footer>
              <Button variant="success" style={{ backgroundColor: "#cb0c9f" }} onClick={handleAddPartner}>
                Add Partner
              </Button>
              <Button variant="secondary" style={{
                backgroundColor: 'white',
                color: '#cb0c9f',
                border: '1px solid #cb0c9f',
              }} onClick={closeModalAddPartner}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>


          <Modal show={openpopupView} size='lg' className='model-popup-design' >
            <div className='d-flex justify-content-between mt-3' >
              <Modal.Title className='ms-4 '>View Details</Modal.Title>
              <button className='me-3 btn btn-outline-secondary  ' onClick={closeModalView}>
                x
              </button>
            </div>
            <div className='ms-4'>
              {profile_image ? (
                <img className="rounded-circle me-3" style={{ width: '50px', height: '50px' }} src={profile_image} alt="Profile" />
              ) : (
                <img className="rounded-circle me-3" style={{ width: '50px', height: '50px' }} src={avathar} alt="Default" />
              )}
            </div>
            <div className='loginmodalbody' style={{ width: 'auto' }}>
              <Modal.Body className='testing-test'>
                <Form.Group >
                  <Form.Label>Name:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={user_name}
                    placeholder="Name input"
                    disabled='true'
                  />
                </Form.Group>
                <Form.Group >
                  <Form.Label>Address:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={user_address != null
                      ? user_address : "No Address Found"
                    }
                    placeholder="Name input"
                    disabled='true'
                  />
                </Form.Group>
                <Form.Group >
                  <Form.Label>User Role:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={user_role}
                    placeholder="Name input"
                    disabled='true'
                  />
                </Form.Group>
                <Form.Group >
                  <Form.Label>Contact No:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={usercontact_number != null
                      ? usercontact_number : "No Contact No Found"
                    }
                    placeholder="Name input"
                    disabled='true'
                  />
                </Form.Group>
                <Form.Group >
                  <Form.Label>Email Address:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={useremail_address != null
                      ? useremail_address : "No Email Address Found"
                    }
                    placeholder="Name input"
                    disabled='true'
                  />
                </Form.Group>
              </Modal.Body>
            </div>
            <Modal.Footer>
              <Button variant="secondary" style={{
                backgroundColor: 'white',
                color: '#cb0c9f',
                border: '1px solid #cb0c9f',
              }} onClick={closeModalView}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={openpopupDelete} size='md' className='model-popup-design' >
            <div className='d-flex justify-content-between mt-3' >
              <Modal.Title className='ms-4 '>Delete</Modal.Title>
              <button className='me-3 btn btn-outline-secondary  ' onClick={closeModalDelete}>
                x
              </button>
            </div>
            <div className='loginmodalbody' style={{ width: '785px' }}>
              <Modal.Body className='testing-test' >
                Are you sure you want to delete this item ?
              </Modal.Body>
            </div>
            <Modal.Footer>
              <Button variant="danger" style={{
                backgroundImage: 'linear-gradient(310deg, #7928ca, #ff0080)',
                color: 'white',
                border: 'none',
              }} onClick={handleDelete}>
                Delete
              </Button>
              <Button variant="secondary" style={{
                backgroundColor: 'white',
                color: '#cb0c9f',
                border: '1px solid #cb0c9f',
              }} onClick={closeModalDelete}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={openpopupView1} size='lg' className='model-popup-design' >
            <div className='d-flex justify-content-between mt-3' >
              <Modal.Title className='ms-4 '>Update Details</Modal.Title> <button className='me-3 btn btn-outline-secondary' onClick={closeModalView1}> x </button>
            </div>
            <div className='ms-4'>
            </div>
            <div className='loginmodalbody' style={{ width: 'auto' }}>
              <Modal.Body className='testing-test'>
                <Form.Group >
                  <Form.Label> Partner Name: <span style={{ color: 'red' }}>*</span> </Form.Label>
                  <Form.Control type="text" name="user_name" onChange={handleChange1} value={editedData.user_name} placeholder="Partner Name" required />
                </Form.Group>
                <Form.Group >
                  <Form.Label> Partner Email: <span style={{ color: 'red' }}>*</span> </Form.Label>
                  <Form.Control type="text" name="email_address" onChange={handleChange1} value={editedData.email_address} placeholder="Partner Email" required />
                </Form.Group>
                <Form.Group >
                  <Form.Label>  Partner contact no: <span style={{ color: 'red' }}>*</span> </Form.Label>
                  <Form.Control type="text" name="contact_number" onChange={handleChange1} value={editedData.contact_number} placeholder="Partner contact no" required />
                </Form.Group>
                <Form.Group >
                  <Form.Label> Address: <span style={{ color: 'red' }}>*</span> </Form.Label>
                  <Form.Control as="textarea" rows={3} name="address" onChange={handleChange1} value={editedData.address} placeholder="Address" required />
                </Form.Group>
                <Form.Group className="radio-custom">
                  <Form.Label>
                    Status: <span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <div>
                    <Form.Check inline label="Active" type="radio" id="activeRadio" name="active_status"
                      checked={editedData.active_status === true} onChange={() => handleStatusChange(true)} style={{ marginRight: '10px', borderRadius: '10px' }}
                      className={editedData.active_status === true ? 'radio-active' : 'radio-inactive'} required />
                    <Form.Check
                      inline
                      label="Inactive"
                      type="radio"
                      id="inactiveRadio"
                      name="active_status"
                      checked={editedData.active_status === false} 
                      onChange={() => handleStatusChange(false)}
                      style={{ marginRight: '10px' }}  
                      className={editedData.active_status === false ? 'radio-active' : 'radio-inactive'} 
                      required
                    />
                  </div>
                </Form.Group>
              </Modal.Body>
            </div>
            <Modal.Footer>
              <Button variant="secondary" style={{
                backgroundImage: 'linear-gradient(310deg, #7928ca, #ff0080)',
                color: 'white',
                border: 'none',
              }} onClick={() => geteditmasterdata(editeValue)}>
                update
              </Button>
              <Button variant="secondary" style={{
                backgroundColor: 'white',
                color: '#cb0c9f',
                border: '1px solid #cb0c9f',
              }} onClick={closeModalView1}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

        </div>
        <Footer />
      </main>
    </div >
  );
};

export default Partners;