import React, { useContext, useState } from 'react'
import Navbar from './Navbar'
import Footer from '../../Layout/Footer'
import { MainContext } from '../../Store/Context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Icons } from '../../Icons/IconIndex'
import team_2 from "../../Images/team-2.jpg";
import team_3 from "../../Images/team-3.jpg";
import team_4 from "../../Images/team-4.jpg";
import { Avatar } from '@material-ui/core'
import { useNavigate, Link } from 'react-router-dom'
import { apiURL } from '../../Layout/Common/confiqurl';
import toast, { Toaster } from 'react-hot-toast';
import Axios from 'axios';
import { maxLength, numberMethod, validPasswordRegex, validEmailRegex, validPhoneNumberRegex } from '../../Layout/Common/common';
import { useEffect } from 'react'
import "./style.css"
import { Label } from 'recharts'




const SuperAdmin = () => {
    const { user } = useContext(MainContext)
    const [showPopup, setShowPopup] = useState(false);

    const [isToastOpen, setIsToastOpen] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');
   
    const [data, setData] = useState([]);
    const [recordData, setRecordData] = useState([]);
  
    const [hasRecords, setHasRecords] = useState();

    const navigate = useNavigate()
    const [icons, setIcons] = useState(true)
    const [icons1, setIcons1] = useState(true)

    const [state, setState] = useState({

        password: '',
        email: '',
        confirmPassword: '',
    })
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
        const value = event.target.value;
        if (/^\d{0,10}$/.test(value)) { // Limit input to 10 numbers
            setInputValue(value);
        }
    };
    const [usernamevalue, setUsernamevalue] = useState('');

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
    };

    useEffect(() => {
        getData()
    }, []);
    const getData = () => {
        const project = JSON.parse(sessionStorage.getItem('data'));

        Axios.get(`${apiURL}/All_admin_user_details`,
            {
                headers: {
                    'Authorization': `Bearer ${project.access_token}`,

                    "accept": "application/json",
                    "Content-Type": " application/json",
                }


            },)
            .then((response) => {
                console.log(response.data);
                setData(response.data)
                setRecordData(response.data)
            })
    }
    const filteredData = data.filter((item) => {
        const itemValues = Object.values(item).join('').toLowerCase();
        return (
            itemValues.includes(searchQuery.toLowerCase()));
    });

  

    const handleInputNameChange = (event) => {
        const capitalizedValue = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        setUsernamevalue(capitalizedValue);
    }

    const handleSignup = (event) => {
        event.preventDefault();
        if (!isToastOpen) {
            setIsToastOpen(true);

            const options = {
                // firstname: state.name,
                user_name: usernamevalue,
                contact_number: inputValue,
                email_address: state.email,
                password: state.password,
                confirm_password: state.confirmPassword,
                // role:state.role
            }
          

            //  if (state.name.trim()) {
            if (usernamevalue.trim()) {
                if (validPhoneNumberRegex.test(inputValue)) {
                    if (validEmailRegex.test(state.email)) {

                        if (state.password.length >= 8 && validPasswordRegex.test(state.password)) {
                            if (state.confirmPassword === state.password) {
                                Axios.post(`${apiURL}/superadmin`, options, {
                                    headers: {
                                        "accept": "application/json",
                                        "Content-Type": " application/json"
                                    }
                                },
                                ).then((response) => {
                              
                                    getData()
                                    setState({

                                        password: '',
                                        email: '',
                                        confirmPassword: '',
                                    })
                                    setInputValue("")
                                    setUsernamevalue("")
                                    toast.success(' Admin Created Successfully')


                                })
                                    .catch((err) => {

                                        console.log(err.response.data.detail)
                                        toast.error(err.response.data.detail)
                                        // setIsLoading(false);



                                    });
                            } else {
                                toast.error('Please Enter Same as Password');
                            }
                        } else {
                            toast.error('Please Enter Valid Password');
                        }

                    } else {
                        toast.error('Please Enter Valid Email Address');
                    }
                } else {
                    toast.error('Please Enter Mobile Number');
                }
            } else {
                toast.error('Please Enter Username');
            }
            setTimeout(() => {
                setIsToastOpen(false);
            }, 3000); // set the timeout based on how long the toast message will be displayed
        }

    }
    console.log(usernamevalue, "klkk");


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentPages = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredData.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleInputFocus = () => {
        setShowPopup(true);
    };

 
    const filter = (event) => {
        const searchQuery = event.target.value.toLowerCase();
        const filteredData = data.filter(f => f.user_name.toLowerCase().includes(searchQuery));
        const hasRecords = filteredData.length > 0;

        setRecordData(filteredData);
        setHasRecords(hasRecords);
    }
  

    return (
        <div className={user ? "g-sidenav-show bg-gray-100 g-sidenav-hidden " : "g-sidenav-show  bg-gray-100"} >
            <Toaster toastOptions={{ position: "top-right" }} />
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

                <Navbar Name='Super Admin' />



                <div className="container-fluid pt-4" style={{ minHeight: "360px" }}>
                    <div class="row">
                        <div class="col-12  m-auto">
                            <form class="multisteps-form__form mb-8" style={{ height: "408px;" }}>

                                <div class="card multisteps-form__panel p-3 border-radius-xl bg-white js-active" data-animation="FadeIn">
                                    <h5 class="font-weight-bolder mb-0"> Admin Management</h5>
                                    {/* <p class="mb-0 text-sm">Mandatory informations</p> */}
                                    <div class="multisteps-form__content">
                                        <div class="row mt-3">
                                            <div class="col-12 col-sm-4">
                                                <label>Username</label>
                                                <input type="text" class="form-control " placeholder="Username" aria-label="username" name='username' value={usernamevalue} onChange={handleInputNameChange} />
                                            </div>
                                            <div class="col-12 col-sm-4 mt-3 mt-sm-0">
                                                <label>Mobile Number</label>
                                                <div class="">
                                                    <input type="text" class="form-control"
                                                        placeholder='  Mobile Number'
                                                        id="input"
                                                        maxLength={10} // Set the maximum length of input to 10
                                                        pattern="[0-9]*" // Only allow numbers
                                                        value={inputValue}
                                                        onChange={handleInputChange}
                                                    />


                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-4">
                                                <label>Email </label>
                                                <input type="email" class="form-control" placeholder="Email" aria-label="Email" name='email' value={state.email} onChange={handleChange} />

                                            </div>


                                        </div>

                                        <div class="row mt-3">

                                            <div class="col-6 col-sm-6 mt-3 ">
                                                <label>Password</label>
                                                <input type={icons ? "password" : "text"} class="form-control" placeholder="Password" onFocus={handleInputFocus} aria-label="Password" name='password' value={state.password} onChange={handleChange} />
                                                <div className='d-flex justify-content-end me-3'>
                                                    <label class="input-icon">
                                                        <i onClick={() => setIcons(!icons)} className="text-black" style={{ fontSize: "24px", cursor: "pointer" }}>
                                                            {
                                                                icons ? (<FontAwesomeIcon icon={Icons.faEye} />
                                                                ) : (<FontAwesomeIcon icon={Icons.faEyeSlash} />
                                                                )
                                                            }
                                                        </i>
                                                    </label>
                                                </div>
                                                {showPopup && (
                                                    <div className="">
                                                        <p style={{ fontSize: "13px" }}>A minimum 8 characters password contains a first letter should uppercase ,lowercase special characters and number are required</p>
                                                    </div>
                                                )}
                                            </div>
                                            <div class="col-6 col-sm-6 mt-3   ">
                                                <label>Confirm Password</label>

                                                <input type={icons1 ? "password" : "text"} class="form-control" placeholder="Confirm Password" aria-label="Password" name='confirmPassword' value={state.confirmPassword} onChange={handleChange} />
                                                <span class="input-group-text">
                                                    <i onClick={() => setIcons1(!icons1)} className="text-black" style={{ fontSize: "24px", cursor: "pointer" }}>
                                                        {
                                                            icons1 ? (<FontAwesomeIcon icon={Icons.faEye} />
                                                            ) : (<FontAwesomeIcon icon={Icons.faEyeSlash} />
                                                            )
                                                        }
                                                    </i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="button-row d-flex mt-4">
                                            <button class="btn bg-gradient-dark ms-auto mb-0 js-btn-next " type="button" title="Next" onClick={handleSignup}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>



                </div>
                <div className="container-fluid mt-5" style={{ minHeight: "460px" }}>


                    <div className="row ">
                        <div className="col-12">
                            <div className="card" >

                                <div className="card-header mt-2 d-flex justify-content-between">
                                    <div>
                                        <h5 class="font-weight-bolder mb-0"> All Admins</h5>

                                        {/* <p className="text-sm mb-0">
                                            A lightweight, extendable, dependency-free javascript HTML table plugin.
                                        </p> */}
                                    </div>


                                </div>
                                <div className="table-responsive">
                                    <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                        <div className="dataTable-top">
                                            <div className="dataTable-dropdown">
                                                <label style={{ width: "300px" }}>
                                                    {/* <input className="dataTable-input" placeholder="Search..." value={""} onClick={(e) => setItemsPerPage(e.target.value)} type="text" /> */}
                                                    <select class="dataTable-selector w-50 me-2" onClick={(e) => setItemsPerPage(e.target.value)}>
                                                        <option value="5" >5</option>
                                                        <option value="10">10</option>

                                                        <option value="15">15</option>
                                                        <option value="20">20</option>
                                                    </select>
                                                    entries per page</label>
                                            </div>
                                            <div>
                                                <div className="dataTable-search">
                                                    {/* <input className="dataTable-input" placeholder="Search123..." value={searchQuery} onChange={(event) => setSearchQuery(event.target.value)} type="text" /> */}

                                                    <input className="dataTable-input" placeholder="Search name.." type="text" onChange={filter} />
                                                    <i class="fas fa-search"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dataTable-container" style={{ minHeight: "400px" }} >
                                            <table className="table table-flush dataTable-table" id="datatable-search">
                                                <thead className="thead-light">
                                                    <tr><th data-sortable="" style={{ width: "10.8797%" }}>
                                                        <a className="dataTable-sorter"><label>Admin Name</label> </a>
                                                    </th>
                                                        <th data-sortable="" style={{ width: "10.4808%" }}>
                                                            <a className="dataTable-sorter"> <label>Admin Contact</label></a>
                                                        </th>
                                                        <th data-sortable="" style={{ width: "25.4808%" }}>
                                                            <a className="dataTable-sorter"> <label>Admin Email</label></a>
                                                        </th>

                                                        <th data-sortable="" style={{ width: "10.18577%" }}>
                                                            <a className="dataTable-sorter"><label>Allotated Places</label> </a>
                                                        </th>
                                                        <th data-sortable="" style={{ width: "25.7845%" }}>
                                                            <a className="dataTable-sorter"><label>Address</label></a>
                                                        </th>

                                                        <th data-sortable="" style={{ width: "10.0304%" }}>
                                                            <a className="dataTable-sorter"> <label>Status</label></a>
                                                        </th>
                                                        <th data-sortable="" style={{ width: "10.0304%" }}>
                                                            <a className="dataTable-sorter"> <label>Actions</label></a>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {recordData && recordData.map((item, i) =>
                                                        <tr key={i + 1}>

                                                            <td className="text-sm d-flex justify-content-start" ><div> <Avatar alt="Remy Sharp" src={team_3} /></div><div className='mt-2 ms-3 '> {item.user_name}</div></td>
                                                            <td className="text-sm" style={{ whiteSpace: 'pre-wrap' }}>{item.contact_number}</td>
                                                            <td className="text-sm" style={{ whiteSpace: 'pre-wrap' }}>{item.email_address}</td>
                                                            <td className="text-sm font-weight-normal" style={{ whiteSpace: 'pre-wrap' }}>

                                                                <div className="avatar-group">
                                                                    <a href="" className="avatar rounded-circle  avatar-xs " data-bs-toggle="tooltip" data-original-title="Audrey Love">
                                                                        <img alt="Image placeholder" src={team_2} className=" " />
                                                                    </a>
                                                                    <a href="" className="avatar  rounded-circle  avatar-xs " data-bs-toggle="tooltip" data-original-title="Jessica Rowland">
                                                                        <img alt="Image placeholder" src={team_3} className="" />
                                                                    </a>
                                                                    <a href="" className="avatar  rounded-circle avatar-xs " data-bs-toggle="tooltip" data-original-title="Michael Lewis">
                                                                        <img alt="Image placeholder" src={team_2} className="rounded-circle" />
                                                                    </a>
                                                                    <a href="" className="avatar  avatar-xs rounded-circle" data-bs-toggle="tooltip" data-original-title="Audrey Love">
                                                                        <img alt="Image placeholder" src={team_4} className="rounded-circle " />
                                                                    </a>
                                                                </div>
                                                            </td>
                                                            <td className="text-sm" style={{ whiteSpace: 'pre-wrap' }}>{item.user_id % 2 == 0 ? "No 5, East Coast Road, Injambakkam, Chennai - 600115" : "176 Luz Church Road, Mylapore, Chennai 600004"}</td>

                                                            <td className="text-sm" style={{ whiteSpace: 'pre-wrap' }}>{item.user_id % 2 == 0 ? (<span className="badge badge-success badge-sm"> Active</span>) : (<span className="badge badge-danger badge-sm">In Active</span>)}</td>
                                                            <td className="text-sm"  >
                                                                <a data-bs-toggle="tooltip" data-bs-original-title="Preview product">
                                                                    <i className="text-secondary" aria-hidden="true" >
                                                                        <FontAwesomeIcon icon={Icons.faEye} />
                                                                    </i>
                                                                </a>
                                                                <a data-bs-toggle="tooltip" className='mx-3' data-bs-original-title="Edit Hall">
                                                                    <i className="text-secondary" aria-hidden="true">
                                                                        <FontAwesomeIcon icon={Icons.faPen} />
                                                                    </i>
                                                                </a>
                                                                <a data-bs-toggle="tooltip" data-bs-original-title="Delete Hall"  >
                                                                    <i className="text-secondary" aria-hidden="true">
                                                                        <FontAwesomeIcon icon={Icons.faTrash} />
                                                                    </i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {!hasRecords && (

                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td className='txtnorecord'>No Record Found</td>
                                                        </tr>
                                                    )}
                                                </tbody>

                                            </table>
                                        </div>
                                        <div className="dataTable-bottom">
                                            <div className="dataTable-info">Showing {indexOfFirstItem + 1} to  {currentPage === pageNumbers.length ? filteredData.length : indexOfLastItem}   of {filteredData.length} entries</div>
                                            <nav className="dataTable-pagination">
                                                <ul className="dataTable-pagination-list">
                                                    <li className="pager" style={{ cursor: "pointer" }}><a data-page="1"
                                                        onClick={() => handlePageChange(currentPage - 1)}
                                                        className={currentPage === 1 ? "button-disable" : ""}

                                                    >‹</a></li>
                                                    {pageNumbers.map((number) => (
                                                        <li key={number} className={number == currentPage ? "active" : " pager"}>
                                                            <a
                                                                onClick={() => setCurrentPage(number)}

                                                                className={""}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                {number}
                                                            </a>
                                                        </li>
                                                    ))}


                                                    <li className="pager"><a data-page="2"
                                                        onClick={() => handlePageChange(currentPage + 1)}
                                                        className={currentPage === pageNumbers.length ? "button-disable" : ""}
                                                        style={{ cursor: "pointer" }} >›</a></li>

                                                </ul></nav></div></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer />
            </main>

        </div>
    )
}

export default SuperAdmin
